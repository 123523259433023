define('minerva-frontend/helpers/get-file-url', ['exports', 'minerva-frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getFileUrl = getFileUrl;
  function getFileUrl(params /*, hash*/) {
    return _environment.default.APP.apiUrl + '/fileservice/' + params;
  }

  exports.default = Ember.Helper.helper(getFileUrl);
});