define('minerva-frontend/validations/proposals/m-visitor', ['exports', 'ember-changeset-validations/validators', 'minerva-frontend/validators/generic/date-before', 'minerva-frontend/validators/generic/date-after', 'ember-changeset-conditional-validations/validators/sometimes'], function (exports, _validators, _dateBefore, _dateAfter, _sometimes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = {

    helperDateOfBirth: [].concat(_toConsumableArray((0, _sometimes.default)([(0, _validators.validatePresence)({ presence: true, message: "Visitor date of birth can't be blank" })], function (changes, content) {
      return Ember.get(changes, 'isClientIndividual') && Ember.get(changes, 'isVisitorInsured') || Ember.get(changes, 'isClientCompany');
    })), _toConsumableArray((0, _sometimes.default)([(0, _dateAfter.default)({
      years: -18,
      message: 'Visitor date of birth must be less than 18 years old'
    })], function (changes, content) {
      return Ember.get(changes, 'isClientIndividual') && Ember.get(changes, 'isVisitorInsured');
    }))),

    helperPassportNumber: (0, _sometimes.default)([(0, _validators.validatePresence)({ presence: true, message: "Visitor passport number can't be blank" })], function (changes, content) {
      return Ember.get(changes, 'isClientIndividual') && Ember.get(changes, 'isVisitorInsured') || Ember.get(changes, 'isClientCompany');
    }),

    helperFullName: (0, _sometimes.default)([(0, _validators.validatePresence)({ presence: true, message: "Visitor full name can't be blank" }), (0, _validators.validateLength)({ min: 2, max: 60 })], function (changes, content) {
      return Ember.get(changes, 'isClientIndividual') && Ember.get(changes, 'isVisitorInsured') || Ember.get(changes, 'isClientCompany');
    }),

    helperGender: (0, _sometimes.default)([(0, _validators.validatePresence)({ presence: true, message: "Visitor gender can't be blank" })], function (changes, content) {
      return Ember.get(changes, 'isClientIndividual') && Ember.get(changes, 'isVisitorInsured') || Ember.get(changes, 'isClientCompany');
    }),

    helperAddressOfEmployment: (0, _sometimes.default)([(0, _validators.validatePresence)({ presence: true, message: "Visitor address can't be blank" })], function (changes, content) {
      return Ember.get(changes, 'isClientIndividual') && Ember.get(changes, 'isVisitorInsured') || Ember.get(changes, 'isClientCompany');
    }),
    medicalCoverType: (0, _validators.validatePresence)(true)

  };
});