define('minerva-frontend/validations/miaccident-insured-person', ['exports', 'ember-changeset-validations/validators', 'moment', 'minerva-frontend/validators/special/insured-person-birthday', 'ember-changeset-conditional-validations/validators/sometimes'], function (exports, _validators, _moment, _insuredPersonBirthday, _sometimes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    insuredName: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ min: 4, max: 30 })],
    insuredId: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ integer: true }), (0, _validators.validateLength)({ min: 4, max: 8 })],
    nationality: [(0, _validators.validatePresence)(true)],
    district: [(0, _validators.validatePresence)(true)],
    address: [(0, _validators.validatePresence)(true)],
    postCode: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ integer: true })],
    dateOfBirth: [(0, _validators.validatePresence)(true), (0, _insuredPersonBirthday.default)({
      message: '{description} must be greater than {age_low} and lower than {age_high}.',
      relationField: 'relationToPolicyHolder'
    })],
    weight: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ integer: true })],
    height: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ integer: true })]
    /*
    relationToPolicyHolder: [
      validatePresence(true),
    ],
    childOccupation:validateSometimes([
      validatePresence(true),
    ], function (changes, content) {
      const relationToPolicyHolder = get(changes, 'relationToPolicyHolder') || get(content, 'relationToPolicyHolder');
      if(relationToPolicyHolder === 'CHILD'){
        const dateOfBirthStr = get(changes,'dateOfBirth') || get(content, 'dateOfBirth');
        const dateOfBirth = moment(dateOfBirthStr);
        if (dateOfBirth.isValid() && moment().diff(dateOfBirth,'years',true) >= 18){
            return true;
        }
      }
      return false;
    }),
    */
  };
});