define("minerva-frontend/validations/proposals/motor", ["exports", "minerva-frontend/validators/generic/date-before", "minerva-frontend/validators/generic/date-after", "ember-changeset-conditional-validations/validators/sometimes", "ember-changeset-validations/validators", "minerva-frontend/validations/covernotes/motor"], function (exports, _dateBefore, _dateAfter, _sometimes, _validators, _motor) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.ProposalValidations = undefined;
  var assign = Ember.assign;
  var ProposalValidations = exports.ProposalValidations = {
    /*
    CHECKED ON document-generic-info
    endDateTime: validateSometimes([
      validatePresence(true),
      validateDateBefore({
        field: 'startDateTime',
        years: -1,
        hours: -23,
        minutes: -59,
        message: '{description} cannot be more than 1 year after start date'
      }),
      validateDateAfter({
        field: 'minEndDateTime',
        message: '{description} cannot be less than {onOrAfter}'
      }),
      validateDateAfter({
        field: 'startDateTime',
        message: '{description} cannot be before start date'
      })
    ], function (changes, content) {
      return (get(changes, 'customStartEndDate') == true);
    }),
    */

    windscreen: [(0, _validators.validateNumber)({ min: 300, allowBlank: true })],
    personalAccident: [(0, _validators.validateNumber)({ allowBlank: true })]
  };

  exports.default = assign({}, _motor.default, ProposalValidations);
});