define('minerva-frontend/controllers/covernote/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['endorsementMode'],
    endorsementMode: false,
    currentUser: Ember.inject.service()
  });
});