define("minerva-frontend/serializers/proposal", ["exports", "minerva-frontend/serializers/application"], function (exports, _application) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    serializeAttribute: function serializeAttribute(snapshot, json, key, attributes) {
      if (name === "pdfLinks") {
        return false;
      }
      return this._super.apply(this, arguments);
    }
  });
});