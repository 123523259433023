define('minerva-frontend/components/helpers/param-filter-block/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    unfilteredArray: null,
    filterParam: '',
    filterValue: null,
    hasValue: Ember.computed.bool('filterValue'),
    onEmptyValueReturnAll: false,
    filteredArray: Ember.computed('unfilteredArray.[]', 'filterParam', 'filterValue', function () {
      var param = this.get('filterParam');
      var val = this.get('filterValue');
      if (!this.get('hasValue') && this.get('onEmptyValueReturnAll')) {
        return this.get('unfilteredArray');
      }
      if (!this.get('unfilteredArray')) {
        return undefined;
      }
      return this.get('unfilteredArray').filterBy(param, val);
    })
  });
});