define('minerva-frontend/components/lob-forms/mi-place-proposal/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    miPlacePlans: Ember.computed.alias('params.miplace-plan-type'),
    miPlaceRiskLocations: Ember.computed.alias('params.miplace-risk-locations'),
    miPlacePremisesUses: Ember.computed.alias('params.miplace-premises-uses'),
    miPlacePremisesWallTypes: Ember.computed.alias('params.miplace-premises-wall-types'),
    miPlacePremisesCeilingTypes: Ember.computed.alias('params.miplace-premises-ceiling-types'),
    miPlacePremisesFrameTypes: Ember.computed.alias('params.miplace-premises-frame-types'),
    miPlacePremisesDependencies: Ember.computed.alias('params.miplace-premises-dependencies'),
    miPlaceCoverOccupancyExtensionDaysOptions: Ember.computed.alias('params.miplace-cover-occupancy-extention-days'),
    miPlaceMortgageClauses: Ember.computed.alias('params.miplace-banks'),
    //isClientCompany: equal('clientIdType', 'COMPANY_REGISTRATION'),
    totalSumInsured: '-',
    addressSameAsClients: false,

    init: function init() {
      this._super.apply(this, arguments);
      //this.set('_changeset.isClientCompany', this.get('isClientCompany')); // Set so the MiAccidentValidations can use it to decide if certain validations should happen. the isCLientCompany is not an actual value passed to the model
      //this.set('_changeset.coinsurancePercentage', this.get('coinsurancePercentages').findBy('id','COINSURANCE_1'));
      //this.set('_changeset.annualExcess', this.get('mihealthExcesses').findBy('id','EXCESS_1'));
      this.set('_changeset.addressSameAsClients', this.get('addressSameAsClients'));
    },
    calculateTotalSumInsured: function calculateTotalSumInsured(buildingVal, contentsVal) {
      var totalSumInsured = "-";
      if (buildingVal && contentsVal) {
        totalSumInsured = parseInt(buildingVal) + parseInt(contentsVal);
      }
      this.set('totalSumInsured', totalSumInsured);
      return totalSumInsured;
    },

    actions: {
      addressSameAsClientsChanged: function addressSameAsClientsChanged(evt) {
        this.set('_changeset.miPlacePremisesStreet', null);
        this.set('_changeset.miPlacePremisesHomeStreetNumber', null);
        this.set('_changeset.miPlacePremisesPostCode', null);
        this.set('_changeset.miPlacePremisesApartmentNumber', null);
        this.set('_changeset.addressSameAsClients', evt.currentTarget.checked); // Set so the MiPlaceValidations can use it to decide if certain validations should happen. the addressSameAsClients is not an actual value passed to the model. 
        this.get('_changeset').validate(); // Force revalidation now that addressSameAsClients has changed.
      },
      miPlaceSumInsuredBuildingChanged: function miPlaceSumInsuredBuildingChanged(value, changeset) {
        changeset.set('miPlaceSumInsuredBuilding', value);
        this.calculateTotalSumInsured(value, changeset.get('miPlaceSumInsuredContents'));
      },
      miPlaceSumInsuredContentsChanged: function miPlaceSumInsuredContentsChanged(value, changeset) {
        changeset.set('miPlaceSumInsuredContents', value);
        this.calculateTotalSumInsured(changeset.get('miPlaceSumInsuredBuilding'), value);
      },
      miPlacePlanChanged: function miPlacePlanChanged(value, changeset) {
        if (!value) {
          //clear up all covers
          this.set('_changeset.miPlaceCoverEarthquake', null);
          this.set('_changeset.miPlaceCoverReinstateValue', null);
          this.set('_changeset.miPlaceCoverPublicLiability', null);
          this.set('_changeset.miPlaceCoverForestFire', null);
          this.set('_changeset.miPlaceCoverRentLoss', null);
          this.set('_changeset.miPlaceCoverFoodSpoilage', null);
          this.set('_changeset.miPlaceCoverLiabilityToTenant', null);
          this.set('_changeset.miPlaceCoverHouseholdAppliances', null);
          this.set('_changeset.miPlaceCoverWaterLoss', null);
          this.set('_changeset.miPlaceCoverExtendedExplosion', null);
          this.set('_changeset.miPlaceCoverOccupancyExtension', null);
          this.set('_changeset.miPlaceCoverOccupancyExtensionDays', null);
          return;
        }
        var miPlacePlanId = Ember.get(value, 'id');;

        var fieldsToLock = {
          miPlaceCoverEarthquake: true,
          miPlaceCoverReinstateValue: true,
          miPlaceCoverPublicLiability: true,
          miPlaceCoverForestFire: true,
          miPlaceCoverRentLoss: true,
          miPlaceCoverFoodSpoilage: true,
          miPlaceCoverLiabilityToTenant: true,
          miPlaceCoverHouseholdAppliances: true,
          miPlaceCoverWaterLoss: true,
          miPlaceCoverExtendedExplosion: true,
          miPlaceCoverOccupancyExtension: false,
          miPlaceCoverOccupancyExtensionDays: false
        };

        switch (miPlacePlanId) {
          case 'CLASSIC':
            changeset.set('miPlaceCoverEarthquake', false);
            changeset.set('miPlaceCoverReinstateValue', false);
            changeset.set('miPlaceCoverPublicLiability', false);
            changeset.set('miPlaceCoverForestFire', false);
            changeset.set('miPlaceCoverRentLoss', false);
            changeset.set('miPlaceCoverFoodSpoilage', false);
            changeset.set('miPlaceCoverLiabilityToTenant', false);
            changeset.set('miPlaceCoverHouseholdAppliances', false);
            changeset.set('miPlaceCoverWaterLoss', false);
            changeset.set('miPlaceCoverExtendedExplosion', false);

            fieldsToLock.miPlaceCoverEarthquake = false; //Allow changing
            fieldsToLock.miPlaceCoverReinstateValue = false;
            fieldsToLock.miPlaceCoverPublicLiability = false;
            fieldsToLock.miPlaceCoverForestFire = false;
            fieldsToLock.miPlaceCoverRentLoss = true; // We lock this as it's not available to this package
            fieldsToLock.miPlaceCoverFoodSpoilage = true;
            fieldsToLock.miPlaceCoverLiabilityToTenant = true;
            fieldsToLock.miPlaceCoverHouseholdAppliances = false;
            fieldsToLock.miPlaceCoverWaterLoss = false;
            fieldsToLock.miPlaceCoverExtendedExplosion = true;
            break;
          case 'ADVANCED':
            changeset.set('miPlaceCoverEarthquake', true);
            changeset.set('miPlaceCoverReinstateValue', false);
            changeset.set('miPlaceCoverPublicLiability', false);
            changeset.set('miPlaceCoverForestFire', false);
            changeset.set('miPlaceCoverRentLoss', false);
            changeset.set('miPlaceCoverFoodSpoilage', false);
            changeset.set('miPlaceCoverLiabilityToTenant', false);
            changeset.set('miPlaceCoverHouseholdAppliances', false);
            changeset.set('miPlaceCoverWaterLoss', false);
            changeset.set('miPlaceCoverExtendedExplosion', false);

            fieldsToLock.miPlaceCoverReinstateValue = false;
            fieldsToLock.miPlaceCoverPublicLiability = false;
            fieldsToLock.miPlaceCoverForestFire = false;
            fieldsToLock.miPlaceCoverRentLoss = false;
            fieldsToLock.miPlaceCoverFoodSpoilage = false;
            fieldsToLock.miPlaceCoverLiabilityToTenant = false;
            fieldsToLock.miPlaceCoverHouseholdAppliances = false;
            fieldsToLock.miPlaceCoverWaterLoss = false;
            fieldsToLock.miPlaceCoverExtendedExplosion = false;
            break;
          case 'ELITE':
            changeset.set('miPlaceCoverEarthquake', true);
            changeset.set('miPlaceCoverReinstateValue', true);
            changeset.set('miPlaceCoverPublicLiability', true);
            changeset.set('miPlaceCoverForestFire', true);
            changeset.set('miPlaceCoverRentLoss', true);
            changeset.set('miPlaceCoverFoodSpoilage', true);
            changeset.set('miPlaceCoverLiabilityToTenant', true);
            changeset.set('miPlaceCoverHouseholdAppliances', true);
            changeset.set('miPlaceCoverWaterLoss', true);
            changeset.set('miPlaceCoverExtendedExplosion', true);
            break;
        }
        changeset.set('miPlaceCoverOccupancyExtensionDays', null);
        if (miPlacePlanId) {
          this.set('_fieldsToLock', fieldsToLock);
        } else {
          this.set('_fieldsToLock', {});
        }

        changeset.set('miPlacePlan', value);
      },
      emptySubmit: function emptySubmit() {},
      submit: function submit(model, submissionStatus) {
        //Here we should handle Lob specific logic. When done, pass the stuff on to the form-wrapper to submit the info!
        this.onSubmit(model, submissionStatus);
      }
    }
  });
});