define('minerva-frontend/validators/special/driver-birthday', ['exports', 'ember-changeset-validations/utils/validation-errors', 'ember-validators', 'moment'], function (exports, _validationErrors, _emberValidators, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = validateDriverBirthday;
  function validateDriverBirthday() {
    var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    return function (key, newValue, oldValue, changes, content) {
      var beforeDate = (0, _moment.default)();
      var isLearner = Ember.get(changes, 'learner') || Ember.get(content, 'learner');
      beforeDate = beforeDate.subtract(18, 'years');

      if (isLearner) {
        beforeDate = beforeDate.add(1, 'years');
      }
      beforeDate = beforeDate.toISOString();
      options.before = beforeDate;
      options.format = 'YYYY-MM-DDTHH:mm:ssZ';

      if (isLearner) {
        options.age = 17;
      } else {
        options.age = 18;
      }
      var result = (0, _emberValidators.validate)('date', newValue, options, null, key);
      return result === true ? true : (0, _validationErrors.default)(key, result, newValue, options);
    };
  }
});