define('minerva-frontend/components/depricated/dashboard/sales-line-graph/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    month: null,
    type: 'line',
    monthLabel: Ember.computed('month', function () {
      var month = this.get('month');
      var date = new Date(new Date().getYear(), month, 5);
      var monthLabel = date.toLocaleString("en-us", { month: "long" });
      return monthLabel + ' proposals';
    }),
    numberData: Ember.computed('month', function () {
      var days = [];
      var data = [];
      var month = this.get('month');
      var date = new Date(new Date().getYear(), month, 1);

      while (date.getMonth() === month) {
        var calcDay = new Date(date).toLocaleString("en-us", { day: "numeric" });
        days.push(calcDay);
        var today = new Date().getDate();
        var thisMonth = new Date().getMonth();
        if (month < thisMonth) {
          data.push(Math.floor(Math.random() * 49000 + 1000));
        } else if (calcDay < today) {
          data.push(Math.floor(Math.random() * 49000 + 1000));
        } else {
          data.push(0);
        }
        date.setDate(date.getDate() + 1);
      }

      return {
        labels: days,
        datasets: [{
          data: data
        }]
      };
    }),
    graphOptions: {
      steppedLine: true,
      line: {
        tension: 0 // disables bezier curves
      },
      legend: {
        display: false
      }
    }
  });
});