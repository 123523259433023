define('minerva-frontend/validations/client-endorsement', ['exports', 'ember-changeset-validations/validators', 'minerva-frontend/validations/client'], function (exports, _validators, _client) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.EndorsementClientValidations = undefined;
  var EndorsementClientValidations = exports.EndorsementClientValidations = {
    nationality: (0, _validators.validatePresence)(false)
  };

  exports.default = Ember.assign({}, _client.default, EndorsementClientValidations);
});