define('minerva-frontend/controllers/covernote/new-endorsement', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    ajax: Ember.inject.service(),
    paramStore: Ember.inject.service(),
    paramsLoaded: false,
    _initParams: function _initParams() {
      var _this = this;

      this.set('documentTypes', Ember.A([{ id: 'STANDARD_ENDORSEMENT', label: "Standard endorsement" }, { id: 'DEFINITE_ENDORSEMENT', label: "Definite endorsement" }]));

      var paramStore = this.get('paramStore');
      return paramStore.getParams().then(function (params) {
        Ember.set(_this, 'params', params);
      });
    },
    init: function init() {
      var _this2 = this;

      this._super.apply(this, arguments);
      this._initParams().then(function () {
        Ember.set(_this2, 'paramsLoaded', true);
      });
    }
  });
});