define('minerva-frontend/components/lob-form-wrappers/mi-health-form/component', ['exports', 'ember-changeset-validations', 'ember-changeset', 'minerva-frontend/validations/proposals/mi-health', 'minerva-frontend/validations/client', 'minerva-frontend/validations/proposals/document-generic-info', 'minerva-frontend/mixins/document-times-calculator'], function (exports, _emberChangesetValidations, _emberChangeset, _miHealth, _client, _documentGenericInfo, _documentTimesCalculator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var keys = Object.keys;
  exports.default = Ember.Component.extend(_documentTimesCalculator.default, {
    router: Ember.inject.service(),
    _clientDataLocked: false,
    _genericDataInfoLocked: false,
    init: function init() {
      this._super.apply(this, arguments);
      // this.set('durationOptions', [
      //   /*
      //   {
      //     value
      //   },
      //   */
      //   {
      //     label: '4 months',
      //     months: 4,
      //   },
      //   {
      //     label: '6 months',
      //     months: 6,
      //   },
      //   {
      //     label: '8 months',
      //     months: 8,
      //   },
      //   {
      //     label: '12 months',
      //     months: 12,
      //   }
      // ]);

      var defaultDuration = {
        label: '12 months',
        months: 12
      };
      this.set("_defaultDuration", defaultDuration);

      var model = Ember.get(this, 'model');

      //Now create 2 changesets for client and generic-info, and pass them down to the relevant components to handle them
      var _clientChangeset, _genericInfoChangeset, _documentChangeset;
      _clientChangeset = new _emberChangeset.default(model, (0, _emberChangesetValidations.default)(_client.default), _client.default);
      _genericInfoChangeset = new _emberChangeset.default(model, (0, _emberChangesetValidations.default)(_documentGenericInfo.default), _documentGenericInfo.default);
      Ember.set(this, '_clientChangeset', _clientChangeset);
      Ember.set(this, '_genericInfoChangeset', _genericInfoChangeset);
      var validations = _miHealth.default; // get(this, 'lobSpecificValidations');
      _documentChangeset = new _emberChangeset.default(model, (0, _emberChangesetValidations.default)(validations), validations);
      Ember.set(this, '_documentChangeset', _documentChangeset);
    },

    actions: {
      clientDataLoaded: function clientDataLoaded(hasLoaded) {
        Ember.set(this, '_clientDataLoaded', hasLoaded);
        if (!hasLoaded) {
          Ember.set(this, '_clientDataLocked', false);
          Ember.set(this, '_genericDataInfoLocked', false);
        }
      },
      submit: function submit(model, submissionStatus) {
        var _this = this;

        var _clientChangeset = Ember.get(this, '_clientChangeset');
        var _genericInfoChangeset = Ember.get(this, '_genericInfoChangeset');
        var _documentChangeset = Ember.get(this, '_documentChangeset');
        var promise1 = _clientChangeset.validate();
        var promise2 = _genericInfoChangeset.validate();
        var promise3 = _documentChangeset.validate();

        Ember.set(this, '_submittingDataLock', true);

        Ember.RSVP.all([promise1, promise2, promise3]).then(function (promises) {
          if (_clientChangeset.get('isValid') && _genericInfoChangeset.get('isValid') && _documentChangeset.get('isValid')) {
            _clientChangeset.execute();
            _genericInfoChangeset.execute();
            _documentChangeset.execute();
            //After execute commands, the underlying objects have proper values (even if the changesets had added fields on them while working with them
            var _model = _this.get('model');

            if (!_genericInfoChangeset.get('customStartEndDate')) {
              //means we should apply the default
              var duration = Ember.get(_this, '_defaultDuration');
              var times = _this.getStartEndDateTimes(null, duration); // From DocumentTimesCalculator
              _model.set('startDateTime', times.startDateTime);
              _model.set('endDateTime', times.endDateTime);
            }
            _model.set('lineOfBusiness', Ember.get(_this, 'selectedLob')); //Apply selected lob
            _model.set('submissionStatus', submissionStatus);
            _model.save().then(function (obj) {
              _this.get('notifications').success("Document was saved successfully");
              _this.get('router').replaceWith(_this.get('detailRoute'), obj.get('id'));
            }).catch(function (response) {
              _this.get('notifications').error('There was an error while trying to save the document. ' + response.errors);
              Ember.set(_this, '_submittingDataLock', false);
            });
          } else {
            console.log("WTF", promises);
            Ember.set(_this, '_submittingDataLock', false);
          }
        }).catch(function (promises) {
          console.log("Data appear to be invalid");
          Ember.set(_this, '_submittingDataLock', false);
        });
      }
    }
  });
});