define('minerva-frontend/authenticators/jwt', ['exports', 'ember-simple-auth-jwt/authenticators/jwt', 'minerva-frontend/config/environment'], function (exports, _jwt, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _jwt.default.extend({
    serverTokenEndpoint: _environment.default.APP.serverTokenEndpoint,
    serverRefreshTokenEndpoint: _environment.default.APP.serverRefreshTokenEndpoint,
    ajax: Ember.inject.service(),
    makeRequest: function makeRequest(url, data) {
      var _this = this;

      //let headers = new Headers();
      //headers.append('Content-Type','application/x-www-form-urlencoded');
      //headers['Authorization'] = "Basic " + btoa("testjwtclientid:XY7kmzoNzl100"); //'Basic dGVzdGp3dGNsaWVudGlkOlhZN2ttem9OemwxMDA=';
      //headers.append('Authorization',"Basic " + btoa('testjwtclientid' + ":" + 'XY7kmzoNzl100'));  //'Basic dGVzdGp3dGNsaWVudGlkOlhZN2ttem9OemwxMDA=';
      if (data.refresh_token) {
        data.grant_type = 'refresh_token';
      } else {
        data.grant_type = 'password';
      }

      var paramdata = $.param(data);
      var options = {
        data: paramdata,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': "Basic " + btoa('testjwtclientid' + ":" + 'XY7kmzoNzl100')
        },
        method: 'POST',
        credentials: 'include'
      };
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this.get('ajax').request(url, options).then(function (response) {
          if (response.error) {
            reject(response);
          } else {
            window.localStorage.setItem('jwtLastRefreshAt', Date.now());
            resolve(response);
          }
        }).catch(function (response) {
          reject(response);
        });
      });
    },
    _validate: function _validate(data) {
      // Validate that a token is present
      if (Ember.isEmpty(data['access_token'])) {
        return false;
      }

      var jwtToken = data['access_token'].split('.');

      // Validate the three elements of a JWT are present
      if (jwtToken.length !== 3) {
        return false;
      }

      // Validate the JWT headers
      var jwtHeader = JSON.parse(atob(jwtToken[0]));
      if (jwtHeader.typ !== "JWT") {
        return false;
      }
      // Validate the JWT payload:
      // iat: issued at time
      // exp: expiration time
      var jwtPayload = JSON.parse(atob(jwtToken[1]));
      if (isNaN(jwtPayload['exp'])) {
        return false;
      }
      return true;
    },

    /*
    _refreshAccessToken(data) {
      var timeElapsedSinceLastRefresh = Date.now() - window.localStorage.getItem('jwtLastRefreshAt')
      if (timeElapsedSinceLastRefresh <= this.get('refreshTokenAfter')) {
        // Request attempted too soon! Reschedule
        this._validateParseRefreshToken(data);
        return RSVP.Promise.resolve(data);
      }
       const serverRefreshTokenEndpoint = this.get('serverRefreshTokenEndpoint');
      console.log( "Attempting to refresh the token at endpoint ", serverRefreshTokenEndpoint);
      console.log("Data to use:", data);
      var dataForJava = {
        grant_type: 'refresh_token',
        refresh_token: data.refresh_token
      };
      return new RSVP.Promise((resolve, reject) => {
        this.makeRequest(serverRefreshTokenEndpoint, dataForJava).then((response) => {
          run(() => {
            this._validateParseRefreshToken(response);
            this.trigger('sessionDataUpdated', response);
            resolve(response);
          });
        }, (reason) => {
          Ember.warn(`Access token could not be refreshed - server responded with ${JSON.stringify(reason.responseJSON)}.`, false, {
            id: 'ember-simple-auth-jwt.failedJWTTokenRefresh'
          });
          reject();
        });
      });
    },
    */
    _validateParseRefreshToken: function _validateParseRefreshToken(response) {
      if (!this._validate(response)) {
        Ember.RSVP.Promise.reject('token is missing or invalid in server response');
      }
      response.token = response.access_token; //Re-add this as 'token' in response so the authorizer will place nicely

      //Inject username from jwt to use in template for now
      var jwtToken = response.access_token.split('.');
      var jwtPayload = JSON.parse(atob(jwtToken[1]));
      response.username = jwtPayload.user_name;

      this._scheduleAccessTokenRefresh(response);
    },
    _scheduleAccessTokenRefresh: function _scheduleAccessTokenRefresh(data) {
      var jwtPayload = JSON.parse(atob(data.token.split('.')[1]));
      var jwtPayloadExpiresAt = jwtPayload.expires_in;

      var offset = 1000; // Refresh 1 sec before JWT expires
      var now = Date.now();
      var waitMs = jwtPayloadExpiresAt * 1000 - now - offset; //expiresAt is in sec

      if (this._refreshTokenTimeout) {
        run.cancel(this._refreshTokenTimeout);
        delete this._refreshTokenTimeout;
      }

      // Reschedule if the JWT is still valid
      if (waitMs > 0) {
        this._refreshTokenTimeout = run.later(this, this._refreshAccessToken, data, waitMs);
      }
    }
  });
});