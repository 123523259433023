define('minerva-frontend/components/forms/driver-input-form/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    model: null,
    _excessAmountLocked: false, //alias('model.learner'),
    _excessAmount: "",

    actions: {
      learnerChanged: function learnerChanged(value, changeset) {
        changeset.set('learner', value);
        if (value) {
          //this.get('actions').excessAmountChanged.call(this, 500, changeset);
          changeset.set('excessAmount', 500);
          this.set('_excessAmount', 500);
          this.set('_excessAmountLocked', true);
        } else {
          changeset.set('excessAmount', '');
          this.set('_excessAmount', '');
          this.set('_excessAmountLocked', false);
        }
        changeset.validate();
      },
      remove: function remove(model) {
        this.get('onRemove')(model);
      },
      validate: function validate(changeset) {
        //console.log('validating driver form');
        changeset.validate();
        return false;
      }
    }
  });
});