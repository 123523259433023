define('minerva-frontend/serializers/covernote-cancellation-request', ['exports', 'ember-data', 'minerva-frontend/serializers/application'], function (exports, _emberData, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      covernote: {
        serialize: 'ids',
        deserialize: 'records'
      }
    }
  });
});