define('minerva-frontend/validations/user-profile', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    username: (0, _validators.validatePresence)(true),
    firstName: (0, _validators.validatePresence)(true),
    //lastName: validatePresence(true),
    address1: (0, _validators.validatePresence)(true),
    maxCovernoteAmount: (0, _validators.validateNumber)({ integer: true, allowBlank: true, positive: true }),
    minCovernoteAmount: (0, _validators.validateNumber)({ integer: true, allowBlank: true, positive: true }),
    maxDriverAge: (0, _validators.validateNumber)({ integer: true, allowBlank: true, positive: true }),
    minDriverAge: (0, _validators.validateNumber)({ integer: true, allowBlank: true, positive: true }),
    maxInsuredAge: (0, _validators.validateNumber)({ integer: true, allowBlank: true, positive: true }),
    minInsuredAge: (0, _validators.validateNumber)({ integer: true, allowBlank: true, positive: true })
  };
});