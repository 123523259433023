define('minerva-frontend/adapters/application', ['exports', 'ember-data', 'ember-simple-auth/mixins/data-adapter-mixin', 'minerva-frontend/config/environment'], function (exports, _emberData, _dataAdapterMixin, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONAPIAdapter.extend(_dataAdapterMixin.default, {
    host: _environment.default.APP.apiUrl,
    authorizer: 'authorizer:jwt',

    headers: {
      "Accept": "application/json, text/javascript; q=0.01"
    }
  });
});