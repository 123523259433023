define('minerva-frontend/validations/proposals/document-generic-info-without-installments', ['exports', 'ember-changeset-validations/validators', 'minerva-frontend/validators/generic/date-before', 'ember-changeset-conditional-validations/validators/sometimes', 'minerva-frontend/validators/generic/date-after'], function (exports, _validators, _dateBefore, _sometimes, _dateAfter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {

    /*
    //Start date is locked to covernote start date, so skip validation alltogether
    startDateTime: validateSometimes([
      validatePresence(true),
      validateDateAfter({
        minutes: -30,
        message: '{description} cannot start earlier than now'
      })
    ], function (changes, content) {
      return (get(changes, 'customStartEndDate') == true);
    }),
    */

    endDateTime: (0, _sometimes.default)([(0, _validators.validatePresence)(true), (0, _dateBefore.default)({
      field: 'startDateTime',
      years: -1,
      hours: -23,
      minutes: -59,
      message: '{description} cannot be more than 1 year after start date'
    }), (0, _dateAfter.default)({
      field: 'startDateTime',
      message: '{description} cannot be before start date'
    })], function (changes, content) {
      return Ember.get(changes, 'customStartEndDate') == true;
    })
  };
});