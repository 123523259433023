define("minerva-frontend/components/validated-button", ["exports", "ember-validated-form/components/validated-button"], function (exports, _validatedButton) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _validatedButton.default;
    }
  });
});