define('minerva-frontend/components/users/user-password-change-form/component', ['exports', 'minerva-frontend/validations/user-password'], function (exports, _userPassword) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    UserPasswordValidations: _userPassword.default,
    meEndpoint: false,
    store: Ember.inject.service(),
    actions: {
      submit: function submit(changeset) {
        var _this = this;

        var successMsg, failMsg;
        successMsg = "Password was updated successfully";
        failMsg = "There was a problem updating the user's password. ";

        if (this.get('meEndpoint')) {
          return changeset.cast(['password', 'passwordConfirmation']).save({ adapterOptions: { me: true } }).then(function () {
            _this.get('notifications').success(successMsg);
          }).catch(function (response) {
            _this.get('notifications').error(failMsg + response.errors);
          });
        } else {
          return changeset.cast(['password', 'passwordConfirmation']).save().then(function () {
            _this.get('notifications').success(successMsg);
          }).catch(function (response) {
            _this.get('notifications').error(failMsg + response.errors);
          });
        }
      }
    }
  });
});