define('minerva-frontend/validators/special/driver-amount', ['exports', 'ember-changeset-validations/utils/validation-errors', 'ember-changeset-validations/utils/with-defaults', 'ember-validators'], function (exports, _validationErrors, _withDefaults, _emberValidators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = validateSpecialDriverAmount;
  function validateSpecialDriverAmount() {
    var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    return function (key, newValue, oldValue, changes, content) {
      var ownerDriving = Ember.get(changes, options.ownerDrivingKey) || Ember.get(content, options.ownerDrivingKey);
      if (ownerDriving) {
        options.max = 5;
        options.message = 'You can have maximum ' + options.max + ' extra drivers except the owner';
        options.allowNone = true;
      } else {
        options.min = 1;
        options.max = 6;
        options.message = 'Total drivers must be between ' + options.min + ' and ' + options.max + ' including owner if driving';
        options.allowNone = false;
        options.allowBlank = false;
      }

      var result = (0, _emberValidators.validate)('length', newValue, options, null, key);
      return result === true ? true : (0, _validationErrors.default)(key, result, newValue, options);
    };
  }
});