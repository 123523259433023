define('minerva-frontend/components/lob-form-wrappers/motor-form/component', ['exports', 'moment', 'ember-changeset-validations', 'ember-changeset', 'minerva-frontend/validations/covernotes/document-generic-info', 'minerva-frontend/validations/proposals/document-generic-info', 'minerva-frontend/validations/standard-endorsements/document-generic-info', 'minerva-frontend/validations/definite-endorsements/document-generic-info', 'minerva-frontend/validations/client', 'minerva-frontend/validations/client-endorsement', 'minerva-frontend/validations/proposals/private', 'minerva-frontend/validations/proposals/commercial', 'minerva-frontend/validations/proposals/motorcycle', 'minerva-frontend/validations/proposals/special-type', 'minerva-frontend/validations/proposals/private-bus', 'minerva-frontend/validations/covernotes/private', 'minerva-frontend/validations/covernotes/commercial', 'minerva-frontend/validations/covernotes/motor', 'minerva-frontend/validations/standard-endorsements/private', 'minerva-frontend/validations/standard-endorsements/commercial', 'minerva-frontend/validations/standard-endorsements/motor', 'minerva-frontend/validations/definite-endorsements/private', 'minerva-frontend/validations/definite-endorsements/commercial', 'minerva-frontend/validations/definite-endorsements/motor', 'minerva-frontend/mixins/document-times-calculator'], function (exports, _moment, _emberChangesetValidations, _emberChangeset, _documentGenericInfo, _documentGenericInfo2, _documentGenericInfo3, _documentGenericInfo4, _client, _clientEndorsement, _private, _commercial, _motorcycle, _specialType, _privateBus, _private2, _commercial2, _motor, _private3, _commercial3, _motor2, _private4, _commercial4, _motor3, _documentTimesCalculator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_documentTimesCalculator.default, {
    router: Ember.inject.service(),
    covernoteMode: undefined,
    stateInitializers: undefined,
    lobSpecificValidations: Ember.computed('selectedLob.id', function () {
      var selectedLobID = Ember.get(this, 'selectedLob.id');
      if (this.get('endorsementMode')) {
        if (Ember.get(this, 'model.documentType') == 'STANDARD_ENDORSEMENT') {
          switch (selectedLobID) {
            case 'PRIVATE':
              return _private3.default;
            case 'COMMERCIAL':
              return _commercial3.default;
            case 'MOTORCYCLE':
            case 'SPECIAL_TYPE':
            case 'PRIVATE_BUS':
              return _motor2.default; //TODO - Create validation objects for each different LOB of motor and use those instead
            default:
              return _motor2.default;
          }
        } else if (Ember.get(this, 'model.documentType') == 'DEFINITE_ENDORSEMENT') {
          switch (selectedLobID) {
            case 'PRIVATE':
              return _private4.default;
            case 'COMMERCIAL':
              return _commercial4.default;
            case 'MOTORCYCLE':
            case 'SPECIAL_TYPE':
            case 'PRIVATE_BUS':
              return _motor3.default; //TODO - Create validation objects for each different LOB of motor and use those instead
            default:
              return _motor3.default;
          }
        }
      } else if (this.get('covernoteMode')) {
        switch (selectedLobID) {
          case 'PRIVATE':
            return _private2.default;
          case 'COMMERCIAL':
            return _commercial2.default;
          case 'MOTORCYCLE':
          case 'SPECIAL_TYPE':
          case 'PRIVATE_BUS':
            return _motor.default; //TODO - Create validation objects for each different LOB of motor and use those instead
          default:
            return _motor.default;
        }
      } else {
        switch (selectedLobID) {
          case 'PRIVATE':
            return _private.default;
          case 'COMMERCIAL':
            return _commercial.default;
          case 'MOTORCYCLE':
            return _motorcycle.default;
          case 'SPECIAL_TYPE':
            return _specialType.default;
          case 'PRIVATE_BUS':
            return _privateBus.default;
          default:
            return null;
        }
      }
    }),
    _clientDataLocked: false,
    _clientChangeLock: false,
    _genericDataInfoLocked: false,
    init: function init() {
      this._super.apply(this, arguments);
      console.log("Super Important Log - Motor Form Wrapper (Re)Initializing! - This should appear every time a new lob is selected!!!");
      var model = Ember.get(this, 'model');

      //A Defautl start/end date is being set here. If the user does not set a customStartEndDate (which has specific values), this component, prior submit, uses
      //the defaults to calculate a start and end date, to send to the server

      if (Ember.get(this, 'endorsementMode')) {
        //We need to check this prior covernoteMode, cause both are set in the case of the endorsement (remnant of old code that handled endorsements as special types of covernotes)
        this.set("_defaultDuration", {
          preset: {
            endDateTime: model.get('endDateTime')
          },
          label: '(end of contract date - ' + (0, _moment.default)(model.get('endDateTime')).format('DD/MM/YY - hh:mm') + ')'
        });
      } else if (Ember.get(this, 'covernoteMode')) {
        this.set("_defaultDuration", {
          years: 0,
          months: 4,
          days: 0
        });
      } else {
        this.set("_defaultDuration", {
          years: 1,
          months: 0,
          days: 0
        });
      }

      var validationMaxDate = null;
      var stateInitializers = this.get('stateInitializers'); //Should be passed when initializing the object
      var initState = stateInitializers ? stateInitializers.state : false;
      if (initState) {
        if (initState === 'COVERNOTE_TO_PROPOSAL') {
          Ember.set(this, '_clientDataLoaded', true);
          Ember.set(this, '_clientChangeLock', true);
        } else if (initState === 'CONTRACT_TO_ENDORSEMENT') {
          //pass - TODO
          switch (stateInitializers.values.type) {
            case 'STANDARD_ENDORSEMENT':
              Ember.set(this, '_clientDataLoaded', true);
              Ember.set(this, '_clientChangeLock', true);

              delete model.startDateTime; //We need to clear them, as they were preset from the contract.
              delete model.endDateTime; //We need to clear them, as they were preset from the contract.
              break;
            case 'DEFINITE_ENDORSEMENT':
              Ember.set(this, '_clientDataLoaded', true);
              Ember.set(this, '_clientDataLocked', true);
              Ember.set(this, '_clientChangeLock', true);
              validationMaxDate = model.get('endDateTime');
              delete model.startDateTime; //We need to clear them, as they were preset from the contract.
              delete model.endDateTime; //We need to clear them, as they were preset from the contract.

              break;
          }
        } else if (initState === 'COVERNOTE_FINALIZATION') {
          Ember.set(this, '_clientDataLoaded', true);
          Ember.set(this, '_clientDataLocked', false);
          Ember.set(this, '_clientChangeLock', false);
        }
      }

      //Now create 2 changesets for client and generic-info, and pass them down to the relevant components to handle them


      var _clientChangeset, _genericInfoChangeset, _documentChangeset;
      if (this.get('endorsementMode')) {
        _clientChangeset = new _emberChangeset.default(model, (0, _emberChangesetValidations.default)(_clientEndorsement.default), _clientEndorsement.default);
      } else {
        _clientChangeset = new _emberChangeset.default(model, (0, _emberChangesetValidations.default)(_client.default), _client.default);
      }

      Ember.set(this, '_clientChangeset', _clientChangeset);
      if (this.get('endorsementMode')) {
        if (Ember.get(model, 'documentType') === 'STANDARD_ENDORSEMENT') {
          _genericInfoChangeset = new _emberChangeset.default(model, (0, _emberChangesetValidations.default)(_documentGenericInfo3.default), _documentGenericInfo3.default);
        } else if (Ember.get(model, 'documentType') === 'DEFINITE_ENDORSEMENT') {
          _genericInfoChangeset = new _emberChangeset.default(model, (0, _emberChangesetValidations.default)(_documentGenericInfo4.default), _documentGenericInfo4.default);
        }
      } else if (this.get('covernoteMode')) {
        _genericInfoChangeset = new _emberChangeset.default(model, (0, _emberChangesetValidations.default)(_documentGenericInfo.default), _documentGenericInfo.default);
      } else {
        _genericInfoChangeset = new _emberChangeset.default(model, (0, _emberChangesetValidations.default)(_documentGenericInfo2.default), _documentGenericInfo2.default);
      }

      if (validationMaxDate) {
        Ember.set(_genericInfoChangeset, 'maxEndDateTime', model.get('endDateTime'));
      }
      Ember.set(this, '_genericInfoChangeset', _genericInfoChangeset);

      var validations = Ember.get(this, 'lobSpecificValidations');
      _documentChangeset = new _emberChangeset.default(model, (0, _emberChangesetValidations.default)(validations), validations);
      Ember.set(this, '_documentChangeset', _documentChangeset);
    },

    actions: {
      actClientDataLoaded: function actClientDataLoaded(hasLoaded) {
        Ember.set(this, '_clientDataLoaded', hasLoaded);
        if (!hasLoaded) {
          Ember.set(this, '_clientDataLocked', false);
          Ember.set(this, '_genericDataInfoLocked', false);
        }
      },
      submit: function submit(model, submissionStatus) {
        var _this = this;

        //submissionStatus can be FINAL or DRAFT
        var _clientChangeset = Ember.get(this, '_clientChangeset');
        var _genericInfoChangeset = Ember.get(this, '_genericInfoChangeset');
        var _documentChangeset = Ember.get(this, '_documentChangeset');

        var promise1 = void 0,
            promise2 = void 0,
            promise3 = void 0;

        promise1 = _clientChangeset.validate();
        promise2 = _genericInfoChangeset.validate();
        promise3 = _documentChangeset.validate();

        Ember.set(this, '_submittingDataLock', true);

        Ember.RSVP.all([promise1, promise2, promise3]).then(function (promises) {

          if (submissionStatus === 'FINAL' && !(_clientChangeset.get('isValid') && _genericInfoChangeset.get('isValid') && _documentChangeset.get('isValid'))) {
            //IF is FINAL and NOT VALID
            console.log("WTF", promises);
            Ember.set(_this, '_submittingDataLock', false);
            return;
          }
          //Else if we proceed, it means is either a draft, or is final and it's also valid
          var model = _this.get('model');

          if (submissionStatus === 'DRAFT') {
            //Execute does not work if changesets are not valid. So we force copying the data over
            [_clientChangeset, _genericInfoChangeset, _documentChangeset].forEach(function (changeset) {
              var changes = changeset.get('changes');
              changes.forEach(function (changeObj) {
                //has key/value
                model.set(changeObj.key, changeObj.value);
              });
            });
          } else {
            _clientChangeset.execute();
            _genericInfoChangeset.execute();
            _documentChangeset.execute();
          }
          //After execute commands, the underlying objects have proper values (even if the changesets had added fields on them while working with them


          if (!_genericInfoChangeset.get('customStartEndDate')) {
            //means we should apply the default
            var duration = Ember.get(_this, '_defaultDuration');
            var times = _this.getStartEndDateTimes(null, duration); // From DocumentTimesCalculator
            console.log('Not having customStartEndDate, calculating dates:', times);
            model.set('startDateTime', times.startDateTime);
            model.set('endDateTime', times.endDateTime);
          }
          model.set('lineOfBusiness', Ember.get(_this, 'selectedLob')); //Apply selected lob
          model.set('submissionStatus', submissionStatus);
          model.save().then(function (obj) {
            _this.get('notifications').success("Document was saved successfully");
            _this.get('router').replaceWith(_this.get('detailRoute'), obj.get('id'));
          }).catch(function (response) {
            console.log(response);
            var error;
            if (response.errors) {
              error = response.errors;
            } else {
              error = response;
            }
            _this.get('notifications').error('There was an error while trying to save the document. ' + error);
            Ember.set(_this, '_submittingDataLock', false);
          });
        }).catch(function (promises) {
          console.log("Data appear to be invalid", promises);
          Ember.set(_this, '_submittingDataLock', false);
        });
      }
    }
  });
});