define('minerva-frontend/helpers/get-field-errors', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getFieldErrors = getFieldErrors;
  function getFieldErrors(params /*, hash*/) {
    var errorsArray = params[0];
    var fieldName = params[1];
    for (var i = 0; i < errorsArray.length; i++) {
      if (errorsArray[i].key == fieldName) {
        return errorsArray[i].validation;
      }
    }
    return [];
  }

  exports.default = Ember.Helper.helper(getFieldErrors);
});