define('minerva-frontend/controllers/application', ['exports', 'minerva-frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    currentUser: Ember.inject.service(),
    router: Ember.inject.service(),
    actions: {
      invalidateSession: function invalidateSession() {
        this.get('session').invalidate();
      }
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.get('notifications').setDefaultAutoClear(true);
      this.get('notifications').setDefaultClearDuration(5000);
      if (_environment.default.APP.server === 'staging') {
        this.set('isStaging', true);
      } else if (_environment.default.APP.server === 'dev') {
        this.set('isDev', true);
      }
    },

    showUnfinishedFeatures: _environment.default.APP.unfinishedFeatures,
    isStaging: false,
    isDev: false
  });
});