define('minerva-frontend/components/generic-forms/documenttype-selection-form/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    documentTypes: undefined,
    covernoteMode: false,
    endorsementMode: false,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('documentTypes', Ember.A([{ id: 'STANDARD_ENDORSEMENT', display: "Standard endorsement", displayEl: "", comtecCode: "" }, { id: 'DEFINITE_ENDORSEMENT', display: "Definite endorsement", displayEl: "", comtecCode: "" }]));
    },

    actions: {
      typeChanged: function typeChanged(value) {
        if (this.get('onTypeChanged')) {
          this.get('onTypeChanged')(value);
        }
      }
    }
  });
});