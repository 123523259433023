define('minerva-frontend/serializers/covernote', ['exports', 'ember-data', 'minerva-frontend/serializers/application'], function (exports, _emberData, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      cancellationRequest: { embedded: 'always' }
    },
    modelClassName: 'covernote',
    /*
    pushPayload(store, payload) {
      var modelClass = store.modelFor('covernote');
      var payload2 = this.normalizeResponse(store, modelClass, payload, undefined, 'query');
      //return this._super(store, payload2);
      return store.push(payload2);
    }
    */
    serializeAttribute: function serializeAttribute(snapshot, json, key, attributes) {
      if (name === "pdfLinks") {
        return false;
      }
      return this._super.apply(this, arguments);
    }
  });
});