define('minerva-frontend/validations/endorsement', ['exports', 'minerva-frontend/validations/document', 'minerva-frontend/validations/covernote', 'ember-changeset-validations/validators', 'minerva-frontend/validators/generic/date-before', 'ember-changeset-conditional-validations/validators/sometimes', 'minerva-frontend/validators/generic/date-after'], function (exports, _document, _covernote, _validators, _dateBefore, _sometimes, _dateAfter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.EndorsementValidations = undefined;
  var assign = Ember.assign;
  var EndorsementValidations = exports.EndorsementValidations = {
    nationality: (0, _validators.validatePresence)(false),

    endDateTime: (0, _sometimes.default)([(0, _validators.validatePresence)(true), (0, _dateBefore.default)({
      field: 'startDateTime',
      months: -4,
      hours: -23,
      minutes: -59,
      message: '{description} cannot be more than 4 months after start date'
    })], function (changes, content) {
      return Ember.get(changes, 'customStartEndDate') == true;
    })

    /*
    endDateTime: validateSometimes([
      validatePresence(true),
      validateDateBefore({
        field: 'maxEndDateTime',
        message: '{description} cannot be more than the expiration date of the proposal {onOrBefore}'
      })
    ], function (changes, content) {
      return (get(changes, 'customStartEndDate') == true);
    }),
    */
  };

  exports.default = assign({}, _document.default, _covernote.default, EndorsementValidations);
});