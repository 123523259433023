define('minerva-frontend/components/lob-forms/mi-accident-proposal/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    miAccidentPlans: Ember.computed.alias('params.miaccident-plans'),
    isClientCompany: Ember.computed.equal('clientIdType', 'COMPANY_REGISTRATION'),
    init: function init() {
      this._super.apply(this, arguments);
      this.set('_changeset.isClientCompany', this.get('isClientCompany')); // Set so the MiAccidentValidations can use it to decide if certain validations should happen. the isCLientCompany is not an actual value passed to the model
      //this.set('_changeset.coinsurancePercentage', this.get('coinsurancePercentages').findBy('id','COINSURANCE_1'));
      //this.set('_changeset.annualExcess', this.get('mihealthExcesses').findBy('id','EXCESS_1'));
    },

    actions: {
      emptySubmit: function emptySubmit() {},
      submit: function submit(model, submissionStatus) {
        //Here we should handle Lob specific logic. When done, pass the stuff on to the form-wrapper to submit the info!
        this.onSubmit(model, submissionStatus);
      }
    }
  });
});