define('minerva-frontend/controllers/user', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      activateUser: function activateUser() {
        if (confirm('Are you sure you want to activate this user account?')) {
          this.get('model').set('deactivated', false);
          this.get('model').save().then(function (user) {
            user.reload();
          });
        }
      },
      deactivateUser: function deactivateUser() {
        if (confirm('Are you sure you want to deactivate this user account?')) {
          this.get('model').set('deactivated', true);
          this.get('model').save().then(function (user) {
            user.reload();
          });
        }
      }
    }
  });
});