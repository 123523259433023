define('minerva-frontend/components/generic-forms/supplementary-info-form/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    changeset: undefined,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('specialTermsPreselections', ["Ο πιο πάνω όρος διαγράφεται και με την γραπτή συγκατάθεση της εταιρείας, στον οδηγό ΧΧΧΧΧΧΧΧΧΧΧΧΧΧΧΧΧΧ, Αρ. Ταυτότητας ΧΧΧΧΧΧΧ, παρέχεται κάλυψη Περιεκτική.", "ΕΚΧΩΡΗΣΗ - Με την παρούσα συμφωνείται και αναγνωρίζεται ότι όλα τα δικαιώματα του παρόντος Καλυπτικού Σημειώματος εκχωρούνται προς τον/την  ΧΧΧΧΧΧΧΧΧΧΧΧΧΧ", "Για σκοπούς εξέτασης για απόκτηση άδειας οδήγησης. Το παρών καλυπτικό καλύπτει και τον εξεταστή."]); //['This is the term 1', 'This is the term 2', 'You can always do something else', 'The covernote is in your hands!', 'Do not insure the TRSX!']);
    },

    isSpecialTermsVisible: Ember.computed.alias('currentUser.user.allowSpecialTerms'),
    actions: {
      autopopulateSpecialTerms: function autopopulateSpecialTerms(term) {
        this.set('changeset.specialTerms', term);
      }
    }
  });
});