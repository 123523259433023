define('minerva-frontend/validations/insured-person', ['exports', 'ember-changeset-validations/validators', 'moment', 'minerva-frontend/validators/special/insured-person-birthday', 'ember-changeset-conditional-validations/validators/sometimes'], function (exports, _validators, _moment, _insuredPersonBirthday, _sometimes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    insuredName: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ min: 4, max: 30 })],
    insuredId: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ integer: true }), (0, _validators.validateLength)({ min: 4, max: 8 })],
    dateOfBirth: [(0, _validators.validatePresence)(true), (0, _insuredPersonBirthday.default)({
      message: '{description} must be greater than {age_low} and lower than {age_high}.',
      relationField: 'relationToPolicyHolder'
    })],
    relationToPolicyHolder: [(0, _validators.validatePresence)(true)],
    childOccupation: (0, _sometimes.default)([(0, _validators.validatePresence)(true)], function (changes, content) {
      var relationToPolicyHolder = Ember.get(changes, 'relationToPolicyHolder') || Ember.get(content, 'relationToPolicyHolder');
      if (relationToPolicyHolder === 'CHILD') {
        var dateOfBirthStr = Ember.get(changes, 'dateOfBirth') || Ember.get(content, 'dateOfBirth');
        var dateOfBirth = (0, _moment.default)(dateOfBirthStr);
        if (dateOfBirth.isValid() && (0, _moment.default)().diff(dateOfBirth, 'years', true) >= 18) {
          return true;
        }
      }
      return false;
    })
  };
});