define('minerva-frontend/validations/proposals/private', ['exports', 'ember-changeset-validations/validators', 'minerva-frontend/validations/covernotes/private', 'minerva-frontend/validations/proposals/motor'], function (exports, _validators, _private, _motor) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.PrivateProposalValidations = undefined;
  var PrivateProposalValidations = exports.PrivateProposalValidations = {
    /*
    CHECKED ON document-generic-info
    endDateTime: validateSometimes([
      validatePresence(true),
      validateDateBefore({
        field: 'startDateTime',
        years: -1,
        hours: -23,
        minutes: -59,
        message: '{description} cannot be more than 1 year after start date'
      }),
      validateDateAfter({
        field: 'minEndDateTime',
        message: '{description} cannot be less than {onOrAfter}'
      }),
      validateDateAfter({
        field: 'startDateTime',
        message: '{description} cannot be before start date'
      })
    ], function (changes, content) {
      return (get(changes, 'customStartEndDate') == true);
    }),
    */
    vehicleCategory: (0, _validators.validatePresence)(true),
    vehicleType: (0, _validators.validatePresence)(true)
  };
  exports.default = Ember.assign({}, _private.default, _motor.default, PrivateProposalValidations);
});