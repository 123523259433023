define('minerva-frontend/components/covernotes/request-cancellation-list-table/component', ['exports', 'ember-models-table/components/models-table-server-paginated', 'minerva-frontend/mixins/list-table'], function (exports, _modelsTableServerPaginated, _listTable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _modelsTableServerPaginated.default.extend(_listTable.default, {
    classNames: "covernote-table-wrapper",
    router: Ember.inject.service(),
    showGlobalFilter: false,
    columns: [{
      "propertyName": "documentDateTimeFormatted",
      "title": "Submitted on",
      "disableFiltering": true,
      "disableSorting": true
    }, {
      "propertyName": "lastModificationDateTimeFormatted",
      "title": "Modified on",
      "disableFiltering": true,
      "disableSorting": true
    }, {
      "title": "Related Covernote",
      "component": "cancellation-requests/list-table-covernote-link",
      "disableFiltering": true,
      "disableSorting": true
    }, {
      "propertyName": "status",
      "disableFiltering": true,
      "disableSorting": true
    }, {
      "propertyName": "cancellationReason",
      "title": "Reason asked",
      "disableFiltering": true,
      "disableSorting": true
    }, {
      "propertyName": 'handledBy',
      "title": "Handled By",
      "disableFiltering": true,
      "disableSorting": true
    }, {
      "propertyName": "handleComments",
      "title": "Handling comments",
      "disableFiltering": true,
      "disableSorting": true
    }],
    actions: {
      clickOnRow: function clickOnRow(index, obj) {
        this.get('router').transitionTo('covernote.covernote', obj.get('covernote.id'));
      }
    }
  });
});