define('minerva-frontend/router', ['exports', 'minerva-frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('covernotes', function () {
      this.route('active');
      this.route('drafts');
      this.route('cancelled');
      this.route('pending');
      this.route('declined');
      this.route('cancellation-requests');
      this.route('all');
    });
    this.route('covernote', function () {
      this.route('new');
      this.route('edit', { path: "/edit/:covernoteId" });
      this.route('covernote', { path: "/note/:covernoteId" });
      this.route('new-endorsement');
    });
    this.route('proposals', function () {
      this.route('issued');
      this.route('drafts');
      this.route('all');
      this.route('unfiled');
    });
    this.route('proposal', function () {
      this.route('new');
      this.route('new2');
      //this.route('edit', {path: "/edit/:proposalId"});
      this.route('proposal', { path: "/document/:proposalId" });
    });
    this.route('settings', function () {
      this.route('parameters', function () {
        this.route('genders');
        this.route('packages');
        this.route('cover-types');
        this.route('driver-types');
        this.route('id-types');
        this.route('body-types');
        this.route('vehicle-categories');
        this.route('business-lines');
        this.route('occupations');
      });
      this.route('users');
    });
    this.route('profile');
    this.route('login');

    this.route('users', function () {
      this.route('new');
    });
    this.route('user', { path: "/user/:userId" });
    this.route('reports');
  });

  exports.default = Router;
});