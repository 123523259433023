define('minerva-frontend/controllers/login', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    username: "",
    password: "",
    actions: {
      authenticate: function authenticate() {
        var _this = this;

        var _getProperties = this.getProperties('username', 'password'),
            username = _getProperties.username,
            password = _getProperties.password;

        this.get('session').authenticate('authenticator:jwt', username, password).then(function () {
          _this.get('notifications').success('You were logged in successully.');
        }).catch(function (response) {
          console.log(response);
          _this.get('notifications').warning('You could not login. ' + response.payload.error_description);
          _this.set('errorMessage', response.payload.error_description || response);
        });
      }
    }
  });
});