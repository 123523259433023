define('minerva-frontend/components/generic-forms/proposal-generic-details-form/component', ['exports', 'moment', 'minerva-frontend/mixins/document-times-calculator'], function (exports, _moment, _documentTimesCalculator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_documentTimesCalculator.default, {
    _endDateTimeDefaultLabel: "",
    defaultDuration: undefined, //Object with years,months,days to specify default duration {years:x, months:x, days:x}
    _endDateTimeDuration: undefined,
    policyInstallments: Ember.computed.alias('params.policy-installments'),
    policyInstallmentsOptions: undefined,
    init: function init() {
      this._super.apply(this, arguments);
      Ember.set(this, '_endDateTimeDefaultLabel', this.getDurationLabel(Ember.get(this, 'defaultDuration')));
      Ember.set(this, '_endDateTimeDuration', Ember.get(this, 'defaultDuration'));

      var stateInitializers = this.get('stateInitializers'); //Should be passed when initializing the object
      var initState = stateInitializers ? stateInitializers.state : false;
      var times;
      if (initState) {
        //Means car is already preloaded!
        if (initState === 'COVERNOTE_TO_PROPOSAL') {
          Ember.set(this, '_changeset.customStartEndDate', true);
          times = this._getDefaultStartEndDateTimes(this.get('_changeset.startDateTime'));
          Ember.set(this, '_changeset.startDateTime', times.startDateTime);
          Ember.set(this, '_changeset.endDateTime', times.endDateTime);
          this.set('startDateLocked', true);
          this.set('customStartEndDateLocked', true);
        } else if (initState === 'CONTRACT_TO_ENDORSEMENT') {
          //Validations for checking that endDate does not pass the maxEndDate (which is the contract's end date) are defined on the motor-form
          //When initializing the changesets, instead of here. Then those values get picked up by the validators, which are to be found
          //in validations/definite-endorsemsnts/document-generic-info.js
        } else if (initState === 'COVERNOTE_FINALIZATION') {
          /*
          //If we were to set the default duration for either the covernote or the endorsement (this is passed from the parent), we would
          //keep this snippet. If we were to put the values used from the user when creating this (which seems more proper considering it probably
          //went for approval and it's being edited before approval, we use the next
          times = this._getDefaultStartEndDateTimes(this.get('_changeset.startDateTime'));
          set(this, '_changeset.startDateTime', times.startDateTime);
          set(this, '_changeset.endDateTime', times.endDateTime);
           */
          //Note, there is the case that this is coming from a Draft being finalized. Hence, the start/end dates might be NOT SET (if you see the
          //stateInitializers, we do not handle the plain covernote creation, hence we do not explicitly set a start/end time, not only until this
          //is submitted to the server.
          //So if that is the case, we should skip initialization like this was never set
          if (this.get('_changeset.startDateTime')) {
            Ember.set(this, '_changeset.customStartEndDate', true);
            Ember.set(this, '_changeset.startDateTime', new Date());
            Ember.set(this, '_changeset.endDateTime', this.get('_changeset.endDateTime'));
          }
        }
      }
    },


    _dataLocked: Ember.computed('preInitialized', 'dataLocked', function () {
      if (this.get('dataLocked') === undefined) {
        return this.get('preInitialized');
      } else {
        return this.get('dataLocked');
      }
    }),
    endDateLocked: false,
    startDateLocked: false,

    /*
    _initChangeset(model, changeset) {
      //TODO - Integrate this with init()
        const isModelPreinitialized = !model.get('isNew') || get(this, 'preInitialized');
       if (isModelPreinitialized && model.get('startDateTime')) {
        //First, lets set start date to where it should be;
        set(changeset, 'customStartEndDate', true);
        var times = this._getDefaultStartEndDateTimes(model.get('startDateTime'));
        set(changeset, 'startDateTime', times.startDateTime);
        set(changeset, 'endDateTime', times.endDateTime);
      }
      //set default values from database
      return changeset;
     },
    */

    _startDateTimeIsFuture: Ember.computed('_changeset.startDateTime', function () {
      var now = (0, _moment.default)();
      now.set({ 'hour': 23, 'minute': 59 });
      return this.get('_changeset.startDateTime') > now.toDate();
    }),

    _getDefaultStartEndDateTimes: function _getDefaultStartEndDateTimes(initialStartDate) {
      var duration = Ember.get(this, 'defaultDuration');
      return this.getStartEndDateTimes(initialStartDate, duration); //From DocumentTimesCalculator
    },
    _initCustomStartEndDateTime: function _initCustomStartEndDateTime(changeset) {
      var today = (0, _moment.default)();
      today.add(5, 'minute');
      //var tomorrow = today.add(1, 'day');
      //tomorrow.set({'hour': 0, 'minute': 1});

      var times = this._getDefaultStartEndDateTimes(today);
      changeset.set('startDateTime', times.startDateTime);
      changeset.set('endDateTime', times.endDateTime);
    },

    actions: {
      startDateTimeChanged: function startDateTimeChanged(value, changeset) {
        changeset.set('startDateTime', value);
        if (value) {
          var times = this._getDefaultStartEndDateTimes(value);
          changeset.set('endDateTime', times.endDateTime);
        }
      },
      customStartEndDateChanged: function customStartEndDateChanged(value, changeset) {
        changeset.set('customStartEndDate', value);
        if (value) {
          //Means custom start date is set
          this._initCustomStartEndDateTime(changeset);
        } else {
          changeset.set('startDateTime', undefined);
          changeset.set('endDateTime', undefined);
        }
        changeset.validate();
      },
      endDateTimeDurationChanged: function endDateTimeDurationChanged(value) {
        var _changeset = Ember.get(this, '_changeset');
        Ember.set(this, '_endDateTimeDuration', value);
        var times = this.getStartEndDateTimes(_changeset.get('startDateTime'), value);
        _changeset.set('endDateTime', times.endDateTime);
      }
    }
  });
});