define('minerva-frontend/components/reports/basic-report-generator/component', ['exports', 'minerva-frontend/config/environment', 'moment'], function (exports, _environment, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'basic-report-generator',
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    paramStore: Ember.inject.service(),
    businessLines: Ember.computed.alias('params.business-lines'),
    documentStates: undefined,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.set('documentStates', [{
        id: 'DRAFT',
        display: 'Draft'
      }, {
        id: 'ACTIVE',
        display: 'Active'
      }, {
        id: 'CANCELLED',
        display: 'Cancelled'
      }, {
        id: 'DECLINED',
        display: 'Declined'
      }, {
        id: 'PENDING_APPROVAL',
        display: 'Pending Approval'
      }]);
      var paramStore = this.get('paramStore');

      var paramsPromise = paramStore.getParams().then(function (params) {
        _this.set('params', params);
        _this.set('documentReportSettings', {
          'lobs': [],
          'documentState': [],
          'dateFrom': null,
          'dateTo': null,
          'pendingProposal': false,
          'type': '' //Should be COVERNOTE, DEFINITE_ENDORSEMENT, STANDARD_ENDORSEMENT /PROPOSAL
        });
      });
    },
    _initiateServerCall: function _initiateServerCall(endpoint, queryparams) {
      var _this2 = this;

      var headers = {};
      var url = _environment.default.APP.apiUrl + "/export/" + endpoint + "-url";
      if (queryparams) {
        url = url + '?' + queryparams;
      }
      var type = "text/csv; charset=UTF-8";
      this.get('session').authorize('authorizer:jwt', function (headerName, headerValue) {
        headers[headerName] = headerValue;
      });
      this.get('ajax').raw(url, {
        method: 'GET',
        headers: headers,
        contentType: "application/x-www-form-urlencoded; charset=UTF-8"
      }).then(function (result) {
        console.log("Successfull result");
        console.log(result);

        if (result.textStatus === 'success') {
          _this2.set('reportURL', _environment.default.APP.apiUrl + result.response.url);
        }
        /*
        var filename = 'response.csv';
        var blob = new Blob([result.response], {type: type, endings: 'native', encoding: "UTF-8"});
        var URL = window.URL || window.webkitURL;
        var downloadUrl = URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = downloadUrl;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        a.remove();
        */
      }).finally(function () {});
    },
    _get_ids_csv: function _get_ids_csv(arr) {
      return arr.map(function (value) {
        return Ember.get(value, 'id');
      });
    },
    _get_date_param: function _get_date_param(date) {
      return (0, _moment.default)(date).format('YYYYMMDD');
    },

    actions: {
      generateReport: function generateReport(endpoint) {
        var _this3 = this;

        if (endpoint == 'users') {
          this._initiateServerCall(endpoint, null);
        } else {
          var documentReportSettings = this.get('documentReportSettings');
          console.log("Generating report...", documentReportSettings);
          var queryparams = [];
          var temp_val = null,
              val = null;
          Object.keys(documentReportSettings).forEach(function (key, index) {
            // key: the name of the object key
            temp_val = documentReportSettings[key];

            if (temp_val) {
              if (key === 'lobs' || key === 'documentState') {
                val = _this3._get_ids_csv(temp_val);
              } else if (key === 'dateFrom' || key === 'dateTo') {
                val = _this3._get_date_param(temp_val);
              } else {
                val = temp_val;
              }
            } else {
              val = null;
            }
            if (val) {
              queryparams.push(key + '=' + val);
            }
          });
          console.log(queryparams);
          if (Object.keys(queryparams).length !== 0) {
            this._initiateServerCall(endpoint, queryparams.join("&"));
          }
        }

        return false;
      }
    }
  });
});