define('minerva-frontend/components/proposals/list-table/component', ['exports', 'ember-models-table/components/models-table-server-paginated', 'minerva-frontend/mixins/list-table'], function (exports, _modelsTableServerPaginated, _listTable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _modelsTableServerPaginated.default.extend(_listTable.default, {
    classNames: "proposal-table-wrapper",
    currentUser: Ember.inject.service(),
    router: Ember.inject.service(),

    columnsAgent: [{
      "propertyName": "documentNumber",
      "routeName": "proposal.proposal",
      "className": "document-number-col"
    }, {
      "propertyName": "startDateTimeFormatted",
      "title": "Start On",
      "disableFiltering": true,
      "disableSorting": true
    }, {
      "propertyName": "endDateTimeFormatted",
      "title": "Until",
      "disableFiltering": true,
      "disableSorting": true
    }, {
      "propertyName": "lineOfBusiness.display",
      "title": "Line of Business",
      "disableFiltering": true,
      "disableSorting": true
    }, {
      "title": "Cover Info",
      "component": "proposals/list-table-cover-info-wrapper",
      "disableFiltering": true,
      "disableSorting": true
    }],

    showState: false,
    showOwner: Ember.computed.or('currentUser.user.isAdmin', 'currentUser.user.isUnderwriter', 'currentUser.user.isOfficeHead'),
    columns: Ember.computed('showState', 'showOwner', function () {
      var columns = Ember.get(this, 'columnsAgent').slice(0);
      if (Ember.get(this, 'showState')) {
        columns.insertAt(2, {
          "propertyName": "state",
          "disableFiltering": true,
          "disableSorting": true
        });
      }
      if (Ember.get(this, 'showOwner')) {
        columns.insertAt(2, {
          "propertyName": "ownerID",
          "title": "Owner",
          "disableFiltering": true,
          "disableSorting": true
        });
      }
      return columns;
    }),
    actions: {
      clickOnRow: function clickOnRow(index, obj) {
        this.get('router').transitionTo('proposal.proposal', obj.get('id'));
      }
    }
  });
});