define('minerva-frontend/validations/covernotes/motor', ['exports', 'ember-changeset-conditional-validations/validators/sometimes', 'ember-changeset-validations/validators'], function (exports, _sometimes, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.CovernoteValidations = undefined;
  var CovernoteValidations = exports.CovernoteValidations = {
    documentType: [(0, _validators.validatePresence)(true)],
    //lineOfBusiness: validatePresence(true), - The way the process is right now, you cannot ommit this

    /*
    DEFINED ON DOCUMENT GENERIC INFO
    startDateTime: validateSometimes([
      validatePresence(true),
      validateDateAfter({
        minutes: -30,
        message: '{description} cannot start earlier than now'
      })
    ], function (changes, content) {
      return (get(changes, 'customStartEndDate') == true);
    }),
     endDateTime: validateSometimes([
      validatePresence(true),
      validateDateBefore({
        field: 'startDateTime',
        months: -4,
        hours: -23,
        minutes: -59,
        message: '{description} cannot be more than 4 months after start date'
      }),
      validateDateAfter({
        field: 'startDateTime',
        months: 1,
        message: '{description} must be at least 1 month after start date',
      })
    ], function (changes, content) {
      return (get(changes, 'customStartEndDate') == true);
    }),
    */

    coverType: (0, _validators.validatePresence)(true),

    vehicleRegistrationNumber: (0, _sometimes.default)([(0, _validators.validatePresence)(true)], function (changes, content) {
      return !(Ember.get(changes, 'vehicleChassisNumber') || Ember.get(content, 'vehicleChassisNumber'));
    }),
    vehicleChassisNumber: (0, _sometimes.default)([(0, _validators.validatePresence)(true)], function (changes, content) {
      return !(Ember.get(changes, 'vehicleRegistrationNumber') || Ember.get(content, 'vehicleRegistrationNumber'));
    }),
    //vehicleRegistrationNumber - Required either this or the chassis number
    //vehicleChassisNumber

    typeOfBody: (0, _validators.validatePresence)(true),
    vehicleMake: (0, _validators.validatePresence)(true),
    vehicleModel: (0, _validators.validatePresence)(true),
    vehicleEngineCapacity: (0, _validators.validateNumber)({ positive: true, integer: true, allowBlank: false }),
    vehicleNumberOfSeats: (0, _validators.validateNumber)({ positive: true, integer: true, allowBlank: false }),
    //trailerNumber : presence if trailer is true

    driversType: (0, _validators.validatePresence)(true),
    drivers: (0, _sometimes.default)([(0, _validators.validateLength)({ min: 1, max: 6, allowBlank: false }), (0, _validators.validatePresence)(true)], function (changes, content) {
      if (!(Ember.get(changes, 'driversType') || Ember.get(content, 'driversType'))) {
        return false;
      } else if (Ember.get(changes, 'driversType.id') === 'ANY_DRIVER' || Ember.get(content, 'driversType.id') === 'ANY_DRIVER' || Ember.get(changes, 'driversType.id') === 'ANY_DRIVER_WITH_EXCLUSION' || Ember.get(content, 'driversType.id') === 'ANY_DRIVER_WITH_EXCLUSION') {
        return false;
      } else {
        return true;
      }
    })
  };

  exports.default = CovernoteValidations;
});