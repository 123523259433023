define('minerva-frontend/components/generic-forms/mi-health/insured-persons-wizard-form/component', ['exports', 'ember-changeset-validations', 'ember-changeset', 'minerva-frontend/validations/insured-person', 'minerva-frontend/validations/owner-insured-person', 'moment'], function (exports, _emberChangesetValidations, _emberChangeset, _insuredPerson, _ownerInsuredPerson, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var InsuredPerson = Ember.Object.extend({
    insuredId: '',
    insuredName: '',
    dateOfBirth: '',
    relationToPolicyHolder: null,
    ownerInsured: false,
    childOccupation: null
  });
  exports.default = Ember.Component.extend({
    InsuredPersonValidations: _insuredPerson.default,
    dataLocked: undefined,
    insuredPersons: null,
    value: undefined,
    _insuredPersonInEditMode: Ember.computed.filterBy('_personChangesets', 'isEditing', true),
    _hasInsuredPersonsInEditMode: Ember.computed.notEmpty('_insuredPersonInEditMode'), //Need to make sure to show it in the template, so it;s beeing forced to be calculated!!
    _hasInsuredPersonsInEditModeChange: Ember.observer('_hasInsuredPersonsInEditMode', function () {
      this.set('hasInsuredPersonsInEditMode', this.get('_hasInsuredPersonsInEditMode'));
    }),
    _personsCount: Ember.computed.alias('_persons.length'),
    _canAddPersons: Ember.computed.lt('_personsCount', 7),
    _ownerInsuredgButtonShown: Ember.computed('_personChangesets.[]', function () {
      var personChangesets = Ember.get(this, '_personChangesets');
      var found = false;
      personChangesets.forEach(function (personChangeset) {
        if (Ember.get(personChangeset, 'ownerInsured')) {
          found = true;
        }
      });
      return !found;
    }),
    _insuredPersonsLocked: Ember.computed.alias('dataLocked'),

    _getInsuredPersons: function _getInsuredPersons() {
      var personChangesets = Ember.get(this, '_personChangesets');
      var insuredPersons = [];
      personChangesets.forEach(function (personChangeset) {
        if (personChangeset.get('isValid')) {
          insuredPersons.push({
            insuredId: Ember.get(personChangeset, 'insuredId'),
            insuredName: Ember.get(personChangeset, 'insuredName'),
            dateOfBirth: Ember.get(personChangeset, 'dateOfBirth') ? (0, _moment.default)(Ember.get(personChangeset, 'dateOfBirth')).format('YYYY-MM-DD') : null,
            ownerInsured: Ember.get(personChangeset, 'ownerInsured'),
            relationToPolicyHolder: Ember.get(personChangeset, 'relationToPolicyHolder'),
            childOccupation: Ember.get(personChangeset, 'relationToPolicyHolder') === 'CHILD' ? Ember.get(personChangeset, 'childOccupation') : null // If not a child, don't process this value at all, to avoid cases where child was first selected, then deselected, but this stated populated
          });
        }
      });
      return insuredPersons;
    },

    _persons: Ember.computed('_personChangesets.[]', function () {
      return this._getInsuredPersons();
    }),
    init: function init() {
      this._super.apply(this, arguments);
      var existingInsuredPersons = Ember.get(this, 'value');
      var insuredPersons = Ember.A([]);
      var changesetInsuredPersons = Ember.A([]);
      if (!existingInsuredPersons || !existingInsuredPersons.length) {
        Ember.set(this, '_personChangesets', changesetInsuredPersons);
      } else {
        existingInsuredPersons.forEach(function (personPOJO) {
          var insuredPerson = InsuredPerson.create(personPOJO);
          if (personPOJO.dateOfBirth) {
            insuredPerson.set('dateOfBirth', new _moment.default(personPOJO.dateOfBirth, 'YYYY/MM/DD').toDate());
          }
          insuredPerson.set('isEditing', false);
          insuredPersons.pushObject(insuredPerson);
          var validationClass = _insuredPerson.default;
          if (insuredPerson.get('ownerInsured')) {
            validationClass = _ownerInsuredPerson.default;
          }
          var personChangeset = new _emberChangeset.default(insuredPerson, (0, _emberChangesetValidations.default)(validationClass), validationClass);
          changesetInsuredPersons.pushObject(personChangeset);
        });
      }
      console.log("_personChangesets updated", changesetInsuredPersons);
      Ember.set(this, '_personChangesets', changesetInsuredPersons);
    },


    actions: {
      addInsured: function addInsured() {
        var person = InsuredPerson.create({ isEditing: true });
        var personChangeset = new _emberChangeset.default(person, (0, _emberChangesetValidations.default)(_insuredPerson.default), _insuredPerson.default);
        var persons = Ember.get(this, '_personChangesets');
        persons.pushObject(personChangeset);
        personChangeset.validate();
      },
      addOwnerInsured: function addOwnerInsured() {
        var person = InsuredPerson.create({ ownerInsured: true, isEditing: true });
        var personChangeset = new _emberChangeset.default(person, (0, _emberChangesetValidations.default)(_ownerInsuredPerson.default), _ownerInsuredPerson.default);
        var persons = Ember.get(this, '_personChangesets');
        persons.pushObject(personChangeset);
        personChangeset.validate();
      },
      removePerson: function removePerson(index) {
        Ember.get(this, '_personChangesets').removeAt(index);
        this.get('onchange')(this._getInsuredPersons());
      },
      updatePersons: function updatePersons() {
        console.log("Propagating updating insured persons...");
        this.get('onchange')(this._getInsuredPersons());
      }
    }
  });
});