define('minerva-frontend/components/generic-forms/motor/driver-single-form/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    model: null,
    showExcess: null,
    showLearner: null,
    isOwner: null,
    driverIdDisabled: Ember.computed.alias('isOwner'),
    driverNameDisabled: Ember.computed.alias('isOwner'),
    dateOfBirthDisabled: Ember.computed.alias('isOwner'),

    _excessAmountLocked: false, //alias('model.learner'),
    _excessAmount: "",
    _saved: undefined,
    init: function init() {
      this._super.apply(this, arguments);
      if (this.get('model.isEditing')) {
        this.set('_saved', false);
      } else {
        this.set('_saved', true);
      }
    },

    actions: {
      learnerChanged: function learnerChanged(value, changeset) {
        changeset.set('learner', value);
        if (value) {
          //this.get('actions').excessAmountChanged.call(this, 500, changeset);
          changeset.set('excessAmount', 500);
          this.set('_excessAmount', 500);
          this.set('_excessAmountLocked', true);
        } else {
          changeset.set('excessAmount', '');
          this.set('_excessAmount', '');
          this.set('_excessAmountLocked', false);
        }
      },
      remove: function remove(index) {
        this.get('onRemove')(index);
      },
      saveData: function saveData(changeset) {
        var _this = this;

        changeset.validate().then(function () {
          if (changeset.get('isValid')) {
            console.log('Saving driver to true');
            _this.set('_saved', true);
            _this.set('model.isEditing', false);
            _this.get('onchange')();
          }
        });
        //return false;
      },
      editData: function editData() {
        this.set('_saved', false);
        console.log("Starting editing of driver...");
        this.set('model.isEditing', true);
      },
      validate: function validate(changeset) {
        //console.log('validating driver form');
        changeset.validate();
        return false;
      }
    }
  });
});