define('minerva-frontend/components/covernotes/transform-to-endorsement-form/component', ['exports', 'ember-changeset-validations', 'ember-changeset', 'minerva-frontend/validations/covernote-transform-to-endorsement', 'minerva-frontend/config/environment'], function (exports, _emberChangesetValidations, _emberChangeset, _covernoteTransformToEndorsement, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    TransformToEndorsementValidations: _covernoteTransformToEndorsement.default,
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    contractReference: undefined,
    confirmation: false,
    covernote: undefined,
    _loadedPolicy: undefined,
    _loadingPolicy: false,
    _submittingEndorsement: false,
    init: function init() {
      this._super.apply(this, arguments);
      var covernote = this.get('covernote');
      var changeset = new _emberChangeset.default(covernote, (0, _emberChangesetValidations.default)(_covernoteTransformToEndorsement.default), _covernoteTransformToEndorsement.default);
      this.set('changeset', changeset);
    },

    actions: {
      checkPolicy: function checkPolicy() {
        var _this = this;

        var existingContractReference = this.get('changeset.contractReference');
        if (!existingContractReference) {
          return;
        }
        var lineOfBusiness = this.get('covernote.lineOfBusiness');
        var url = _environment.default.APP.apiUrl + "/covernotes/policydetails";
        var headers = {};
        this.get('session').authorize('authorizer:jwt', function (headerName, headerValue) {
          headers[headerName] = headerValue;
        });
        var notifications = this.get('notifications');
        this.set('_loadingPolicy', true);
        this.get('ajax').request(url, {
          method: 'GET',
          headers: headers,
          contentType: "application/x-www-form-urlencoded; charset=UTF-8",
          data: {
            lob: Ember.get(lineOfBusiness, 'comtecCode'),
            policyId: existingContractReference
          }
        }).then(function (response) {
          _this.set('_loadedPolicy', response);
        }).finally(function () {
          _this.set('_loadingPolicy', false);
        });
      },
      submit: function submit(changeset) {
        var _this2 = this;

        var payload = { policyId: changeset.get('contractReference') };
        console.log(payload);
        this.set('_submittingEndorsement', true);
        var request = this.get('covernote').transformToEndorsement(payload, { pushToStore: true }).then(function (status) {
          _this2.get('notifications').success("Your covernote has been converted to an endorsement successfully");
          if (_this2.get('on-success')) {
            _this2.get('on-success')();
          }
          _this2.set('_submittingEndorsement', false);
        }).catch(function (response) {
          //request.rollbackAttributes();
          _this2.set('_submittingEndorsement', false);
          _this2.get('notifications').error('There was an error while trying to convert your covernote to an endorsement. ' + response.errors);
        });
      }
    }
  });
});