define('minerva-frontend/components/generic-forms/lob-selection-form/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    paramStore: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    businessLines: undefined,
    widgetOnly: false,
    covernoteMode: true,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      var paramStore = this.get('paramStore');
      paramStore.getParams().then(function (params) {
        var user = _this.get('currentUser.user');
        var allowedLobs = void 0;
        if (_this.get('endorsementMode')) {
          allowedLobs = Ember.get(user, 'allowedEndorsementLobs') ? Ember.get(user, 'allowedEndorsementLobs') : [];
        } else if (_this.get('covernoteMode')) {
          allowedLobs = Ember.get(user, 'allowedCovernoteLobs') ? Ember.get(user, 'allowedCovernoteLobs') : [];
        } else if (_this.get('proposalMode')) {
          allowedLobs = Ember.get(user, 'allowedProposalLobs') ? Ember.get(user, 'allowedProposalLobs') : [];
        } else {
          allowedLobs = [];
        }
        Ember.set(_this, 'businessLines', Ember.get(params, 'business-lines').filter(function (lob) {
          return allowedLobs.findIndex(function (checkLob) {
            return checkLob.id === lob.id;
          }) >= 0;
        }));
      });
    },

    actions: {
      lobChanged: function lobChanged(value) {
        if (this.get('onLobChanged')) {
          this.get('onLobChanged')(value);
        }
      }
    }
  });
});