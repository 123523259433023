define("minerva-frontend/controllers/index", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    loadingLateProposalCovernotes: true,
    loadingMissingDocumentsCovernotes: true,
    covernoteColumns: [{
      "propertyName": "documentNumber",
      "routeName": "covernote.covernote",
      "className": "document-number-col"
    }, {
      "propertyName": "vehicleRegistrationNumber",
      "title": "Vehicle Reg No.",
      "disableSorting": true,
      "className": "vehicle-reg-number-col"
    }, {
      "propertyName": "documentDateTimeFormatted",
      "title": "Created on",
      "disableFiltering": true,
      "disableSorting": true
    }, {
      "propertyName": "startDateTimeFormatted",
      "title": "Starts On",
      "disableFiltering": true,
      "disableSorting": true
    }, {
      "propertyName": "endDateTimeFormatted",
      "title": "Until",
      "disableFiltering": true,
      "disableSorting": true
    }, {
      "propertyName": "coverType.display",
      "title": "Cover",
      "disableFiltering": true,
      "disableSorting": true
    }, {
      "propertyName": "lineOfBusiness.display",
      "title": "LOB",
      "disableFiltering": true,
      "disableSorting": true
    }]
  });
});