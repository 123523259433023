define('minerva-frontend/validators/special/insured-person-birthday', ['exports', 'ember-changeset-validations/utils/validation-errors', 'ember-validators', 'moment'], function (exports, _validationErrors, _emberValidators, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = validateInsuredPersonBirthday;
  function validateInsuredPersonBirthday() {
    var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    return function (key, newValue, oldValue, changes, content) {
      var relationToPolicyHolder = Ember.get(changes, options.relationField) || Ember.get(content, options.relationField);

      if (!relationToPolicyHolder) {
        return true; //No need to validate. we do not have a relationship to compare against to
      }
      var minBirthDate = (0, _moment.default)();
      var maxBirthDate = (0, _moment.default)();
      var isChild = relationToPolicyHolder && relationToPolicyHolder === 'CHILD';

      if (isChild) {
        minBirthDate = minBirthDate.subtract(3, 'months');
        maxBirthDate = maxBirthDate.subtract(25, 'years');
        options.age_low = "3 months";
        options.age_high = "25";
      } else {
        minBirthDate = minBirthDate.subtract(18, 'years');
        maxBirthDate = maxBirthDate.subtract(65, 'years');
        options.age_low = 18;
        options.age_high = 65;
      }

      //options.message = "Insured Birth date must be after " + minBirthDate.format('DD/MM/YYYY') + " and before " + maxBirthDate.format('DD/MM/YYYY');
      options.after = maxBirthDate.toISOString();
      options.before = minBirthDate.toISOString();
      options.format = 'YYYY-MM-DDTHH:mm:ssZ';

      //Also validate if greater than birthdate
      var result = (0, _emberValidators.validate)('date', newValue, options, null, key);
      return result === true ? true : (0, _validationErrors.default)(key, result, newValue, options);
    };
  }
});