define('minerva-frontend/models/proposal', ['exports', 'ember-data', 'ember-data/attr', 'minerva-frontend/models/minerva-document', 'ember-moment/computeds/moment', 'ember-moment/computeds/format', 'ember-moment/computeds/locale'], function (exports, _emberData, _attr, _minervaDocument, _moment, _format, _locale) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.proposalSchema = undefined;
  var proposalSchema = exports.proposalSchema = {

    documentType: _emberData.default.attr('string', { defaultValue: 'PROPOSAL' }),
    agentCode: (0, _attr.default)('string'),

    ////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////
    //////////                                                               ///////////
    //////////                                                               ///////////
    //////////                                                               ///////////
    //////////                      Motor related Fields                     ///////////
    //////////                                                               ///////////
    //////////                                                               ///////////
    //////////                                                               ///////////
    ////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////
    relatedCovernote: _emberData.default.belongsTo('covernote'),
    //Cover details
    coverPackage: (0, _attr.default)('enum'), //belongsTo('params/package'),
    //cashPayment: attr('boolean'), //PEnding
    vehicleCategory: (0, _attr.default)('enum'), //belongsTo('params/vehicle-category'),
    vehicleSpecialType: (0, _attr.default)('enum'), //belongsTo('vehicle-special-type'), //MISSING FROM JAVA
    vehicleType: (0, _attr.default)('enum'), //belongsTo('params/vehicle-type'),
    proposalDate: (0, _attr.default)('date'), //!!!

    ntalika: (0, _attr.default)('boolean'), //MISSING FROM JAVA
    drivingSchool: (0, _attr.default)('boolean'), //MISSING FROM JAVA
    vehicleAntique: (0, _attr.default)('boolean'), //MISSING FROM JAVA
    ownPackage: (0, _attr.default)('boolean'), //MISSING FROM JAVA
    //vehicle information

    convertible: (0, _attr.default)('boolean'),
    highPerformance: (0, _attr.default)('boolean'),
    //highPower?!!!
    leftHandDrive: (0, _attr.default)('boolean'),

    //drivers info
    driverWithDisability: (0, _attr.default)('boolean'),
    youngOrOldInOtherPolicy: (0, _attr.default)('boolean'),
    additionalExcess: (0, _attr.default)('enum'),
    noClaimsDiscount: (0, _attr.default)('enum'), //belongsTo('params/no-claim-discount'),
    //MinervaAdditionalExcess additionalExcess;

    //covers info
    maliciousDamageCover: (0, _attr.default)('boolean'), // COMPREHENSIVE ONLY
    maliciousFire: (0, _attr.default)('boolean'), //Fire & Theft ONLY
    stormCover: (0, _attr.default)('boolean'), // COMPREHENSIVE ONLY
    ncdProtection: (0, _attr.default)('boolean'), // whatever this is // COMPREHENSIVE ONLY
    nclProtectionCover: (0, _attr.default)('boolean'), // whatever this is // THIRD PARTY ONLY
    roadAssistance: (0, _attr.default)('boolean'),
    ownerDrivingOtherVehicles: (0, _attr.default)('boolean'),
    lossOfUse: (0, _attr.default)('boolean'),

    personalAccident: (0, _attr.default)('number'),
    windscreen: (0, _attr.default)('number'),
    hailstormCover: (0, _attr.default)('boolean'),

    goodsInTransitCover: (0, _attr.default)('boolean'),
    goodsInTransitCoverAmount: (0, _attr.default)('number'),
    toolOfTradeCoverAmount: (0, _attr.default)('number'),
    moreRemarks: (0, _attr.default)('string'),

    installments: (0, _attr.default)('enum'),
    allDriversAreEmployees: (0, _attr.default)('boolean'),
    physicalDocumentsReceived: (0, _attr.default)('boolean', { defaultValue: false }),
    ////---------------------------------------------------------------------------/////
    ////---------------------------------------------------------------------------/////

    ////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////
    //////////                                                               ///////////
    //////////                                                               ///////////
    //////////                                                               ///////////
    //////////                          EL Helper fields                     ///////////
    //////////                                                               ///////////
    //////////                                                               ///////////
    //////////                                                               ///////////
    ////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////
    ame: (0, _attr.default)('string'),
    salary: (0, _attr.default)('string'),
    agriMachineUse: (0, _attr.default)('boolean'),
    ////---------------------------------------------------------------------------/////
    ////---------------------------------------------------------------------------/////

    ////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////
    //////////                                                               ///////////
    //////////                                                               ///////////
    //////////                                                               ///////////
    //////////                    Medical Helper fields                      ///////////
    //////////                                                               ///////////
    //////////                                                               ///////////
    //////////                                                               ///////////
    ////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////
    medicalPolicyNumber: (0, _attr.default)('string'),
    helperPassportNumber: (0, _attr.default)('string'),
    helperFirstName: (0, _attr.default)('string'),
    helperLastName: (0, _attr.default)('string'),
    helperFullName: (0, _attr.default)('string'),
    helperDateOfBirth: (0, _attr.default)('date-string-serializable'),

    helperNationality: _emberData.default.belongsTo('country'), //TODO
    helperGender: (0, _attr.default)('enum'), //TODO
    helperAddressOfEmployment: (0, _attr.default)('string'), //TODO
    medicalCoverType: (0, _attr.default)('enum'),
    ////---------------------------------------------------------------------------/////
    ////---------------------------------------------------------------------------/////

    ////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////
    //////////                                                               ///////////
    //////////                                                               ///////////
    //////////                                                               ///////////
    //////////                    ELnM Helper fields                         ///////////
    //////////                                                               ///////////
    //////////                                                               ///////////
    //////////                                                               ///////////
    ////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////
    //No Extra fields. Compines EL Helper and Medical Helper fields together
    helperOccupation: (0, _attr.default)('enum'), //Shown on both EL and Medical though
    ////---------------------------------------------------------------------------/////
    ////---------------------------------------------------------------------------/////

    ////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////
    //////////                                                               ///////////
    //////////                                                               ///////////
    //////////                                                               ///////////
    //////////                    MI Health fields                           ///////////
    //////////                                                               ///////////
    //////////                                                               ///////////
    //////////                                                               ///////////
    ////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////
    annualExcess: (0, _attr.default)('enum'),
    coinsurancePercentage: (0, _attr.default)('enum'),
    insuredPersons: (0, _attr.default)(), //Array of insured person objects

    ////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////
    //////////                                                               ///////////
    //////////                                                               ///////////
    //////////                                                               ///////////
    //////////                    MI Accident fields                           ///////////
    //////////                                                               ///////////
    //////////                                                               ///////////
    //////////                                                               ///////////
    ////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////
    miAccidentPlan: (0, _attr.default)('enum'),
    otherInsuredPerson: (0, _attr.default)(),
    weight: (0, _attr.default)('string'),
    height: (0, _attr.default)('string'),

    ////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////
    //////////                                                               ///////////
    //////////                                                               ///////////
    //////////                                                               ///////////
    //////////                    MI Place Express fields                           ///////////
    //////////                                                               ///////////
    //////////                                                               ///////////
    //////////                                                               ///////////
    ////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////  
    //miPlaceRiskLocation: attr('enum'), 


    //address fields
    miPlacePremisesStreet: _emberData.default.belongsTo('street'),
    //miPlacePremisesStreetName: attr('string'), //Used only of street is not chosen
    //miPlacePremisesCityCodeName: attr('string'), //Used only of street is not chosen
    miPlacePremisesHomeStreetNumber: (0, _attr.default)('string'),
    miPlacePremisesApartmentNumber: (0, _attr.default)('number'),
    miPlacePremisesPostCode: (0, _attr.default)('string'),
    miPlacePremisesBuilding: (0, _attr.default)('string'),
    ///

    // Premises Details
    miPlacePremisesUse: (0, _attr.default)('enum'),
    miPlacePremisesConstructionYear: (0, _attr.default)('number'),
    miPlacePremisesRenovationYear: (0, _attr.default)('number'), //MISSING from COMTEC fields
    miPlacePremisesFloorCount: (0, _attr.default)('number'),
    miPlacePremisesWallsType: (0, _attr.default)('enum'),
    miPlacePremisesCeilingType: (0, _attr.default)('enum'),
    miPlacePremisesFrameType: (0, _attr.default)('enum'),
    miPlacePremisesBasement: (0, _attr.default)('boolean'), //PDF has it boolean. Comtec needs a number?
    miPlacePremisesDependency: (0, _attr.default)('enum'), //MISSING from COMTEC fields
    miPlacePremisesHasFireAlarm: (0, _attr.default)('boolean'),
    miPlacePremisesHasPool: (0, _attr.default)('boolean'),
    // Cover plan options
    miPlaceCoverEarthquake: (0, _attr.default)('boolean'),
    miPlaceCoverReinstateValue: (0, _attr.default)('boolean'),
    miPlaceCoverPublicLiability: (0, _attr.default)('boolean'),
    miPlaceCoverForestFire: (0, _attr.default)('boolean'),
    miPlaceCoverRentLoss: (0, _attr.default)('boolean'),
    miPlaceCoverFoodSpoilage: (0, _attr.default)('boolean'),
    miPlaceCoverLiabilityToTenant: (0, _attr.default)('boolean'),
    miPlaceCoverHouseholdAppliances: (0, _attr.default)('boolean'),
    miPlaceCoverWaterLoss: (0, _attr.default)('boolean'),
    miPlaceCoverExtendedExplosion: (0, _attr.default)('boolean'),
    miPlaceCoverOccupancyExtension: (0, _attr.default)('boolean'),
    miPlaceCoverOccupancyExtensionDays: (0, _attr.default)('enum'),

    miPlacePlan: (0, _attr.default)('enum'),
    miPlaceSumInsuredBuilding: (0, _attr.default)('number'),
    miPlaceSumInsuredContents: (0, _attr.default)('number'),

    miPlaceMortgageClause: (0, _attr.default)('enum'), //Bank list
    miPlaceMortgageRefNum: (0, _attr.default)('string') //MISSING from COMTEC fields
  };

  exports.default = _minervaDocument.default.extend(proposalSchema, {
    relatedCovernoteId: Ember.computed('relatedCovernote', function () {
      return this.belongsTo('relatedCovernote').id();
    }),
    helperDateOfBirthFormatted: (0, _format.default)((0, _locale.default)((0, _moment.default)('helperDateOfBirth'), 'moment.locale'), 'DD/MM/YYYY'),
    miPlaceTotalSumInsured: Ember.computed('miPlaceSumInsuredBuilding', 'miPlaceSumInsuredContents', function () {
      if (this.miPlaceSumInsuredBuilding && this.miPlaceSumInsuredContents) {
        return this.miPlaceSumInsuredBuilding + this.miPlaceSumInsuredContents;
      }
      return 0;
    })
  });
});