define('minerva-frontend/services/current-user', ['exports', 'minerva-frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    session: Ember.inject.service('session'),
    store: Ember.inject.service(),
    user: null,
    load: function load() {
      var _this = this;

      if (this.get('session.isAuthenticated')) {
        return this.get('store').queryRecord('user', { me: true }).then(function (user) {
          _this.set('user', user);

          user.set('frontendAppMajorVersion', _environment.default.APP.appVersion);
          user.set('frontendAppMinorVersion', _environment.default.APP.minorAppVersion);
          user.set('lastLoginTimestamp', new Date());
          user.save({ adapterOptions: { me: true } }); //Update tracking info for user
        });
      } else {
        return Ember.RSVP.resolve();
      }
    }
  });
});