define('minerva-frontend/validations/proposals/el-helper', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    salary: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)(true)]

  };
});