define('minerva-frontend/components/generic-forms/motor/vehicle-form/component', ['exports', 'npm:greek-utils', 'minerva-frontend/config/environment', 'moment', 'minerva-frontend/routes/proposals/unfiled'], function (exports, _npmGreekUtils, _environment, _moment, _unfiled) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    dataLocked: false, //Given from calling component
    changeset: null, //Given from calling component


    possibleVehicleMake: '',
    _isTomLoading: false,
    _carDataFound: false,
    _carDataLoaded: false,
    _carDataLoading: false,
    _carDataNotMatchingLob: false,
    _carDataNotMatchingLobMessage: [],
    carDataLocked: undefined,
    carChangeLocked: undefined,

    _carDataLocked: Ember.computed.alias('carDataLocked'),
    _carChangeLocked: Ember.computed('carDataLocked', 'carChangeLocked', function () {
      if (this.get('carChangeLocked') === undefined) {
        return this.get('carDataLocked');
      } else {
        return this.get('carChangeLocked');
      }
    }),

    onTypeOfBodyChange: undefined,

    //isConvertibleVisible: TODO
    //isHighPerformanceVisible: TODO
    //isLeftHandDriveVisible: TODO
    _getFieldsToLock: function _getFieldsToLock() {
      var fieldsToLock = {
        vehicleMake: false,
        vehicleChassisNumber: false,
        vehicleModel: false,
        vehicleEngineCapacity: false,
        vehicleEnginePower: false,
        vehicleNumberOfSeats: false,
        vehicleManufactureYear: false
      };
      return fieldsToLock;
    },
    _lockAllFields: function _lockAllFields() {
      var fieldsToLock = this._getFieldsToLock();
      Object.keys(fieldsToLock).forEach(function (key) {
        fieldsToLock[key] = true;
      });
      this.set('_fieldsToLock', fieldsToLock);
    },
    _lockMissingFields: function _lockMissingFields() {
      var fieldsToLock = this._getFieldsToLock();
      var changeset = this.changeset;
      Object.keys(fieldsToLock).forEach(function (key) {
        if (changeset.get(key)) {
          fieldsToLock[key] = true;
        }
      });
      this.set('_fieldsToLock', fieldsToLock);
    },
    init: function init() {
      this._super.apply(this, arguments);

      var stateInitializers = this.get('stateInitializers'); //Should be passed when initializing the object
      var initState = stateInitializers ? stateInitializers.state : false;
      if (initState) {

        //Means car is already preloaded!
        if (initState === 'COVERNOTE_TO_PROPOSAL') {
          Ember.set(this, '_carDataLoaded', true);
          Ember.set(this, '_carDataFound', true);
          Ember.set(this, '_carDataLoading', false);
          Ember.set(this, 'carDataLocked', true);
          Ember.set(this, 'carChangeLocked', true);
        } else if (initState === 'CONTRACT_TO_ENDORSEMENT') {
          Ember.set(this, '_carDataLoaded', true);
          Ember.set(this, '_carDataLoading', false);
          Ember.set(this, '_carDataFound', true);
          this._lockMissingFields();
        } else if (initState === 'COVERNOTE_FINALIZATION') {
          Ember.set(this, '_carDataLoaded', true);
          Ember.set(this, '_carDataLoading', false);
          Ember.set(this, '_carDataFound', true);
          this._lockMissingFields();
        }
      }
    },
    _fetchVehicleInfoFromTom: function _fetchVehicleInfoFromTom(licensePlate /*, resolve, reject*/) {
      var _this = this;

      var changeset = this.changeset;

      if (!licensePlate) {
        return;
      }

      this.set('_carDataLoading', true);
      var url = _environment.default.APP.apiUrl + "/iac/plate/" + licensePlate;

      var headers = {};
      this.get('session').authorize('authorizer:jwt', function (headerName, headerValue) {
        headers[headerName] = headerValue;
      });
      var notifications = Ember.get(this, 'notifications');
      var fieldsToLock = this._getFieldsToLock();
      this.get('ajax').request(url, {
        method: 'GET',
        headers: headers,
        contentType: "application/x-www-form-urlencoded; charset=UTF-8"
      }).then(function (response) {
        var json = response;
        notifications.success('Car with registration number ' + licensePlate + ' found in TOM');
        var manufactureDate = (0, _moment.default)(json.manufactureDate, "DD-MM-YYYY");
        var leftHandDrive;
        if (json.steeringPosition == "Δεξιά") {
          leftHandDrive = false;
        } else {
          leftHandDrive = true;
        }
        changeset.setProperties({
          tomInfo: response,
          vehicleChassisNumber: json.vin,
          vehicleEngineCapacity: json.engineCapacity,
          vehicleEnginePower: json.enginePower,
          vehicleModel: json.modelDescription,
          vehicleManufactureYear: manufactureDate.year(),
          vehicleNumberOfSeats: json.numberOfSeatedPassengers,
          leftHandDrive: leftHandDrive
        });

        if (json.vin) {
          fieldsToLock.vehicleChassisNumber = true;
        }
        if (json.engineCapacity) {
          fieldsToLock.vehicleEngineCapacity = true;
        }
        if (json.enginePower) {
          fieldsToLock.vehicleEnginePower = true;
        }
        if (json.modelDescription) {
          fieldsToLock.vehicleModel = true;
        }
        if (manufactureDate.year()) {
          fieldsToLock.vehicleManufactureYear = true;
        }
        if (json.numberOfSeatedPassengers) {
          fieldsToLock.vehicleNumberOfSeats = true;
        }
        if (leftHandDrive) {
          fieldsToLock.leftHandDrive = true;
        }

        if (json.minervaMake) {
          changeset.set('vehicleMake', undefined);
          _this.get('store').findRecord('minerva-make', json.minervaMake).then(function (make) {
            changeset.set('vehicleMake', make);
          });
          fieldsToLock.vehicleMake = true;
          //this.set('_tomHasResultsForVehicleMake', true);
        } else {
          //this.set('_tomHasResultsForVehicleMake', false);
          fieldsToLock.vehicleMake = false;
          _this.set('possibleVehicleMake', json.make);
        }

        _this.set('_fieldsToLock', fieldsToLock);
        _this.set('_carDataFound', true);
        _this.set('_tomResults', json);
        //CHECK IF car returned vehicle category is allowed for this lob. if yes, allow to proceed, else not


        var currentLobId = Ember.get(_this, 'selectedLob.id');
        var allowedLobIds = json.allowedLobs;
        if (!allowedLobIds || !allowedLobIds.includes(currentLobId)) {
          _this.set('_carDataNotMatchingLob', true);
          _this.set('_carDataNotMatchingLobMessage', allowedLobIds);
        } else {
          _this.set('_carDataNotMatchingLob', false);
        }
        return json;
      }).catch(function (response) {
        console.log("Vehicle form error", response);
        notifications.warning('Car with registration number ' + licensePlate + ' could not be found in TOM');

        changeset.setProperties({
          tomInfo: undefined,
          vehicleChassisNumber: undefined,
          vehicleEngineCapacity: undefined,
          vehicleEnginePower: undefined,
          vehicleModel: undefined,
          vehicleManufactureYear: undefined,
          vehicleNumberOfSeats: undefined,
          leftHandDrive: undefined,
          vehicleMake: undefined
        });
        //this.set('_tomHasResultsForVehicleMake', false);
        _this.set('_fieldsToLock', {});
        _this.set('_carDataFound', false);
        _this.set('_tomResults', undefined);
        _this.set('_carDataNotMatchingLob', false);
        _this.set('_carDataNotMatchingLobMessage', []);
      }).finally(function () {
        _this.set('_carDataLoaded', true);
        _this.set('_carDataLoading', false);
      });
      //fetch(url, {method: 'GET', headers: headers})
    },

    actions: {
      checkEnter: function checkEnter(followUpEvent, e) {
        if (e.key === 'Enter') {
          this.get('actions.' + followUpEvent).call(this);
        }
      },
      vehicleRegistrationNumberChanged: function vehicleRegistrationNumberChanged(value, changeset) {
        var greeklishVersion = _npmGreekUtils.default.toGreeklish(value);
        changeset.set('vehicleRegistrationNumber', greeklishVersion);
      },
      lookupCar: function lookupCar() {
        var vehicleRegistrationNumber = Ember.get(this, 'changeset.vehicleRegistrationNumber');
        this._fetchVehicleInfoFromTom(vehicleRegistrationNumber.toUpperCase());
      },
      skipCarLookup: function skipCarLookup() {
        var vehicleRegistrationNumber = '000000';
        Ember.set(this.get('changeset'), 'vehicleRegistrationNumber', vehicleRegistrationNumber);
        this._fetchVehicleInfoFromTom(vehicleRegistrationNumber.toUpperCase());
      },
      clearCar: function clearCar() {
        this.set('_carDataLoaded', false);
        this.set('_carDataLoading', false);
        this.set('_carDataFound', false);
        this.set('_carDataNotMatchingLob', false);
        this.set('_carDataNotMatchingLobMessage', []);

        this.changeset.setProperties({
          vehicleRegistrationNumberChanged: undefined,
          tomInfo: undefined,
          vehicleChassisNumber: undefined,
          vehicleEngineCapacity: undefined,
          vehicleEnginePower: undefined,
          vehicleModel: undefined,
          vehicleManufactureYear: undefined,
          vehicleNumberOfSeats: undefined,
          leftHandDrive: undefined,
          vehicleMake: undefined
        });
        this.set('_fieldsToLock', {});
      },
      typeOfBodyChanged: function typeOfBodyChanged(value, changeset) {
        changeset.set('typeOfBody', value);
        if (this.get('onTypeOfBodyChange')) {
          this.get('onTypeOfBodyChange')(value);
        }
      }
    }
  });
});