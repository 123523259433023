define('minerva-frontend/validations/proposals/mi-health', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    annualExcess: [(0, _validators.validatePresence)(true)],
    coinsurancePercentage: [(0, _validators.validatePresence)(true)],
    insuredPersons: [(0, _validators.validateLength)({ min: 1, max: 6, allowBlank: false }), (0, _validators.validatePresence)(true)]

  };
});