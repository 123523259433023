define('minerva-frontend/components/combos/country-select/component', ['exports', 'minerva-frontend/components/combos/generic-model-select/component', 'minerva-frontend/components/combos/generic-model-select/template'], function (exports, _component, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    layout: _template.default,
    inputPlaceholder: "Search for nationality",
    modelClass: 'country'
  });
});