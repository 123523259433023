define('minerva-frontend/components/lob-forms/private-bus-proposal/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    filteredCoverTypes: Ember.computed.filterBy('params.cover-types', 'id', 'THIRD_PARTY'),
    filteredBodyTypes: Ember.computed.filterBy('params.body-types', 'lob', 'PRIVATE_BUS'),
    filteredDriverTypes: Ember.computed.filter('params.driver-types', function (driverType, index, array) {
      var user = this.get('currentUser.user');
      var isUnderwriter = user.get("isHeadUnderwriter") || user.get("isUnderwriter");
      if (!isUnderwriter && driverType.id === 'ANY_DRIVER') {
        return false; //Block ANY DRIVER for non admins
      }
      //We hard code these to the ones with applicable cover type THIRD_PARTY,because no other cover type can be selected for this lob
      var found = false;
      Ember.get(driverType, 'applicableCoverTypes').forEach(function (covertype) {
        if (covertype.id == 'THIRD_PARTY') {
          found = true;
        }
      });
      return found;
    }),
    isClientCompany: Ember.computed.equal('clientIdType', 'COMPANY_REGISTRATION'),
    //filteredVehicleCategories: filterBy('params.vehicle-categories', 'lob', 'MOTORCYCLE'),
    //filteredPackages: filterBy('params.packages', 'lob', 'MOTORCYCLE'),
    //coverFilteredPackages: undefined,
    _hasDriversInEditMode: undefined,
    init: function init() {
      this._super.apply(this, arguments);
      if (!Ember.get(this, '_changeset.coverType')) {
        //If not coming from a prepopulated document, that has a covertype,
        //and covertype is empty, populate it with a default
        var thirdParty = this.get('params.cover-types').findBy('id', 'THIRD_PARTY');
        Ember.set(this, '_changeset.coverType', thirdParty);
      }

      var stateInitializers = this.get('stateInitializers'); //Should be passed when initializing the object
      var initState = stateInitializers ? stateInitializers.state : false;
      if (initState) {
        //Means car is already preloaded!
        if (initState === 'COVERNOTE_TO_PROPOSAL') {
          Ember.set(this, '_driversLocked', true);
        } else if (initState === 'CONTRACT_TO_ENDORSEMENT') {
          Ember.set(this, '_coverInfoLocked', true);
          Ember.set(this, '_driverTypeLocked', true);
        }
      }
    },

    actions: {
      driversTypeChanged: function driversTypeChanged(value, changeset) {
        changeset.set('driversType', value);
        changeset.validate();
      },
      emptySubmit: function emptySubmit() {},
      submit: function submit(model, submissionStatus) {
        this.onSubmit(model, submissionStatus); //Let it propagate to motor lob-form-wrapper to handle instead. no custom logic here
      }
    }
  });
});