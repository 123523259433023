define('minerva-frontend/transforms/date-string-serializable', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      if (serialized) {
        return (0, _moment.default)(serialized, 'YYYY-MM-DD').toDate();
      } else {
        return null;
      }
    },
    serialize: function serialize(deserialized) {
      if (deserialized) {
        return (0, _moment.default)(deserialized).format('YYYY-MM-DD');
      } else {
        return null;
      }
    }
  });
});