define('minerva-frontend/models/street', ['exports', 'ember-data', 'ember-custom-actions'], function (exports, _emberData, _emberCustomActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    findByDisplayName: (0, _emberCustomActions.resourceAction)('search/findByPostCodeOrderByStreetName', { type: 'GET' }),
    cityCode: _emberData.default.attr('number'),
    district: _emberData.default.attr('number'),
    postCode: _emberData.default.attr('string'),
    districtName: _emberData.default.attr('string'),
    areaName: _emberData.default.attr('string'),
    streetName: _emberData.default.attr('string')
  });
});