define('minerva-frontend/validations/user-password', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    password: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ min: 6, allowBlank: false })],
    passwordConfirmation: (0, _validators.validateConfirmation)({ on: 'password' })
  };
});