define('minerva-frontend/routes/covernote/new', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    currentUser: Ember.inject.service(),
    queryParams: {
      lob: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel() {
      if (!this.get('currentUser.user.canIssueCovernote')) {
        return this.transitionTo('index');
      }
    },
    model: function model(params) {
      if (this.get('currentUser.user.migratedToNewVersion')) {
        console.log("User migrated to new versoin. Loading new covernote forms...");
        if (params.lob) {
          return Ember.RSVP.hash({
            lineOfBusinessId: params.lob,
            covernote: this.get('store').createRecord('covernote', {})
          });
        } else {
          return Ember.RSVP.hash({
            lineOfBusinessId: null,
            covernote: this.get('store').createRecord('covernote', {})
          });
        }
      } else {
        return this.get('store').createRecord('covernote', {});
      }
    },

    actions: {
      willTransition: function willTransition() {
        if (this.get('currentUser.user.migratedToNewVersion')) {
          var model = this.controller.get('model.covernote');
          if (model && model.get('isNew')) {
            model.destroyRecord();
          }
        } else {
          var _model = this.controller.get('model');
          if (_model && _model.get('isNew')) {
            _model.destroyRecord();
          }
        }
      }
    }

  });
});