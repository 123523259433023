define('minerva-frontend/components/generic-forms/mi-accident/insured-person-single-form/component', ['exports', 'moment', 'ember-changeset-validations', 'ember-changeset', 'minerva-frontend/validations/miaccident-insured-person'], function (exports, _moment, _emberChangesetValidations, _emberChangeset, _miaccidentInsuredPerson) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var InsuredPerson = Ember.Object.extend({
    insuredId: '',
    idType: '',
    insuredName: '',
    dateOfBirth: '',
    nationality: '',
    address: '',
    village: '',
    district: '',
    postCode: '',
    landlineTelNumber: '',
    mobileTelNumber: '',
    workTelNumber: ''
  });

  exports.default = Ember.Component.extend({
    MiaccidentInsuredPersonValidations: _miaccidentInsuredPerson.default,
    store: Ember.inject.service(),
    model: null,
    value: undefined,
    params: undefined,
    hasInsuredPersonsInEditMode: false,
    filteredIdTypes: Ember.computed.filter('params.id-types', function (idType, index, array) {
      return idType.id !== 'COMPANY_REGISTRATION';
    }),
    districts: Ember.computed.alias('params.districts'),
    _saved: undefined,
    init: function init() {
      this._super.apply(this, arguments);
      var existingOtherInsuredPerson = Ember.get(this, 'value');
      if (!existingOtherInsuredPerson || !existingOtherInsuredPerson.length) {
        //set(this, 'model', null);
        //set(this,'_insuredPerson', null);
        this._addNewInsuredPerson();
        this.set('hasInsuredPersonsInEditMode', true);
      } else {
        this._addNewInsuredPerson(existingData);
        this.set('hasInsuredPersonsInEditMode', false);
      }
    },
    _getInsuredPerson: function _getInsuredPerson() {
      var personChangeset = Ember.get(this, 'model');

      if (personChangeset.get('isValid')) {
        return {
          insuredId: Ember.get(personChangeset, 'insuredId'),
          insuredName: Ember.get(personChangeset, 'insuredName'),
          dateOfBirth: Ember.get(personChangeset, 'dateOfBirth') ? (0, _moment.default)(Ember.get(personChangeset, 'dateOfBirth')).format('YYYY-MM-DD') : null,
          idType: Ember.get(personChangeset, 'insuredType'),
          nationality: Ember.get(personChangeset, 'nationality.id'),
          address: Ember.get(personChangeset, 'address'),
          village: Ember.get(personChangeset, 'village'),
          district: Ember.get(personChangeset, 'district.id'),
          postCode: parseInt(Ember.get(personChangeset, 'postCode')),
          landlineTelNumber: Ember.get(personChangeset, 'landlineTelNumber'),
          mobileTelNumber: Ember.get(personChangeset, 'mobileTelNumber'),
          workTelNumber: Ember.get(personChangeset, 'workTelNumber'),
          weight: Ember.get(personChangeset, 'weight'),
          height: Ember.get(personChangeset, 'height')
        };
      }
    },
    _addNewInsuredPerson: function _addNewInsuredPerson(existingData) {
      var insuredPerson;
      if (existingData) {
        insuredPerson = InsuredPerson.create(existingOtherInsuredPerson);
        if (existingOtherInsuredPerson.dateOfBirth) {
          insuredPerson.set('dateOfBirth', new _moment.default(existingOtherInsuredPerson.dateOfBirth, 'YYYY/MM/DD').toDate());

          this.get('store').findRecord('country', Ember.get(existingOtherInsuredPerson, 'nationality')).then(function (country) {
            Ember.set(_insuredPerson, 'nationality', country);
          });
        }
      } else {
        insuredPerson = InsuredPerson.create();
      }

      this.set('_insuredPerson', insuredPerson);
      var validationClass = _miaccidentInsuredPerson.default;
      var personChangeset = new _emberChangeset.default(insuredPerson, (0, _emberChangesetValidations.default)(validationClass), validationClass);
      this.set('model', personChangeset);
    },

    actions: {
      remove: function remove() {
        // Need to delete the current person
        this.set('model', null);
        this.get('onchange')(null); //Call on change to update otherInsuredPerson on the main model
      },

      /*
      addOtherInsured(){
          this._addNewInsuredPerson();
          this.set('hasInsuredPersonsInEditMode', true);
      },
      */
      saveData: function saveData(changeset) {
        var _this = this;

        //Clean it up in case it was set but then got hidden
        /*
        if (!this.get('showChildOccupations')){
          changeset.set('childOccupation', null);
        }
        */
        changeset.validate().then(function () {
          if (changeset.get('isValid')) {
            _this.set('_saved', true);
            _this.set('hasInsuredPersonsInEditMode', false);
            _this.get('onchange')(_this._getInsuredPerson());
          }
          console.log(changeset.get('errors'));
        });
        //return false;
      },
      editData: function editData() {
        this.set('_saved', false);
        this.set('hasInsuredPersonsInEditMode', true);
      },
      validate: function validate(changeset) {
        //console.log('validating driver form');
        changeset.validate();
        return false;
      },
      landlineTelNumberChanged: function landlineTelNumberChanged(value, changeset) {
        changeset.set('landlineTelNumber', value);
        changeset.validate();
      },
      mobileTelNumberChanged: function mobileTelNumberChanged(value, changeset) {
        changeset.set('mobileTelNumber', value);
        changeset.validate();
      },
      workTelNumberChanged: function workTelNumberChanged(value, changeset) {
        changeset.set('workTelNumber', value);
        changeset.validate();
      }
    }
  });
});