define('minerva-frontend/validations/covernote-transform-to-endorsement', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    contractReference: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ min: 3 })],
    confirmation: [(0, _validators.validateInclusion)({
      list: [true],
      message: 'Please confirm prior submitting!'
    })]

  };
});