define('minerva-frontend/components/helpers/date-time-mask-input/component', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    inputClassNames: '',
    errorClassNames: '',

    labelDate: "Date",
    labelTime: "Time",
    hasTime: false,
    timeDisabled: false,
    value: undefined,
    defaultTime: "",
    calcedTime: Ember.computed('value', function () {
      var value = this.get('value');
      if (!value) {
        return "";
      }
      var datetimeFormatted = (0, _moment.default)(value).format("HH:mm");
      return datetimeFormatted;
    }),
    minValue: undefined,
    maxValue: undefined,

    onChange: undefined,
    _maskType: Ember.computed('timeDisabled', 'hasTime', function () {
      if (this.get('hasTime') && this.get('timeDisabled')) {
        return 'date';
      } else if (this.get('hasTime')) {
        return 'datetime';
      } else {
        return 'date';
      }
    }),
    _valueFormatted: Ember.computed('value', function () {

      //value is assigned from outer element, and is a JS Date. From that,
      //we should output the formatted output to use in the element
      var value = this.get('value');
      if (!value) {
        return "";
      }
      var datetimeFormatted = (0, _moment.default)(value).format(this.get('momentFormat'));
      return datetimeFormatted;
    }),
    _valueUnformatted: "",
    momentFormat: Ember.computed('hasTime', 'timeDisabled', function () {
      if (this.get('hasTime')) {
        return "DD/MM/YYYY HH:mm";
      } else {
        return "DD/MM/YYYY";
      }
    }),
    valueParseFormat: Ember.computed('hasTime', 'timeDisabled', function () {
      if (this.get('hasTime') && !this.get('timeDisabled')) {
        return "DD/MM/YYYY HH:mm";
      } else {
        return "DD/MM/YYYY";
      }
    }),
    _clearError: function _clearError() {
      this.set('error', '');
    },

    /*
    _dateOrTimeChanged: observer('minValue', 'maxValue', '_valueFormatted', '_valueUnformatted', function () {
      var value = this.get('_valueFormatted');
      if(!value){
        return;
      }
      var format = this.get('momentFormat');
      if (this.get('hasTime') && this.get('timeDisabled')) {
        value = value + " " + this.get('defaultTime');
      }
       if (moment(value, format, true).isValid()) {
        var datetimeMoment = moment(value, format);
        var datetime = datetimeMoment.toDate();
         if (this.get('minValue')) {
          var minValue = moment(this.get('minValue'));
          if (datetimeMoment.isBefore(minValue, 'minute')) {
            this.set('error', 'Value cannot be before ' + minValue.format(format));
            this.get('onChange')(undefined);
            //debounce(_clearError, this, 500);
            return;
          }
        }
        if (this.get('maxValue')) {
          var maxValue = moment(this.get('maxValue'));
          if (datetimeMoment.isAfter(maxValue, 'minute')) {
            this.set('error', 'Value cannot be after ' + maxValue.format(format));
            this.get('onChange')(undefined);
            //debounce(_clearError, this, 500);
            return;
          }
        }
        this.set('error', '');
        if (this.get('onChange')) {
          this.get('onChange')(datetime);
        }
      } else {
        this.get('onChange')(undefined);
      }
    }),
    */
    /*
    init() {
      this._super(...arguments);
      if (this.get('value')) {
        var datetimeFormatted = moment(this.get('value')).format(this.get('momentFormat'));
        this.set('_valueFormatted', datetimeFormatted);
      }
    },
    */
    /*
    _valueChanged: observer('value', function () {
      var value = this.get('value');
      var format = this.get('valueParseFormat');
      if (value && moment(value, format, true).isValid()) {
        var datetimeFormatted = moment(value).format(format);
        scheduleOnce('sync', this, function () {
          this.set('_valueFormatted', datetimeFormatted);
        });
       }
     }),
    */
    actions: {
      dateOrTimeChanged: function dateOrTimeChanged(valueUnformatted, valueFormatted) {
        //var value = this.get('_valueFormatted');
        var value = valueFormatted;
        if (!value) {
          return;
        }
        var format = this.get('momentFormat');
        if (this.get('hasTime') && this.get('timeDisabled') && this.get('defaultTime')) {
          value = value + " " + this.get('defaultTime');
        }

        if ((0, _moment.default)(value, format, true).isValid()) {
          var datetimeMoment = (0, _moment.default)(value, format);
          var datetime = datetimeMoment.toDate();

          if (this.get('minValue')) {
            var minValue = (0, _moment.default)(this.get('minValue'));
            if (datetimeMoment.isBefore(minValue, 'minute')) {
              this.set('error', 'Value cannot be before ' + minValue.format(format));
              if (this.get('onChange')) {
                this.get('onChange')(undefined);
              }
              //debounce(_clearError, this, 500);
              return;
            }
          }
          if (this.get('maxValue')) {
            var maxValue = (0, _moment.default)(this.get('maxValue'));
            if (datetimeMoment.isAfter(maxValue, 'minute')) {
              this.set('error', 'Value cannot be after ' + maxValue.format(format));
              if (this.get('onChange')) {
                this.get('onChange')(undefined);
              }
              //debounce(_clearError, this, 500);
              return;
            }
          }
          this.set('error', '');
          if (this.get('onChange')) {
            this.get('onChange')(datetime);
          }
        } else {
          if (this.get('onChange')) {
            this.get('onChange')(undefined);
          }
        }
      }
    }
  });
});