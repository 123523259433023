define('minerva-frontend/validations/user-profile-with-password', ['exports', 'minerva-frontend/validations/user-profile', 'ember-changeset-validations/validators'], function (exports, _userProfile, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.UserProfileWithPasswordValidations = undefined;
  var assign = Ember.assign;
  var UserProfileWithPasswordValidations = exports.UserProfileWithPasswordValidations = {
    password: (0, _validators.validatePresence)(false)
  };

  exports.default = assign({}, _userProfile.default, UserProfileWithPasswordValidations);
});