define('minerva-frontend/validations/covernote', ['exports', 'minerva-frontend/validations/document', 'minerva-frontend/validators/generic/date-before', 'minerva-frontend/validators/generic/date-after', 'ember-changeset-conditional-validations/validators/sometimes', 'ember-changeset-validations/validators'], function (exports, _document, _dateBefore, _dateAfter, _sometimes, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.CovernoteValidations = undefined;
  var assign = Ember.assign;
  var CovernoteValidations = exports.CovernoteValidations = {
    //Only if lob 11 for presence...must make sure about the presence
    policyExcess: (0, _validators.validateNumber)({ positive: true, integer: true, allowBlank: true }),
    startDateTime: (0, _sometimes.default)([(0, _validators.validatePresence)(true), (0, _dateAfter.default)({
      minutes: -30,
      message: '{description} cannot start earlier than now'
    })], function (changes, content) {
      return Ember.get(changes, 'customStartEndDate') == true;
    }),
    documentType: [(0, _validators.validatePresence)(true)],

    endDateTime: (0, _sometimes.default)([(0, _validators.validatePresence)(true), (0, _dateBefore.default)({
      field: 'startDateTime',
      months: -4,
      hours: -23,
      minutes: -59,
      message: '{description} cannot be more than 4 months after start date'
    }), (0, _dateAfter.default)({
      field: 'startDateTime',
      months: 1,
      message: '{description} must be at least 1 month after start date'
    })], function (changes, content) {
      return Ember.get(changes, 'customStartEndDate') == true;
    })

  };

  exports.default = assign({}, _document.default, CovernoteValidations);
});