define('minerva-frontend/components/generic-forms/mi-health/insured-person-single-form/component', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    model: null,
    isOwner: null,
    insuredIdDisabled: Ember.computed.alias('isOwner'),
    insuredNameDisabled: Ember.computed.alias('isOwner'),
    dateOfBirthDisabled: Ember.computed.alias('isOwner'),
    filteredRelationshipTypes: Ember.computed.filter('params.mihealth-relationship', function (relationshipType, index, array) {
      return relationshipType.id !== 'SELF';
    }),

    childOccupations: Ember.computed.alias('params.mihealth-child-occupation'),
    _saved: undefined,
    init: function init() {
      this._super.apply(this, arguments);
      if (this.get('model.isEditing')) {
        this.set('_saved', false);
      } else {
        this.set('_saved', true);
      }
    },

    childAbove18: Ember.computed('model.dateOfBirth', function () {
      var dateOfBirthStr = Ember.get(this, 'model.dateOfBirth');
      var dateOfBirth = (0, _moment.default)(dateOfBirthStr);
      if (dateOfBirth.isValid()) {
        if ((0, _moment.default)().diff(dateOfBirth, 'years', true) < 18) {
          return false;
        }
      }
      return true;
    }),
    relationIsChild: Ember.computed.equal('model.relationToPolicyHolder', "CHILD"),
    showChildOccupations: Ember.computed.and('childAbove18', 'relationIsChild'),
    actions: {
      remove: function remove(index) {
        this.get('onRemove')(index);
      },
      saveData: function saveData(changeset) {
        var _this = this;

        //Clean it up in case it was set but then got hidden
        if (!this.get('showChildOccupations')) {
          changeset.set('childOccupation', null);
        }
        changeset.validate().then(function () {
          if (changeset.get('isValid')) {
            _this.set('_saved', true);
            _this.set('model.isEditing', false);
            _this.get('onchange')();
          }
        });
        //return false;
      },
      editData: function editData() {
        this.set('_saved', false);
        this.set('model.isEditing', true);
      },
      validate: function validate(changeset) {
        //console.log('validating driver form');
        changeset.validate();
        return false;
      }
    }
  });
});