define('minerva-frontend/validations/proposals/mi-place', ['exports', 'ember-changeset-validations/validators', 'ember-changeset-conditional-validations/validators/sometimes'], function (exports, _validators, _sometimes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    miPlacePlan: [(0, _validators.validatePresence)(true)],

    miPlacePremisesConstructionYear: [(0, _validators.validateNumber)({ integer: true, gte: 1985, allowBlank: false })],
    miPlacePremisesRenovationYear: [(0, _validators.validateNumber)({ integer: true, allowBlank: true })],
    miPlacePremisesDependency: [(0, _validators.validatePresence)(true)],

    miPlacePremisesWallsType: [(0, _validators.validatePresence)(true)],
    miPlacePremisesCeilingType: [(0, _validators.validatePresence)(true)],
    miPlacePremisesFrameType: [(0, _validators.validatePresence)(true)],

    miPlaceSumInsuredBuilding: [(0, _validators.validateNumber)({ integer: true, gte: 10000, lte: 500000, allowBlank: false })],
    miPlaceSumInsuredContents: [(0, _validators.validateNumber)({ integer: true, lte: 100000, allowBlank: true })],

    miPlacePremisesStreet: (0, _sometimes.default)([(0, _validators.validatePresence)(true)], function (changes, content) {
      return !(Ember.get(changes, 'addressSameAsClients') === true || Ember.get(content, 'addressSameAsClients') === true);
    }),
    miPlacePremisesPostCode: (0, _sometimes.default)([(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ integer: true })], function (changes, content) {
      return !(Ember.get(changes, 'addressSameAsClients') === true || Ember.get(content, 'addressSameAsClients') === true);
    }),
    miPlacePremisesHomeStreetNumber: (0, _sometimes.default)([(0, _validators.validatePresence)(true)], function (changes, content) {

      return !(Ember.get(changes, 'addressSameAsClients') === true || Ember.get(content, 'addressSameAsClients') === true);
    })

  };
});