define('minerva-frontend/routes/covernotes/pending', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model() {
      //return A([]);
      return this.get('store').query('covernote', { page: 1, size: 10, state: 'PENDING_APPROVAL' });
    }
  });
});