define('minerva-frontend/validations/document', ['exports', 'ember-changeset-validations/validators', 'minerva-frontend/validators/generic/date-before', 'minerva-frontend/validators/special/driver-license-date', 'ember-changeset-conditional-validations/validators/sometimes'], function (exports, _validators, _dateBefore, _driverLicenseDate, _sometimes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = {
    clientIdType: (0, _validators.validatePresence)(true),
    //clientId: [validatePresence(true),validateNumber({integer:true})],
    clientId: [(0, _validators.validatePresence)(true)].concat(_toConsumableArray((0, _sometimes.default)([(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ integer: true })], function (changes, content) {
      return Ember.get(changes, 'clientIdType.id') === 'ID_CARD' || Ember.get(content, 'clientIdType.id') === 'ID_CARD';
    }))),
    firstName: (0, _sometimes.default)([(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ min: 2, max: 20 })
    //Add validate format for chassis number
    ], function (changes, content) {
      return !(Ember.get(changes, 'clientIdType.id') === 'COMPANY_REGISTRATION' || Ember.get(content, 'clientIdType.id') === 'COMPANY_REGISTRATION');
    }),
    lastName: (0, _sometimes.default)([(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ min: 2, max: 20 })
    //Add validate format for chassis number
    ], function (changes, content) {
      return !(Ember.get(changes, 'clientIdType.id') === 'COMPANY_REGISTRATION' || Ember.get(content, 'clientIdType.id') === 'COMPANY_REGISTRATION');
    }),
    companyName: (0, _sometimes.default)([(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ min: 1, max: 40 })], function (changes, content) {
      return Ember.get(changes, 'clientIdType.id') === 'COMPANY_REGISTRATION' || Ember.get(content, 'clientIdType.id') === 'COMPANY_REGISTRATION';
    }),
    email: (0, _validators.validateFormat)({ type: 'email', allowBlank: true }),
    dateOfBirth: (0, _sometimes.default)([(0, _validators.validatePresence)(true), (0, _dateBefore.default)({
      years: 18,
      message: '{description} must be greater than 18 years old'
    })
    //Add validate format for chassis number
    ], function (changes, content) {
      return !(Ember.get(changes, 'clientIdType.id') === 'COMPANY_REGISTRATION' || Ember.get(content, 'clientIdType.id') === 'COMPANY_REGISTRATION');
    }),
    nationality: (0, _sometimes.default)([(0, _validators.validatePresence)(true)
    //Add validate format for chassis number
    ], function (changes, content) {
      return !(Ember.get(changes, 'clientIdType.id') === 'COMPANY_REGISTRATION' || Ember.get(content, 'clientIdType.id') === 'COMPANY_REGISTRATION');
    }),
    occupation: (0, _validators.validatePresence)(true),

    postCode: (0, _validators.validatePresence)(true),
    homeStreetNumber: (0, _validators.validatePresence)(true),

    landlineTelNumber: (0, _sometimes.default)([(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ positive: true, integer: true })], function (changes, content) {
      return !(Ember.get(changes, 'mobileTelNumber') || Ember.get(content, 'mobileTelNumber'));
    }),
    mobileTelNumber: (0, _sometimes.default)([(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ positive: true, integer: true })], function (changes, content) {
      return !(Ember.get(changes, 'landlineTelNumber') || Ember.get(content, 'landlineTelNumber'));
    }),
    faxNumber: (0, _validators.validateNumber)({ positive: true, integer: true, allowBlank: true }),

    lineOfBusiness: (0, _validators.validatePresence)(true),
    coverType: (0, _validators.validatePresence)(true),
    sumInsured: (0, _sometimes.default)([(0, _validators.validateNumber)({ positive: true, integer: true, allowBlank: false })], function (changes, content) {
      var coverTypeId = Ember.get(changes, 'coverType.id');
      if (!coverTypeId) {
        coverTypeId = Ember.get(content, 'coverType.id');
      }
      //return ((coverTypeId === 'COMPREHENSIVE' || coverTypeId === 'FIRE_AND_THEFT') && (get(changes, 'lineOfBusiness.id') === 'PRIVATE' || get(content, 'lineOfBusiness.id') === 'PRIVATE'));
      return coverTypeId === 'COMPREHENSIVE' && (Ember.get(changes, 'lineOfBusiness.id') === 'PRIVATE' || Ember.get(content, 'lineOfBusiness.id') === 'PRIVATE');
    }),
    vehicleRegistrationNumber: (0, _sometimes.default)([(0, _validators.validatePresence)(true)], function (changes, content) {
      return !(Ember.get(changes, 'vehicleChassisNumber') || Ember.get(content, 'vehicleChassisNumber'));
    }),
    vehicleChassisNumber: (0, _sometimes.default)([(0, _validators.validatePresence)(true)], function (changes, content) {
      return !(Ember.get(changes, 'vehicleRegistrationNumber') || Ember.get(content, 'vehicleRegistrationNumber'));
    }),
    //vehicleRegistrationNumber - Required either this or the chassis number
    //vehicleChassisNumber

    typeOfBody: (0, _validators.validatePresence)(true),
    vehicleMake: (0, _validators.validatePresence)(true),
    vehicleModel: (0, _validators.validatePresence)(true),
    vehicleEngineCapacity: (0, _validators.validateNumber)({ positive: true, integer: true, allowBlank: false }),
    vehicleNumberOfSeats: (0, _validators.validateNumber)({ positive: true, integer: true, allowBlank: false }),
    //trailerNumber : presence if trailer is true

    driversType: (0, _validators.validatePresence)(true),
    drivers: (0, _sometimes.default)([(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ min: 1, max: 6 })
    //Add validate format for chassis number
    ], function (changes, content) {
      if (!(Ember.get(changes, 'driversType') || Ember.get(content, 'driversType'))) {
        return false;
      } else if (Ember.get(changes, 'driversType.id') === 'ANY_DRIVER' || Ember.get(content, 'driversType.id') === 'ANY_DRIVER' || Ember.get(changes, 'driversType.id') === 'ANY_DRIVER_WITH_EXCLUSION' || Ember.get(content, 'driversType.id') === 'ANY_DRIVER_WITH_EXCLUSION') {
        return false;
      } else {
        return true;
      }
    }),
    //Fields not included in the covernote model, but needed in the form
    //Will be removed prior saving the changeset to the model
    ownerDrivingLicenseDate: (0, _sometimes.default)([(0, _validators.validatePresence)(true), (0, _driverLicenseDate.default)({
      birthdayField: 'dateOfBirth',
      learnerField: 'ownerIsLearner'
    })
    //Add validate format for chassis number
    ], function (changes, content) {
      return Ember.get(changes, 'ownerDriving') || Ember.get(content, 'ownerDriving');
    }),
    ownerDrivingPolicyExcess: (0, _sometimes.default)([(0, _validators.validateNumber)({ positive: true, integer: true, allowBlank: true })], function (changes, content) {
      return Ember.get(changes, 'ownerDriving') || Ember.get(content, 'ownerDriving');
    })
  };
});