define('minerva-frontend/routes/covernotes/cancellation-requests', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return this.get('store').query('covernote-cancellation-request', { page: 1, size: 10, status: 'PENDING' });
    }
  });
});