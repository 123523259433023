define('minerva-frontend/validators/special/min-policy-excess', ['exports', 'ember-validators', 'ember-changeset-validations/utils/validation-errors'], function (exports, _emberValidators, _validationErrors) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = validateMinPolicyExcess;
  function validateMinPolicyExcess() /* options = {} */{
    return function (key, newValue, oldValue, changes, content) {

      //Excess Calculation
      var excess = 240;
      var sumInsured = Ember.get(changes, 'sumInsured');

      if (!sumInsured) {
        sumInsured = 0;
      }
      if (sumInsured / 100 < 240) {
        excess = 240;
      } else {
        excess = sumInsured / 100;
      }
      excess = Math.round(excess);
      //
      var options = {
        gte: excess,
        allowString: true,
        allowBlank: true,
        allowNone: true
      };
      var result = (0, _emberValidators.validate)('number', newValue, options, null, key);
      return result === true ? true : (0, _validationErrors.default)(key, result, newValue, options);
    };
  }
});