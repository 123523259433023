define('minerva-frontend/components/lob-forms/m-visitor-proposal/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    isClientCompany: Ember.computed.equal('clientIdType', 'COMPANY_REGISTRATION'),
    isClientIndividual: Ember.computed.not('isClientCompany'),
    //isVisitor: true, // equal('_changeset.helperOccupation.id', 'VISITOR'),
    genders: Ember.computed.alias('params.genders'),
    medicalCoverTypes: Ember.computed.filterBy('params.medical-cover-types', 'id', 'VISITOR'),
    selectedInsured: null, //owner/visitor
    otherInsuredOptions: undefined,
    isVisitorInsured: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('otherInsuredOptions', Ember.A(["Owner", "Visitor"]));
      var isCompany = Ember.get(this, 'isClientCompany');
      if (isCompany) {
        this.set("selectedInsured", this.get('otherInsuredOptions')[1]);
        this.set("isVisitorInsured", true);
      } else {
        this.set("selectedInsured", this.get('otherInsuredOptions')[0]);
        this.set("isVisitorInsured", false);
      }

      this.set('_changeset.isClientIndividual', this.get('isClientIndividual')); // Set so the MiAccidentValidations can use it to decide if certain validations should happen. the isCLientCompany is not an actual value passed to the model
      this.set('_changeset.isClientCompany', this.get('isClientCompany'));
      this.set('_changeset.isVisitorInsured', this.get('isVisitorInsured'));
    },

    actions: {
      otherInsuredChanged: function otherInsuredChanged(value) {
        if (value === 'Owner') {
          this.set("isVisitorInsured", false);
          this.set('_changeset.isVisitorInsured', false);
        } else {
          this.set("isVisitorInsured", true);
          this.set('_changeset.isVisitorInsured', true);
        }
        this.set('selectedInsured', value);
        //changeset.set('driversType', value);
        //changeset.validate();
      },
      emptySubmit: function emptySubmit() {},
      submit: function submit(model, submissionStatus) {
        //Here we should handle Lob specific logic. When done, pass the stuff on to the form-wrapper to submit the info!
        this.onSubmit(model, submissionStatus);
      }
    }
  });
});