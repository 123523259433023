define('minerva-frontend/routes/application', ['exports', 'minerva-frontend/mixins/loading-slider', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _loadingSlider, _applicationRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_applicationRouteMixin.default, _loadingSlider.default, {
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    sessionAuthenticated: function sessionAuthenticated() {
      this._super.apply(this, arguments);
      this._loadCurrentUser();
    },

    moment: Ember.inject.service(),
    beforeModel: function beforeModel() {
      this.get('moment').setLocale('el');
      return this._loadCurrentUser();
    },
    _loadCurrentUser: function _loadCurrentUser() {
      var _this = this;

      return this.get('currentUser').load().catch(function () {
        return _this.get('session').invalidate();
      });
    },

    actions: {
      goback: function goback() {
        history.back();
      }
    }
  });
});