define('minerva-frontend/models/covernote', ['exports', 'ember-data', 'minerva-frontend/models/minerva-document', 'minerva-frontend/config/environment', 'ember-custom-actions'], function (exports, _emberData, _minervaDocument, _environment, _emberCustomActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.covernoteSchema = undefined;
  var covernoteSchema = exports.covernoteSchema = {
    transitionedToProposal: _emberData.default.attr('boolean', { defaultValue: false }), //server set
    relatedProposalId: _emberData.default.attr('string'),
    //Missing PROPOSAL ID that it has transitioned to!
    pendingCancellation: _emberData.default.attr('boolean', { defaultValue: false }), //server set
    cancellationRequest: _emberData.default.belongsTo('covernote-cancellation-request'), //DS.attr() //server set?


    existingContractReference: _emberData.default.attr('string'),

    documentType: _emberData.default.attr('string', { defaultValue: 'COVERNOTE' }),
    endorsementNumber: _emberData.default.attr('string'),
    handleComments: _emberData.default.attr('string'),
    canUserApprove: _emberData.default.attr('boolean')
  };

  exports.default = _minervaDocument.default.extend(covernoteSchema, {
    fetchCovernotesWithLateProposals: (0, _emberCustomActions.resourceAction)('withLateProposals', { type: 'GET' }),
    fetchCovernotesWithoutDocuments: (0, _emberCustomActions.resourceAction)('withoutDocuments', { type: 'GET' }),
    transformToEndorsement: (0, _emberCustomActions.modelAction)('transformToEndorsement', { type: 'POST' }),

    isEndorsement: Ember.computed('documentType', function () {
      return this.get('documentType') == 'STANDARD_ENDORSEMENT' || this.get('documentType') == 'DEFINITE_ENDORSEMENT' || this.get('documentType') == 'DEFINITE_COVERNOTE';
    }),
    notIsEndorsement: Ember.computed.not('isEndorsement')
  });
});