define('minerva-frontend/components/covernotes/admin-actions/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'admin-actions',
    currentUser: Ember.inject.service(),
    isShowingApproveDeclineModal: false,
    isShowingApproveDeclineCancellationModal: false,
    approvalDeclineMode: '',
    approvalDeclineCancellationMode: '',
    model: undefined,
    notTransitionedToProposal: Ember.computed.not('model.transitionedToProposal'),
    hasAdminActions: Ember.computed.or('model.isPendingApproval', 'model.pendingCancellation', 'notTransitionedToProposal', 'model.notIsEndorsement'),
    actions: {
      closeApproveDeclineModal: function closeApproveDeclineModal() {
        this.set('isShowingApproveDeclineModal', false);
      },
      openApproveDeclineModal: function openApproveDeclineModal(mode) {
        this.set('approvalDeclineMode', mode);
        this.set('isShowingApproveDeclineModal', true);
      },
      closeApproveDeclineCancellationModal: function closeApproveDeclineCancellationModal() {
        this.set('isShowingApproveDeclineCancellationModal', false);
      },
      openApproveDeclineCancellationModal: function openApproveDeclineCancellationModal(mode) {
        this.set('approvalDeclineCancellationMode', mode);
        this.set('isShowingApproveDeclineCancellationModal', true);
      },
      formSubmitted: function formSubmitted() {
        this.set('isShowingApproveDeclineModal', false);
        this.set('isShowingApproveDeclineCancellationModal', false);
        this.get('model').reload();
      },
      transformToDefiniteCovernote: function transformToDefiniteCovernote() {
        var _this = this;

        if (confirm('Are you sure you want to transform this covernote to a Definite Covernote?')) {
          var model = this.get('model');
          model.set('documentType', 'DEFINITE_COVERNOTE');
          model.save().then(function (model) {
            model.reload();
            _this.get('notifications').success("Covernote was transformed to a definite covernote successfully");
          }).catch(function (response) {
            model.rollbackAttributes();
            _this.get('notifications').error('There was an error while trying to transform the covernote to a definite covernote. ' + response.errors);
          });
        }
      }
      /*
      cancellationRequestSubmitted() {
        this.get('model').reload();
        this.toggleProperty('isShowingCancellationModal');
      }
      */

    }
  });
});