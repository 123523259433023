define('minerva-frontend/components/proposals/admin-actions/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'admin-actions',
    currentUser: Ember.inject.service(),
    model: undefined,
    hasAdminActions: true,
    actions: {
      markPhysicalDocumentsReceived: function markPhysicalDocumentsReceived() {
        var _this = this;

        var model = this.get('model');
        model.set('physicalDocumentsReceived', true);
        model.save().then(function (model) {
          model.reload();
          _this.get('notifications').success("Proposal documents were marked as filed");
        }).catch(function (response) {
          model.rollbackAttributes();
          _this.get('notifications').error('There was an error while trying to update the proposal. ' + response.errors);
        });
      },
      unmarkPhysicalDocumentsReceived: function unmarkPhysicalDocumentsReceived() {
        var _this2 = this;

        var model = this.get('model');
        model.set('physicalDocumentsReceived', false);
        model.save().then(function (model) {
          model.reload();
          _this2.get('notifications').info("Proposal documents were marked as missing");
        }).catch(function (response) {
          model.rollbackAttributes();
          _this2.get('notifications').error('There was an error while trying to update the proposal. ' + response.errors);
        });
      }
    }
  });
});