define('minerva-frontend/components/combos/vehicle-make-select/component', ['exports', 'minerva-frontend/components/combos/generic-model-select/component', 'minerva-frontend/components/combos/generic-model-select/template'], function (exports, _component, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    layout: _template.default,
    inputPlaceholder: "Search for vehicle make",
    modelClass: 'minerva-make',
    searchTriggerVal: "",
    _searchTriggerActivated: Ember.observer('searchTriggerVal', function () {
      var val = this.get('searchTriggerVal');
      if (!val) {
        return;
      }
      this._performSearch(val);
    })
  });
});