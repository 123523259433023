define('minerva-frontend/initializers/ember-simple-auth-jwt', ['exports', 'ember-simple-auth-jwt/authenticators/jwt'], function (exports, _jwt) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'ember-simple-auth-jwt',
    before: 'ember-simple-auth',
    initialize: function initialize(container) {
      container.register('authenticator:jwt', _jwt.default);
    }
  };
});