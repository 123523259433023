define('minerva-frontend/mixins/change-serializer', ['exports', 'ember-data-change-tracker/mixins/keep-only-changed'], function (exports, _keepOnlyChanged) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _keepOnlyChanged.default;
    }
  });
});