define('minerva-frontend/validators/date', ['exports', 'ember-changeset-validations/utils/validation-errors', 'ember-validators', 'moment'], function (exports, _validationErrors, _emberValidators, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = validateDate;
  function validateDate(options) {
    return function (key, value) {
      var beforeDate = undefined;
      if (typeof options.beforeToday === 'boolean') {
        beforeDate = (0, _moment.default)().toISOString();
      } else {
        beforeDate = (0, _moment.default)();
        var subtractOptions = options.beforeToday || {};
        if (subtractOptions.years) {
          beforeDate = beforeDate.subtract(subtractOptions.years, 'years');
        }
        if (subtractOptions.months) {
          beforeDate = beforeDate.subtract(subtractOptions.months, 'months');
        }
        if (subtractOptions.days) {
          beforeDate = beforeDate.subtract(subtractOptions.days, 'days');
        }
        if (subtractOptions.minutes) {
          beforeDate = beforeDate.subtract(subtractOptions.minutes, 'minutes');
        }

        beforeDate = beforeDate.toISOString();
      }
      if (beforeDate) {
        options.before = beforeDate;
        options.format = 'YYYY-MM-DDTHH:mm:ssZ';
      }
      var result = (0, _emberValidators.validate)('date', value, options, null, key);
      return result === true ? true : (0, _validationErrors.default)(key, result);
    };
  }
});