define('minerva-frontend/components/models-table/group-summary-row', ['exports', 'ember-models-table/components/models-table/group-summary-row'], function (exports, _groupSummaryRow) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _groupSummaryRow.default;
    }
  });
});