define('minerva-frontend/validations/proposals/m-helper', ['exports', 'ember-changeset-validations/validators', 'minerva-frontend/validators/generic/date-before', 'minerva-frontend/validators/generic/date-after', 'ember-changeset-conditional-validations/validators/sometimes'], function (exports, _validators, _dateBefore, _dateAfter, _sometimes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    helperDateOfBirth: [(0, _validators.validatePresence)(true), (0, _dateBefore.default)({ //We validate before, because we are given an old date, and we need to make sure it's at least 18 years ago or more
      years: 18,
      message: '{description} must be greater than 18 years old'
    }), (0, _dateAfter.default)({
      years: -65,
      message: '{description} must be maximum 65 years old'
    })
    //Add validate format for chassis number
    ],
    helperPassportNumber: [(0, _validators.validatePresence)(true)],
    helperFullName: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ min: 2, max: 60 })],
    helperGender: (0, _validators.validatePresence)(true),
    helperAddressOfEmployment: (0, _sometimes.default)([(0, _validators.validatePresence)(true)], function (changes, content) {
      return Ember.get(changes, 'helperOccupation.id') === 'GEORGIKOS_ERGATIS';
    }),
    medicalCoverType: (0, _validators.validatePresence)(true)
  };
});