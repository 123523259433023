define('minerva-frontend/components/covernotes/admin/approve-dissaprove-cancellation-modal/component', ['exports', 'minerva-frontend/validations/reason-forced-approval'], function (exports, _reasonForcedApproval) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    ReasonForcedApprovalValidations: _reasonForcedApproval.default,
    mode: '', //mode = approve,decline. Affects color, labels and state to set to the covernote
    title: Ember.computed('mode', function () {
      if (this.get('mode') === 'approve') {
        return 'Approve Cancellation';
      } else if (this.get('mode') === 'decline') {
        return 'Decline Cancellation';
      } else {
        return 'Undefined';
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.set('reasonObj', { reason: '', confirmation: false });
    },

    actions: {
      _closeModal: function _closeModal() {
        if (Ember.get(this, 'on-close')) {
          Ember.get(this, 'on-close')();
        }
      },
      _submitForm: function _submitForm(changeset) {
        var _this = this;

        var reason = changeset.get('reason');
        var status = '';
        if (this.get('mode') == 'approve') {
          status = 'APPROVED';
        } else {
          status = 'DECLINED';
        }

        var model = this.get('model'); // this is a covernote.

        return model.get('cancellationRequest').then(function (cancellationRequest) {
          cancellationRequest.set('handleComments', reason);
          cancellationRequest.set('status', status);
          return cancellationRequest.save().then(function () /*cancellationRequest*/{
            if (Ember.get(_this, 'on-submit')) {
              Ember.get(_this, 'on-submit')();
            }
          });
        });
      }
    }
  });
});