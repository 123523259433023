define('minerva-frontend/mixins/document-times-calculator', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    getDurationLabel: function getDurationLabel(duration) {
      if (!duration) {
        return "No duration specified";
      }
      if (duration.label) {
        return duration.label;
      }
      var labelParts = [];
      if (duration.years) {
        labelParts.push(duration.years + ' years');
      }
      if (duration.months) {
        labelParts.push(duration.months + ' months');
      }
      if (duration.days) {
        labelParts.push(duration.days + ' days');
      }
      return '(in ' + labelParts.join(' and ') + ')';
    },
    getStartEndDateTimes: function getStartEndDateTimes(initialStartDate, duration) {
      var today;
      if (initialStartDate) {
        today = (0, _moment.default)(initialStartDate);
      } else {
        today = (0, _moment.default)();
      }
      if (!duration) {
        return {
          startDateTime: today.toDate(),
          endDateTime: undefined
        };
      }
      if (duration.preset) {
        return {
          startDateTime: today.toDate(),
          endDateTime: duration.preset.endDateTime
        };
      }

      var later = (0, _moment.default)(today);
      if (duration.years) {
        later.add(duration.years, 'year');
      }
      if (duration.months) {
        later.add(duration.months, 'month');
      }
      if (duration.days) {
        later.add(duration.days, 'day');
      }
      later.subtract(1, 'day');
      later.set({ 'hour': 23, 'minute': 59 });

      return {
        startDateTime: today.toDate(),
        endDateTime: later.toDate()
      };
    }
  });
});