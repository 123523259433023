define('minerva-frontend/validations/covernotes/private', ['exports', 'ember-changeset-conditional-validations/validators/sometimes', 'ember-changeset-validations/validators', 'minerva-frontend/validators/special/min-policy-excess', 'minerva-frontend/validations/covernotes/motor'], function (exports, _sometimes, _validators, _minPolicyExcess, _motor) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.PrivateCovernoteValidations = undefined;
  var PrivateCovernoteValidations = exports.PrivateCovernoteValidations = {
    //Only if lob 11 for presence...must make sure about the presence
    policyExcess: (0, _sometimes.default)([(0, _validators.validateNumber)({ positive: true, integer: true, allowBlank: false }), (0, _minPolicyExcess.default)()
    //Add validate format for chassis number
    ], function (changes, content) {
      var coverTypeId = Ember.get(changes, 'coverType.id');
      if (!coverTypeId) {
        coverTypeId = Ember.get(content, 'coverType.id');
      }
      //return ((coverTypeId === 'COMPREHENSIVE' || coverTypeId === 'FIRE_AND_THEFT') && (get(changes, 'lineOfBusiness.id') === 'PRIVATE' || get(content, 'lineOfBusiness.id') === 'PRIVATE'));
      return coverTypeId === 'COMPREHENSIVE';
    }),

    sumInsured: (0, _sometimes.default)([(0, _validators.validateNumber)({ positive: true, integer: true, allowBlank: false })], function (changes, content) {
      var coverTypeId = Ember.get(changes, 'coverType.id');
      if (!coverTypeId) {
        coverTypeId = Ember.get(content, 'coverType.id');
      }
      //return ((coverTypeId === 'COMPREHENSIVE' || coverTypeId === 'FIRE_AND_THEFT') && (get(changes, 'lineOfBusiness.id') === 'PRIVATE' || get(content, 'lineOfBusiness.id') === 'PRIVATE'));
      return coverTypeId === 'COMPREHENSIVE';
    })
  };
  exports.default = Ember.assign({}, _motor.default, PrivateCovernoteValidations);
});