define('minerva-frontend/components/covernotes/admin/approve-decline-cancellation-form/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    //CancellationRequestValidations,
    store: Ember.inject.service(),
    approvalDeclineMode: "",
    title: "Santanoshies",
    cancellationRequest: undefined,
    covernote: undefined,
    init: function init() {
      this._super.apply(this, arguments);
      if (this.get('covernote.cancellationRequest.id')) {
        var request = this.get('store').findRecord('covernote-cancellation-request', this.get('covernote.cancellationRequest.id'));
        this.set('cancellationRequest', request);
      }
    },

    actions: {
      submitCancellation: function submitCancellation(model) {
        var _this = this;

        model.save().then(function (cancellationRequest) {
          //alert('Cancellation request submitted');
          if (_this.get('on-submit')) {
            _this.get('on-submit')();
          }
        });
      }
    }
  });
});