define('minerva-frontend/components/generic-forms/endorsement-form-wrapper/component', ['exports', 'moment', 'minerva-frontend/config/environment', 'minerva-frontend/models/minerva-document'], function (exports, _moment, _environment, _minervaDocument) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var keys = Object.keys;
  exports.default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    router: Ember.inject.service(),
    paramStore: Ember.inject.service(),

    classNames: "large-form-container",

    //Parameters for making this work both for covernotes and proposals

    modelClass: '', //e.g covernote, or proposal
    detailRoute: '', //e.g covernotes.covernote, proposals.proposal

    selectedLob: undefined,
    selectedDocumentType: undefined,
    selectedContractReference: undefined,

    model: undefined, //SHOULD NOT BE DEFINED. A new one will be generated when we load the existing proposal
    _comtecErrors: undefined,

    covernoteMode: Ember.computed.equal('modelClass', 'covernote'),
    proposalMode: Ember.computed.equal('modelClass', 'proposal'),
    endorsementMode: true, //Note - COEXISTS WITH COVERNOTEMODE. Both need to exist for everything to work properly, as right now, endorsements are treated as special cases of covernotes

    stateInitializers: undefined,
    _lineOfBusinessLock: false,
    _documentTypeLock: false,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      var paramStore = this.get('paramStore');
      paramStore.getParams().then(function (params) {
        Ember.set(_this, 'params', params);
      });
    },


    lobSpecificFormWrapper: Ember.computed('selectedLob.id', function () {
      var selectedLobID = Ember.get(this, 'selectedLob.id');
      switch (selectedLobID) {
        case 'PRIVATE':
        case 'COMMERCIAL':
        case 'MOTORCYCLE':
        case 'SPECIAL_TYPE':
        case 'PRIVATE_BUS':
          return 'motor-form';
        case 'EMPLOYER_LIABILITY_HELPER':
        case 'MEDICAL_HELPER':
        case 'EMPLOYER_LIABILITY_AND_MEDICAL_HELPER':
          return null; //return 'elnm-helper-form';
        default:
          return null;
      }
    }),
    actions: {
      lobSelected: function lobSelected(lob) {
        //Lob has changed. So we re-initialize our whole changeset, along with any initialization logic that needs to happen
        //with the new lob

        /*
        if (!lob) {
          set(this, '_clientDataLocked', false);
          set(this, '_genericDataInfoLocked', false);
        }
        */
        Ember.set(this, 'selectedLob', lob);
      },
      documentTypeSelected: function documentTypeSelected(documentType) {
        Ember.set(this, 'selectedDocumentType', documentType);
      },
      checkEnter: function checkEnter(followUpEvent, e) {
        if (e.key === 'Enter') {
          this.get('actions.' + followUpEvent).call(this);
        }
      },
      loadEndorsementContract: function loadEndorsementContract() {
        var _this2 = this;

        var existingContractReference = Ember.get(this, 'selectedContractReference');
        var documentType = Ember.get(this, 'selectedDocumentType');
        var lineOfBusiness = Ember.get(this, 'selectedLob');

        if (!existingContractReference) {
          return;
        }
        Ember.set(this, '_contractFound', false);
        Ember.set(this, '_contractLoaded', false);
        Ember.set(this, '_contractLoading', true);
        var url = _environment.default.APP.apiUrl + "/covernotes/policydetails";

        var headers = {};
        this.get('session').authorize('authorizer:jwt', function (headerName, headerValue) {
          headers[headerName] = headerValue;
        });
        var notifications = Ember.get(this, 'notifications');
        this.get('ajax').request(url, {
          method: 'GET',
          headers: headers,
          contentType: "application/x-www-form-urlencoded; charset=UTF-8",
          data: {
            lob: Ember.get(lineOfBusiness, 'comtecCode'),
            policyId: existingContractReference
          }
        }).then(function (response) {
          //let json = response;

          var stateInitializers = {
            state: 'CONTRACT_TO_ENDORSEMENT',
            values: {
              type: Ember.get(documentType, 'id'),
              existingContractReference: existingContractReference
            }
          };
          Ember.set(_this2, 'stateInitializers', stateInitializers);

          var initProperties = {};
          keys(_minervaDocument.documentSchema).forEach(function (key) {
            initProperties[key] = Ember.get(response, key);
          }); //TODO - HOW THE FUCK DOES NOT THIS LOOSE A BUNCH OF INFO? I SHOULD DO THIS, OR I SHOULD INITIATE MORE CAREFULLY!
          if (initProperties.dateOfBirth) {
            initProperties.dateOfBirth = (0, _moment.default)(initProperties.dateOfBirth, 'YYYY-MM-DD').toDate();
          }
          if (initProperties.startDateTime) {
            initProperties.startDateTime = (0, _moment.default)(initProperties.startDateTime).toDate();
          }
          if (initProperties.endDateTime) {
            initProperties.endDateTime = (0, _moment.default)(initProperties.endDateTime).toDate();
          }

          delete initProperties.documentType; //This will be an endorsement anw
          delete initProperties.state; //Server generated

          delete initProperties.documentNumber; //Server generated
          delete initProperties.documentDateTime; //Server generated

          var ownerId = initProperties.owner;
          delete initProperties.owner; //Server generated
          var nationalityId = initProperties.nationality;
          delete initProperties.nationality;
          var occupationId = initProperties.occupation;
          delete initProperties.occupation;
          var streetId = initProperties.street;
          delete initProperties.street;
          var vehicleMakeId = initProperties.vehicleMake;
          delete initProperties.vehicleMake;

          var model = _this2.get('store').createRecord('covernote', initProperties); //this.get('model');
          model.set('drivers', initProperties.drivers);

          //Clear document type
          model.set('documentType', Ember.get(documentType, 'id'));
          model.set('existingContractReference', existingContractReference);

          var promises = [];
          if (ownerId) {
            promises.push(_this2.get('store').findRecord('user', ownerId).then(function (obj) {
              model.set('owner', obj);
            }));
          }
          if (nationalityId) {
            promises.push(_this2.get('store').findRecord('country', nationalityId).then(function (obj) {
              model.set('nationality', obj);
            }));
          }
          if (occupationId) {
            promises.push(_this2.get('store').findRecord('occupation', occupationId).then(function (obj) {
              model.set('occupation', obj);
            }));
          }
          if (streetId) {
            promises.push(_this2.get('store').findRecord('street', streetId).then(function (obj) {
              model.set('street', obj);
            }));
          }
          if (vehicleMakeId) {
            promises.push(_this2.get('store').findRecord('minerva-make', vehicleMakeId).then(function (obj) {
              model.set('vehicleMake', obj);
            }));
          }
          Ember.RSVP.Promise.all(promises).catch(function () /*e*/{
            //console.log("A promise returned an error", e);
          }).finally(function () {

            _this2.set('model', model);
            Ember.set(_this2, '_contractLoading', false);
            Ember.set(_this2, '_contractLoaded', true);
            Ember.set(_this2, '_contractFound', true);
            notifications.success('Policy with number ' + existingContractReference + ' found. Loading...');
          });
        }).catch(function (response) {
          Ember.set(_this2, '_contractLoading', false);
          Ember.set(_this2, '_contractLoaded', true);
          Ember.set(_this2, '_contractFound', false);
          notifications.warning('Policy with number ' + existingContractReference + ' could not be found.');
        });
      },
      clearEndorsementContract: function clearEndorsementContract() {
        Ember.set(this, '_contractLoading', false);
        Ember.set(this, '_contractLoaded', false);
        Ember.set(this, '_contractFound', false);
        //Clear model
        if (this.get('model')) {
          var model = this.get('model');
          model.destroyRecord();
        }
        Ember.set(this, 'model', undefined);
      },
      goback: function goback() {
        if (confirm("Are you sure you want to cancel?")) {
          this.get('router').transitionTo('index');
          //history.back();
        }
      }
    }
  });
});