define('minerva-frontend/validations/proposals/commercial', ['exports', 'ember-changeset-validations/validators', 'minerva-frontend/validations/covernotes/commercial', 'minerva-frontend/validations/proposals/motor'], function (exports, _validators, _commercial, _motor) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.CommercialProposalValidations = undefined;
  var CommercialProposalValidations = exports.CommercialProposalValidations = {
    vehicleCategory: (0, _validators.validatePresence)(true)

  };
  exports.default = Ember.assign({}, _commercial.default, _motor.default, CommercialProposalValidations);
});