define('minerva-frontend/routes/covernotes/active', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model() {
      return this.get('store').query('covernote', { page: 1, size: 10, state: 'ACTIVE', sort: 'documentDateTime,desc' });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('page', 1);
      }
    }
  });
});