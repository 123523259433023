define('minerva-frontend/components/combos/generic-model-select/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    options: null, //Options shown in select. Filled when perfoming a search
    selectMultiple: false,
    disableBackendSearch: false,
    searchEnabled: true,
    searchField: undefined,
    modelClass: '',
    displayAttr: 'display',
    allowClear: false,
    findByDisplayNameSearchTermKey: 'display',
    findByDisplayNameSearchPageSize: 50,
    actionToDo: 'findByDisplayName',
    inputPlaceholder: "Search for instance",

    _performSearch: function _performSearch(term) {
      var _this = this;

      var modelClass = this.get('modelClass');
      var emptyRecord = this.get('store').createRecord(modelClass);
      var filterParams = {};
      filterParams[this.get('findByDisplayNameSearchTermKey')] = term;
      filterParams['size'] = this.get('findByDisplayNameSearchPageSize');
      var actionToDo = this.get('actionToDo');

      emptyRecord[actionToDo](filterParams /*{ custom options }*/).then(function (response) {
        var models = _this.get('store').pushPayload(modelClass, response);
        _this.set('options', models);
      }).catch(function () /*response*/{
        _this.set('options', []);
      }).finally(function () {
        emptyRecord.deleteRecord();
      });
    },

    actions: {
      searchRepo: function searchRepo(term) {
        var _this2 = this;

        if (this.get('disableBackendSearch')) {
          return;
        } else {
          return new Ember.RSVP.Promise(function (resolve, reject) {
            Ember.run.debounce(_this2, _this2._performSearch, term, resolve, reject, 600);
          });
        }
      },
      changeVal: function changeVal(selection) {
        this.get('onupdate')(selection);
      },
      handleFocus: function handleFocus(select, e) {
        if (this.focusComesFromOutside(e)) {
          select.actions.open();
          if (this.get('onhover')) {
            this.get('onhover')();
          }
        }
      }
    },
    focusComesFromOutside: function focusComesFromOutside(e) {
      var blurredEl = e.relatedTarget;
      if (Ember.isBlank(blurredEl)) {
        return false;
      }
      return !blurredEl.classList.contains('ember-power-select-search-input');
    }
  });
});