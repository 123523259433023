define('minerva-frontend/routes/proposal/new2', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    currentUser: Ember.inject.service(),
    queryParams: {
      lob: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel() {
      if (!this.get('currentUser.user.canIssueProposal')) {
        return this.transitionTo('index');
      }
    },
    model: function model(params) {
      if (params.lob) {
        return Ember.RSVP.hash({
          lineOfBusinessId: params.lob,
          proposal: this.get('store').createRecord('proposal', {})
        });
      } else {
        return Ember.RSVP.hash({
          lineOfBusinessId: null,
          proposal: this.get('store').createRecord('proposal', {})
        });
        //return this.transitionTo('index');
      }
    },

    actions: {
      willTransition: function willTransition() {
        var model = this.controller.get('model.proposal');
        if (model && model.get('isNew')) {
          model.destroyRecord();
        }
      }
    }
  });
});