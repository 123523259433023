define('minerva-frontend/helpers/get-pdf-url', ['exports', 'minerva-frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getPdfUrl = getPdfUrl;
  function getPdfUrl(params /*, hash*/) {
    return '' + _environment.default.APP.apiUrl + params;
  }

  exports.default = Ember.Helper.helper(getPdfUrl);
});