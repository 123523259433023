define('minerva-frontend/components/covernotes/request-cancellation-form/component', ['exports', 'minerva-frontend/validations/covernote-cancellation-request'], function (exports, _covernoteCancellationRequest) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    CancellationRequestValidations: _covernoteCancellationRequest.default,
    store: Ember.inject.service(),
    cancellationRequest: undefined,
    covernote: undefined,
    init: function init() {
      this._super.apply(this, arguments);
      var request = { cancellationReason: '', cancellationRequestConfirmation: false };
      this.set('cancellationRequest', request);
    },

    actions: {
      submit: function submit(changeset) {
        var _this = this;

        var request = this.get('store').createRecord('covernote-cancellation-request', {
          covernote: this.get('covernote'),
          cancellationReason: changeset.get('cancellationReason')
        });
        request.save().then(function () /*cancellationRequest*/{
          _this.get('notifications').success("Your cancellation request was sent successfully");
          if (_this.get('on-submit')) {
            _this.get('on-submit')();
          }
        }).catch(function (response) {
          request.rollbackAttributes();
          _this.get('notifications').error('There was an error while trying to submit your cancellation request. ' + response.errors);
        });
      }
    }
  });
});