define('minerva-frontend/adapters/user', ['exports', 'minerva-frontend/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    urlForQueryRecord: function urlForQueryRecord(query) {
      if (query.me) {
        delete query.me;
        return this._super.apply(this, arguments) + '/me';
      }
      return this._super.apply(this, arguments);
    },
    urlForUpdateRecord: function urlForUpdateRecord(id, modelName, snapshot) {
      var url = this._super.apply(this, arguments);
      if (snapshot.adapterOptions && snapshot.adapterOptions.me) {
        var meUrl = url.substring(0, url.lastIndexOf('/')) + "/me";
        return meUrl;
      }
      return url;
    }
  });
});