define('minerva-frontend/validators/special/driver-license-date', ['exports', 'ember-changeset-validations/utils/validation-errors', 'ember-validators', 'moment'], function (exports, _validationErrors, _emberValidators, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = validateDriverLicenseDate;
  function validateDriverLicenseDate() {
    var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    return function (key, newValue, oldValue, changes, content) {
      var dateOfBirth = Ember.get(changes, options.birthdayField) || Ember.get(content, options.birthdayField);
      if (!dateOfBirth) {
        return true; //No need to validate. we do not have a date of birth to compare against to
      }
      var minLicenseDate = (0, _moment.default)(dateOfBirth);
      var maxLicenseDate = (0, _moment.default)();
      var isLearner = Ember.get(changes, options.learnerField) || Ember.get(content, options.learnerField);

      if (isLearner) {
        minLicenseDate = minLicenseDate.add(17, 'years');
      } else {
        minLicenseDate = minLicenseDate.add(18, 'years');
      }
      minLicenseDate = minLicenseDate.subtract(1, 'days'); //So we capture drivers that closed 18 in the dat of the test

      options.message = "License date must be after " + minLicenseDate.format('DD/MM/YYYY') + " and before today";
      options.after = minLicenseDate.toISOString();
      options.before = maxLicenseDate.toISOString();
      options.format = 'YYYY-MM-DDTHH:mm:ssZ';

      //Also validate if greater than birthdate
      var result = (0, _emberValidators.validate)('date', newValue, options, null, key);
      return result === true ? true : (0, _validationErrors.default)(key, result, newValue, options);
    };
  }
});