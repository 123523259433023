define('minerva-frontend/validators/generic/date-after', ['exports', 'ember-changeset-validations/utils/validation-errors', 'ember-validators', 'moment'], function (exports, _validationErrors, _emberValidators, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = validateDate;
  function validateDate(options) {
    return function (key, value, oldValue, changes, content) {
      var afterDate = undefined;

      if (!options.field) {
        afterDate = (0, _moment.default)();
      } else {
        var relatedFieldValue = Ember.get(changes, options.field) || Ember.get(content, options.field);
        if (relatedFieldValue) {
          afterDate = (0, _moment.default)(relatedFieldValue);
        } else {
          return true;
        }
      }

      if (options.years) {
        afterDate = afterDate.add(options.years, 'years');
      }
      if (options.months) {
        afterDate = afterDate.add(options.months, 'months');
      }
      if (options.days) {
        afterDate = afterDate.add(options.days, 'days');
      }
      if (options.hours) {
        afterDate = afterDate.add(options.hours, 'hours');
      }
      if (options.minutes) {
        afterDate = afterDate.add(options.minutes, 'minutes');
      }

      afterDate.set({ 'second': 0 });
      afterDate = afterDate.toISOString();

      if (afterDate) {
        options.onOrAfter = afterDate;
        options.format = 'YYYY-MM-DDTHH:mm:ssZ';
        options.errorFormat = 'YYYY-MM-DD HH:mm Z';
      }
      var result = (0, _emberValidators.validate)('date', value, options, null, key);
      return result === true ? true : (0, _validationErrors.default)(key, result);
    };
  }
});