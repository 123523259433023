define('minerva-frontend/models/driver', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    driverId: _emberData.default.attr('string'),
    idType: _emberData.default.belongsTo('params/id-type'),
    driverName: _emberData.default.attr('string'),
    learner: _emberData.default.attr('learner'),
    dateOfBirth: _emberData.default.attr('date'),
    excessAmount: _emberData.default.attr('number'),
    licenseNumber: _emberData.default.attr('string')
  });
});