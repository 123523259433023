define('minerva-frontend/mixins/list-table', ['exports', 'ember-models-table/themes/bootstrap4'], function (exports, _bootstrap) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    showComponentFooter: true,
    showColumnsDropdown: false,
    useFilteringByColumns: false,
    showGlobalFilter: true,
    useNumericPagination: false,
    showPageSize: true,
    multipleColumnsSorting: false,
    sendRowDoubleClick: false,

    metaPagesCountProperty: 'totalPages',
    metaItemsCountProperty: 'totalElements',
    filterQueryParameters: {
      globalFilter: 'search',
      sort: 'sort',
      sortDirection: 'dir',
      page: 'page',
      pageSize: 'size'
    },
    themeInstance: Ember.computed(function () {
      return _bootstrap.default.create({
        globalFilterWrapper: 'table-search-box-container'
      });
    }),
    sortingWrapper: function sortingWrapper(query, sortBy, sortDirection) {
      //query[get(this, 'filterQueryParameters.sort')] = sortBy;
      //query[get(this, 'filterQueryParameters.sortDirection')] = sortDirection;
      query[Ember.get(this, 'filterQueryParameters.sort')] = sortBy + "," + sortDirection;
      return query;
    }
  });
});