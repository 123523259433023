define('minerva-frontend/components/covernotes/admin/approve-dissaprove-modal/component', ['exports', 'minerva-frontend/validations/reason-forced-approval'], function (exports, _reasonForcedApproval) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    ReasonForcedApprovalValidations: _reasonForcedApproval.default,
    mode: '', //mode = approve,decline. Affects color, labels and state to set to the covernote
    title: Ember.computed('mode', function () {
      if (this.get('mode') === 'approve') {
        return 'Approve Covernote';
      } else if (this.get('mode') === 'decline') {
        return 'Decline Covernote';
      } else {
        return 'Undefined';
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.set('reasonObj', { reason: '', confirmation: false });
    },

    actions: {
      _closeModal: function _closeModal() {
        if (Ember.get(this, 'on-close')) {
          Ember.get(this, 'on-close')();
        }
      },
      _submitForm: function _submitForm(changeset) {
        var _this = this;

        var reason = changeset.get('reason');
        var state = '';
        if (this.get('mode') == 'approve') {
          state = 'ACTIVE';
        } else {
          state = 'DECLINED';
        }
        var model = this.get('model'); //this is a covernote;
        model.set('state', state);
        model.set('handleComments', reason);
        return model.save().then(function () /*model*/{
          _this.get('notifications').success('Document was updated successully.');
          if (Ember.get(_this, 'on-submit')) {
            Ember.get(_this, 'on-submit')();
          }
        }).catch(function (response) {
          _this.get('notifications').error('There was an error while updating the document. ' + response.errors);
        });
      }
    }
  });
});