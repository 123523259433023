define('minerva-frontend/components/date-picker-month', ['exports', 'ember-date-components/components/date-picker-month'], function (exports, _datePickerMonth) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _datePickerMonth.default;
    }
  });
});