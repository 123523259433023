define('minerva-frontend/validations/definite-endorsements/document-generic-info', ['exports', 'ember-changeset-validations/validators', 'minerva-frontend/validators/generic/date-before', 'ember-changeset-conditional-validations/validators/sometimes', 'minerva-frontend/validators/generic/date-after'], function (exports, _validators, _dateBefore, _sometimes, _dateAfter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {

    startDateTime: (0, _sometimes.default)([(0, _validators.validatePresence)(true), (0, _dateAfter.default)({
      minutes: -30,
      message: '{description} cannot start earlier than now'
    })
    //TODO - Need to validate that startDateTime is lower than the maxEndDateTime which shouold be equal to the base contract endDateTime
    ], function (changes, content) {
      return Ember.get(changes, 'customStartEndDate') == true;
    }),
    /*
    TODO - Need to validate that endDateTime is lower than the maxEndDateTime which shouold be equal to the base contract endDateTime
    */
    endDateTime: (0, _sometimes.default)([(0, _validators.validatePresence)(true), (0, _dateAfter.default)({
      field: 'startDateTime',
      message: '{description} cannot be before start date'
    }), (0, _dateBefore.default)({
      field: 'maxEndDateTime',
      message: '{description} cannot be after contract ending date of {onOrAfter}'
    })], function (changes, content) {
      return Ember.get(changes, 'customStartEndDate') == true;
    })

  };
});