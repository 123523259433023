define('minerva-frontend/serializers/application', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTSerializer.extend({
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      //console.log("Normalizing response...");
      //console.log("primaryModelClass:", primaryModelClass);
      //console.log("payload:", payload);
      //console.log("id:", id);
      //console.log("requestType:", requestType);
      var convertedPayload = {};
      if (payload.hasOwnProperty('content') && !payload.hasOwnProperty('id')) {
        convertedPayload[primaryModelClass.modelName] = JSON.parse(JSON.stringify(payload.content));
        delete payload.content;
        convertedPayload['meta'] = JSON.parse(JSON.stringify(payload));
      } else {
        convertedPayload[primaryModelClass.modelName] = JSON.parse(JSON.stringify(payload));
      }
      //console.log("Converted payload:", convertedPayload);
      return this._super(store, primaryModelClass, convertedPayload, id, requestType);
    },
    serializeIntoHash: function serializeIntoHash(hash, typeClass, snapshot, options) {
      //let normalizedRootKey = this.payloadKeyFromModelName(typeClass.modelName);
      Ember.assign(hash, this.serialize(snapshot, options));
    },
    pushPayload: function pushPayload(store, payload) {
      var modelClass = store.modelFor(this.get('modelClassName'));
      var payload2 = this.normalizeResponse(store, modelClass, payload, undefined, 'query');
      return store.push(payload2);
    }
  });
});