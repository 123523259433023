define('minerva-frontend/components/generic-forms/motor/drivers-wizard-form/component', ['exports', 'ember-changeset-validations', 'ember-changeset', 'minerva-frontend/validations/driver', 'minerva-frontend/validations/owner-driver', 'moment'], function (exports, _emberChangesetValidations, _emberChangeset, _driver, _ownerDriver, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Driver = Ember.Object.extend({
    driverId: '',
    driverName: '',
    learner: false,
    dateOfBirth: '',
    excessAmount: null,
    licenseDate: '',
    foreignLicense: false,
    ownerDriver: false
  });
  exports.default = Ember.Component.extend({
    DriverValidations: _driver.default,
    dataLocked: undefined,
    drivers: null,
    value: undefined,

    canHaveOwnerDriving: false, //Should be pass down from the lob form
    driverExcessVisible: false, //This is visible when on covernote mode. But we should let the parent form decide
    driverLearnerVisible: true,
    _driversInEditMode: Ember.computed.filterBy('_driverChangesets', 'isEditing', true),
    _hasDriversInEditMode: Ember.computed.notEmpty('_driversInEditMode'), //Need to make sure to show it in the template, so it;s beeing forced to be calculated!!
    _hasDriversInEditModeChange: Ember.observer('_hasDriversInEditMode', function () {
      this.set('hasDriversInEditMode', this.get('_hasDriversInEditMode'));
    }),
    _driversCount: Ember.computed.alias('_drivers.length'),
    _canAddDrivers: Ember.computed.lt('_driversCount', 7),
    _ownerDrivingButtonShown: Ember.computed('canHaveOwnerDriving', '_driverChangesets.[]', function () {
      if (!this.get('canHaveOwnerDriving')) {
        return false;
      }
      var driverChangesets = Ember.get(this, '_driverChangesets');
      var found = false;
      driverChangesets.forEach(function (driverChangeset) {
        if (Ember.get(driverChangeset, 'ownerDriver')) {
          found = true;
        }
      });
      return !found;
    }),
    _driversLocked: Ember.computed.alias('dataLocked'),
    _driversChangesets: undefined,

    _getDrivers: function _getDrivers() {
      var driverChangesets = Ember.get(this, '_driverChangesets');
      var drivers = [];
      driverChangesets.forEach(function (driverChangeset) {
        if (driverChangeset.get('isValid')) {
          drivers.push({
            driverId: Ember.get(driverChangeset, 'driverId'),
            driverName: Ember.get(driverChangeset, 'driverName'),
            learner: Ember.get(driverChangeset, 'learner'),
            dateOfBirth: Ember.get(driverChangeset, 'dateOfBirth') ? (0, _moment.default)(Ember.get(driverChangeset, 'dateOfBirth')).format('YYYY-MM-DD') : null,
            excessAmount: Ember.get(driverChangeset, 'excessAmount'),
            licenseDate: (0, _moment.default)(Ember.get(driverChangeset, 'licenseDate')).format('YYYY-MM-DD'),
            foreignLicense: Ember.get(driverChangeset, 'foreignLicense'),
            ownerDriver: Ember.get(driverChangeset, 'ownerDriver')
          });
        }
      });
      return drivers;
    },

    _drivers: Ember.computed('_driverChangesets.[]', function () {
      return this._getDrivers();
      /*
      var driverChangesets = get(this, '_driverChangesets');
      var drivers = [];
      driverChangesets.forEach((driverChangeset) => {
        if (driverChangeset.get('isValid')) {
          drivers.push(
            {
              driverId: get(driverChangeset, 'driverId'),
              driverName: get(driverChangeset, 'driverName'),
              learner: get(driverChangeset, 'learner'),
              dateOfBirth: moment(get(driverChangeset, 'dateOfBirth')).format('YYYY-MM-DD'),
              excessAmount: get(driverChangeset, 'excessAmount'),
              licenseDate: moment(get(driverChangeset, 'licenseDate')).format('YYYY-MM-DD'),
              foreignLicense: get(driverChangeset, 'foreignLicense')
            }
          );
        }
      });
      return drivers;
      */
    }),
    init: function init() {
      this._super.apply(this, arguments);
      var existingDrivers = Ember.get(this, 'value');
      var drivers = Ember.A([]);
      var changesetDrivers = Ember.A([]);
      if (!existingDrivers || !existingDrivers.length) {
        Ember.set(this, '_driverChangesets', changesetDrivers);
      } else {
        existingDrivers.forEach(function (driverPOJO) {
          console.log("Driver", driverPOJO);
          var driver = Driver.create(driverPOJO);
          if (driverPOJO.dateOfBirth) {
            driver.set('dateOfBirth', new _moment.default(driverPOJO.dateOfBirth, 'YYYY/MM/DD').toDate());
          }
          if (driverPOJO.licenseDate) {
            driver.set('licenseDate', new _moment.default(driverPOJO.licenseDate, 'YYYY/MM/DD').toDate());
          }
          driver.set('isEditing', false);
          drivers.pushObject(driver);
          var validationClass = _driver.default;
          if (driver.get('ownerDriving')) {
            validationClass = _ownerDriver.default;
          }
          var driverChangeset = new _emberChangeset.default(driver, (0, _emberChangesetValidations.default)(validationClass), validationClass);
          changesetDrivers.pushObject(driverChangeset);
        });
      }
      //return {changesetDrivers: changesetDrivers, drivers: drivers};
      console.log("_driverChangesets updated", changesetDrivers);
      Ember.set(this, '_driverChangesets', changesetDrivers);
      //this._initExistingDriversAndChangeSets(model, changeset, isModelPreinitialized);
    },

    /*
    _calculateExcess() {
      //console.log("1.*******");
      var excess = 240;
      var sumInsured = this.get('changeset.sumInsured');
      //console.log("2.*******");
      if (!sumInsured) {
        sumInsured = 0;
      }
      if (sumInsured / 100 < 240) {
        excess = 240;
      } else {
        excess = sumInsured / 100;
      }
      var years70 = moment().subtract(70, 'years');
      var years75 = moment().subtract(75, 'years');
      var haveLearner = false;
      var haveOld = false;
      if (this.get('drivers')) {
        this.get('drivers').forEach((driver) => {
          if (get(driver, 'learner')) {
            haveLearner = true;
          }
          var driverYear = moment(get(driver, 'dateOfBirth'));
          if (driverYear.isBetween(years75, years70)) {
            haveOld = true;
          }
        });
      }
      //console.log("3.*******");
      if (haveLearner) {
        excess = excess + 40;
      }
      if (haveOld) {
        excess = excess + 40;
      }
      return Math.round(excess);
    },
     excessCalculated: computed('isPolicyExcessAutocalculated', 'changeset.sumInsured', 'drivers.[]', 'drivers.@each.dateOfBirth', 'drivers.@each.learner', function () {
      if (!this.get('isPolicyExcessAutocalculated')) {
        return undefined;
      }
      return this._calculateExcess();
    }),
    */
    actions: {
      addDriver: function addDriver() {
        var driver = Driver.create({ isEditing: true });
        var driverChangeset = new _emberChangeset.default(driver, (0, _emberChangesetValidations.default)(_driver.default), _driver.default);
        var drivers = Ember.get(this, '_driverChangesets');
        drivers.pushObject(driverChangeset);
        driverChangeset.validate();
      },
      addOwnerDriver: function addOwnerDriver() {
        var driver = Driver.create({ ownerDriver: true, isEditing: true });
        var driverChangeset = new _emberChangeset.default(driver, (0, _emberChangesetValidations.default)(_ownerDriver.default), _ownerDriver.default);
        var drivers = Ember.get(this, '_driverChangesets');
        drivers.pushObject(driverChangeset);
        driverChangeset.validate();
      },
      removeDriver: function removeDriver(driverIndex) {
        Ember.get(this, '_driverChangesets').removeAt(driverIndex);
        this.get('onchange')(this._getDrivers());
      },
      updateDrivers: function updateDrivers() {
        console.log("Propagating updating drivers...");
        this.get('onchange')(this._getDrivers());
      }
    }
  });
});