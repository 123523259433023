define('minerva-frontend/components/helpers/version-updater/component', ['exports', 'minerva-frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    paramStore: Ember.inject.service(),
    getVersionAsInt: function getVersionAsInt(versionString) {
      var newString = versionString.replace(/\./g, '');
      return parseInt(newString);
    },
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.get('paramStore').getConfig().then(function (serverConfig) {
        var frontendAppVersion = new String(serverConfig.frontendAppVersion);
        var currentAppVersion = new String(_environment.default.APP.appVersion);
        _this.set('latestAppVersion', frontendAppVersion);
        _this.set('currentAppVersion', currentAppVersion);
        if (_this.getVersionAsInt(frontendAppVersion) > _this.getVersionAsInt(currentAppVersion)) {
          _this.set('mustUpdate', true);
        }
      });
    },

    actions: {
      reloadPage: function reloadPage() {
        location.reload(true);
      }
    }

  });
});