define('minerva-frontend/components/generic-forms/client-form/component', ['exports', 'minerva-frontend/config/environment', 'moment'], function (exports, _environment, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    paramStore: Ember.inject.service(),
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    ajax: Ember.inject.service(),
    idTypes: undefined,
    genders: undefined,
    _model: undefined,
    _changeset: undefined,
    stateInitializers: undefined,
    policyInstallments: Ember.computed.alias('params.policy-installments'),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.set('cities', Ember.A([{ id: 1, value: "Lefkosia" }, { id: 5, value: "Lemesos" }, { id: 3, value: "Larnaka" }, { id: 4, value: "Paphos" }, { id: 6, value: "Ammochostos" }]));

      var paramStore = this.get('paramStore');
      paramStore.getParams().then(function (params) {
        Ember.set(_this, 'idTypes', Ember.get(params, 'id-types'));
        Ember.set(_this, 'genders', Ember.get(params, 'genders'));

        var stateInitializers = _this.get('stateInitializers'); //Should be passed when initializing the object
        var initState = stateInitializers ? stateInitializers.state : false;
        if (initState) {
          if (!_this.get('_changeset.street')) {
            //Means it has custom address!
            Ember.set(_this, 'isForeignStreet', true);
          }

          if (initState === 'COVERNOTE_TO_PROPOSAL') {
            //pass - TODO
            Ember.set(_this, '_clientDataLoading', false);
            Ember.set(_this, '_clientDataLoaded', true);
            Ember.set(_this, '_comtectClientInfoLock', true);
            Ember.set(_this, 'dataLocked', true); //We lock everything up, as everything should have been set from the covernote
          } else if (initState === 'CONTRACT_TO_ENDORSEMENT') {
            //pass - TODO
            Ember.set(_this, '_clientDataLoading', false);
            Ember.set(_this, '_clientDataLoaded', true);
            Ember.set(_this, '_comtectClientInfoLock', true);
          } else if (initState === 'COVERNOTE_FINALIZATION') {
            Ember.set(_this, '_clientDataLoading', false);
            Ember.set(_this, '_clientDataLoaded', true);

            //Check if client exists in comtec. If yes, lock him (cause probably we brought him from there)
            //IF not, open shit up, cause the user has put everything in the first place
            Ember.set(_this, '_comtectClientInfoLock', true); //Lock until we verify whether we should lock or not
            if (stateInitializers.values.covernote.get('clientId')) {
              _this._checkIfClientExistsInComtec(stateInitializers.values.covernote.get('clientId'), stateInitializers.values.covernote.get('clientIdType.id')).then(function (result) {
                Ember.set(_this, '_comtectClientInfoLock', result);
              });
            }
          }
        } else {
          var defaultClientIdType = 'ID_CARD'; //get(backendConfig, 'documentDefaults.clientIdType');
          Ember.set(_this._changeset, 'clientIdType', Ember.get(_this, 'idTypes').findBy('id', defaultClientIdType));

          paramStore.getConfig().then(function (backendConfig) {
            if (Ember.get(backendConfig, 'documentDefaults.nationality')) {
              _this.get('store').findRecord('country', Ember.get(backendConfig, 'documentDefaults.nationality')).then(function (country) {
                Ember.set(_this._changeset, 'nationality', country);
              });
            }
          });
        }
      });
    },

    //Extra fields for making the form interactively adapt based on the input values
    isForeignStreet: false,
    selectedClientIdType: Ember.computed.oneWay('_changeset.clientIdType.id'),

    //Client Type Checks
    isClientCompany: Ember.computed.equal('selectedClientIdType', 'COMPANY_REGISTRATION'),
    ////Client Type related visibility checks
    isGenderVisible: Ember.computed.not('isClientCompany'),
    isNationalityVisible: Ember.computed.not('isClientCompany'),

    cities: null, //Get loaded from combo-box directly
    documentTypes: null,
    _cityCodeNameFirstHalf: "",
    _cityCodeNameSecondHalf: "",

    //@Put this in the submit button logic!. If isCustomAddress then do this and apply the changes to the changeset that was passed to submit
    _cityCodeNameUpdated: Ember.observer('_cityCodeNameFirstHalf', '_cityCodeNameSecondHalf', function () {
      if (this.get('_cityCodeNameFirstHalf')) {
        this.set('_changeset.cityCodeName', this.get('_cityCodeNameFirstHalf') + ', ' + this.get('_cityCodeNameSecondHalf'));
      } else {
        this.set('_changeset.cityCodeName', '' + this.get('_cityCodeNameSecondHalf'));
      }
    }),

    _comtectClientInfoFieldType: Ember.computed('_comtectClientInfoLock', function () {
      if (Ember.get(this, '_comtectClientInfoLock')) {
        return 'text';
      } else {
        return 'text';
      }
    }),

    _clientDataFound: false,
    _clientDataLoaded: false,
    _clientDataLoading: false,

    dataLocked: undefined,
    changeLocked: undefined,

    _clientDataLocked: Ember.computed('preInitialized', 'dataLocked', function () {
      if (this.get('dataLocked') === undefined) {
        return this.get('preInitialized');
      } else {
        return this.get('dataLocked');
      }
    }),
    _clientChangeLocked: Ember.computed('preInitialized', 'changeLocked', function () {
      if (this.get('changeLocked') === undefined) {
        if (this.get('dataLocked') === undefined) {
          return this.get('preInitialized');
        } else {
          return this.get('dataLocked');
        }
      } else {
        return this.get('changeLocked');
      }
    }),

    _checkIfClientExistsInComtec: function _checkIfClientExistsInComtec(clientId, clientIdType) {
      var _this2 = this;

      console.log("Checking _checkIfClientExistsInComtec", clientId, clientIdType);
      var data = {
        idType: clientIdType,
        id: clientId
      };

      var url = _environment.default.APP.apiUrl + "/comtec/lookupUser";
      this.set('_isComtecClientLoading', true);
      var sessionResult = void 0;
      this.get('session').authorize('authorizer:jwt', function (headerName, headerValue) {
        console.log("Running after getting auth meta");
        var headers = {};
        headers[headerName] = headerValue;
        sessionResult = _this2.get('ajax').request(url, {
          method: 'GET',
          data: data,
          headers: headers,
          contentType: 'application/json; charset=utf-8'
        }).then(function (response) {
          console.log("Ember ajax run successfully");
          if (response.comtecClientNumber) {
            return true;
          } else {
            return false;
          }
        }).catch(function (response) {
          return false;
        });
      });
      return sessionResult;
    },
    _fetchClientInformation: function _fetchClientInformation(clientId, clientIdType /*, resolve, reject*/) {
      var _this3 = this;

      if (!clientId) {
        return;
      }
      Ember.set(this, '_clientDataLoading', true);
      var data = {
        idType: clientIdType,
        id: clientId
      };
      var url = _environment.default.APP.apiUrl + "/comtec/lookupUser";
      this.set('_isComtecClientLoading', true);
      this.get('session').authorize('authorizer:jwt', function (headerName, headerValue) {
        var headers = {};
        headers[headerName] = headerValue;
        _this3.get('ajax').request(url, {
          method: 'GET',
          data: data,
          headers: headers,
          contentType: 'application/json; charset=utf-8'
        }).then(function (response) {
          console.log("Successful call", response);
          var changeset = Ember.get(_this3, '_changeset');
          if (response.comtecClientNumber) {
            //means the request succeeded
            _this3.get('notifications').success("Client found in backend system. Autopopulating...");

            var momentDayOfBirth;

            if (response.dateOfBirth) {
              momentDayOfBirth = new _moment.default(response.dateOfBirth, 'YYYY/MM/DD').toDate();
            }

            changeset.setProperties({
              comtecClientId: response.comtecClientNumber,
              firstName: response.firstName,
              lastName: response.lastName,
              companyName: response.companyName,
              apartmentNumber: response.apartmentNumber,
              cityCodeName: response.cityCodeName,
              dateOfBirth: momentDayOfBirth,
              emailAddress: response.emailAddress,
              faxNumber: response.faxNumber,
              homeStreetNumber: response.homeStreetNumber,
              landlineTelNumber: response.landlineTelNumber,
              mobileTelNumber: response.mobileTelNumber,
              postCode: response.postCode,
              streetName: response.streetName,
              gender: Ember.get(_this3, 'genders').findBy('id', response.gender)
            });
            changeset.validate();

            /*
            if (response.gender) {
              set(changeset, 'gender', this.get('genders').findBy('id', response.gender));
            }
            */
            if (response.occupation) {
              _this3.get('store').findRecord('occupation', response.occupation).then(function (occupation) {
                Ember.set(_this3, '_changeset.occupation', occupation);
              });
            }
            if (response.street) {
              _this3.get('store').findRecord('street', response.street).then(function (street) {
                Ember.set(_this3, '_changeset.street', street);
              });
            }
            if (response.nationality) {
              _this3.get('store').findRecord('country', response.nationality).then(function (country) {
                Ember.set(_this3, '_changeset.nationality', country);
              });
            }
            _this3.set('_comtectClientInfoLock', true); //TO BE DEPRICATED
            Ember.set(_this3, '_clientDataFound', true);
          } else {
            Ember.set(_this3, '_changeset.comtecClientId', undefined);
            _this3.get('notifications').info("Client couldn't be located...");
            changeset.setProperties({
              comtecClientId: undefined,
              firstName: undefined,
              lastName: undefined,
              apartmentNumber: undefined,
              cityCodeName: undefined,
              dateOfBirth: undefined,
              emailAddress: undefined,
              faxNumber: undefined,
              homeStreetNumber: undefined,
              landlineTelNumber: undefined,
              mobileTelNumber: undefined,
              postCode: undefined,
              streetName: undefined,
              gender: undefined,
              occupation: undefined,
              street: undefined,
              nationality: undefined
            });

            _this3.set('_comtectClientInfoLock', false); //TO BE DEPRICATED
            Ember.set(_this3, '_clientDataFound', false);
          }
        }).catch(function (response) {
          console.log("Error caught", response);
          var changeset = Ember.get(_this3, '_changeset');
          Ember.set(_this3, '_changeset.comtecClientId', undefined);
          _this3.get('notifications').error('There was an error while looking up the client. ' + response.errors);

          changeset.setProperties({
            comtecClientId: undefined,
            firstName: undefined,
            lastName: undefined,
            apartmentNumber: undefined,
            cityCodeName: undefined,
            dateOfBirth: undefined,
            emailAddress: undefined,
            faxNumber: undefined,
            homeStreetNumber: undefined,
            landlineTelNumber: undefined,
            mobileTelNumber: undefined,
            postCode: undefined,
            streetName: undefined,
            gender: undefined,
            occupation: undefined,
            street: undefined,
            nationality: undefined
          });
          Ember.set(_this3, '_clientDataFound', false);
          _this3.set('_comtectClientInfoLock', false); //TO BE DEPRICATED
        }).finally(function () {
          _this3.set('_isComtecClientLoading', false); //TO BE DEPRICATED
          Ember.set(_this3, '_clientDataLoading', false);
          Ember.set(_this3, '_clientDataLoaded', true);
          if (Ember.get(_this3, 'onDataLoaded')) {
            Ember.get(_this3, 'onDataLoaded')(true);
          }
        });
      });
    },

    actions: {
      checkEnter: function checkEnter(followUpEvent, e) {
        if (e.key === 'Enter') {
          this.get('actions.' + followUpEvent).call(this);
        }
      },
      firstNameChanged: function firstNameChanged(value, changeset) {
        changeset.set('firstName', value);
        changeset.set('companyName', "");
        changeset.validate();
      },
      lastNameChanged: function lastNameChanged(value, changeset) {
        changeset.set('lastName', value);
        changeset.set('companyName', "");
        changeset.validate();
      },
      companyNameChanged: function companyNameChanged(value, changeset) {
        changeset.set('companyName', value);
        changeset.set('lastName', "");
        changeset.set('firstName', "");
        changeset.validate();
      },
      loadClient: function loadClient() {
        var _this4 = this;

        var clientId = Ember.get(this, '_changeset.clientId');
        var clientIdType = Ember.get(this, '_changeset.clientIdType.id');
        if (!clientId || !clientIdType) {
          return;
        }
        var changeset = Ember.get(this, '_changeset');
        changeset.validate('clientId').then(function (result) {
          if (clientId.trim() !== clientId) {
            changeset.pushErrors('clientId', 'Client Id cannot have spaces');
            return;
          }
          if ((typeof result === 'undefined' ? 'undefined' : _typeof(result)) !== 'object') {
            //That means that an error message was not returned during validation
            _this4._fetchClientInformation(clientId.toUpperCase(), clientIdType);
          }
        });
      },
      clearClient: function clearClient() {
        Ember.get(this, '_changeset').setProperties({
          clientId: undefined,
          comtecClientId: undefined,
          firstName: undefined,
          lastName: undefined,
          apartmentNumber: undefined,
          cityCodeName: undefined,
          dateOfBirth: undefined,
          emailAddress: undefined,
          faxNumber: undefined,
          homeStreetNumber: undefined,
          landlineTelNumber: undefined,
          mobileTelNumber: undefined,
          postCode: undefined,
          streetName: undefined,
          gender: undefined
        });

        /*
        if (get(this,'endorsementMode')==true){
          this.changeset.set('lineOfBusiness', null) //Also clearing this, to force user to rechoose -> hence re initialize fields properly
        }
        */
        Ember.set(this, '_clientDataLoaded', false);
        Ember.set(this, '_clientDataFound', false);
        if (Ember.get(this, 'onDataLoaded')) {
          Ember.get(this, 'onDataLoaded')(false);
        }
      },
      landlineTelNumberChanged: function landlineTelNumberChanged(value, changeset) {
        changeset.set('landlineTelNumber', value);
        changeset.validate();
      },
      mobileTelNumberChanged: function mobileTelNumberChanged(value, changeset) {
        changeset.set('mobileTelNumber', value);
        changeset.validate();
      }
    }
  });
});