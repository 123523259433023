define('minerva-frontend/components/users/user-profile-form/component', ['exports', 'minerva-frontend/validations/user-profile', 'minerva-frontend/validations/user-profile-with-password', 'ember-changeset-validations', 'ember-changeset'], function (exports, _userProfile, _userProfileWithPassword, _emberChangesetValidations, _emberChangeset) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    paramStore: Ember.inject.service(),
    availableRoles: undefined,
    updateMode: false, //Seems depricated. Not used anywhere for now
    createMode: false,
    submitLabel: Ember.computed('updateMode', function () {
      if (this.get('updateMode')) {
        return 'Update';
      } else {
        return 'Save';
      }
    }),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      var rolesPromise = this.get('store').findAll('params/role').then(function (roles) {
        _this.set('availableRoles', roles);
      });
      var districtPromise = this.get('store').findAll('params/district').then(function (districts) {
        _this.set('districts', districts);
      });
      var paramStore = this.get('paramStore');
      var allowedCovernoteLobIDs = ['PRIVATE', 'COMMERCIAL', 'MOTORCYCLE', 'SPECIAL_TYPE', 'PRIVATE_BUS'];
      var allowedProposalLobIds = ['EMPLOYER_LIABILITY_AND_MEDICAL_HELPER', 'EMPLOYER_LIABILITY_HELPER', 'MEDICAL_HELPER', 'MI_HEALTH', 'MI_ACCIDENT', 'MEDICAL_VISITOR', 'MI_PLACE']; //, 'SPECIAL_TYPE','PRIVATE_BUS',Temporary hack, should use this as a parameter eventually

      var paramsPromise = paramStore.getParams().then(function (params) {
        var businessLines = Ember.get(params, 'business-lines');

        _this.set('availableCovernoteLobs', businessLines.filter(function (lob) {
          return allowedCovernoteLobIDs.contains(lob.id);
        }));
        _this.set('availableEndorsementLobs', businessLines.filter(function (lob) {
          return allowedCovernoteLobIDs.contains(lob.id);
        }));
        _this.set('availableProposalLobs', businessLines.filter(function (lob) {
          return allowedProposalLobIds.contains(lob.id);
        }));
      });

      Ember.RSVP.all([rolesPromise, districtPromise, paramsPromise]).then(function (promises) {
        var model = _this.get('model');
        if (_this.get('createMode')) {
          _this.set('changeset', new _emberChangeset.default(model), (0, _emberChangesetValidations.default)(_userProfileWithPassword.default), _userProfileWithPassword.default);
        } else {
          _this.set('changeset', new _emberChangeset.default(model), (0, _emberChangesetValidations.default)(_userProfile.default), _userProfile.default);
        }
      });
    },

    actions: {
      submit: function submit(model) {
        var _this2 = this;

        var successMsg, failMsg;
        if (this.get('createMode')) {
          successMsg = "User was created successfully";
          failMsg = "There was a problem creating the user. ";
        } else {
          successMsg = "User was updated successfully";
          failMsg = "There was a problem updating the user. ";
        }
        if (this.get('createMode')) {
          return model.save().then(function (user) {
            _this2.get('notifications').success(successMsg);
            _this2.get('router').transitionTo('user', user.get('id'));
          }).catch(function (response) {
            _this2.get('notifications').error(failMsg + response.errors);
          });
        } else {
          return model.save().then(function (obj) {
            _this2.get('notifications').success(successMsg);
          }).catch(function (response) {
            _this2.get('notifications').error(failMsg + response.errors);
          });
        }
      }
    }
  });
});