define('minerva-frontend/validations/reason-forced-approval', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    reason: [(0, _validators.validateLength)({ min: 3, allowBlank: true })],
    confirmation: [(0, _validators.validateInclusion)({
      list: [true],
      message: 'Please confirm the submission of this action!'
    })]

  };
});