define('minerva-frontend/helpers/values', ['exports', 'ember-composable-helpers/helpers/values'], function (exports, _values) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _values.default;
    }
  });
  Object.defineProperty(exports, 'values', {
    enumerable: true,
    get: function () {
      return _values.values;
    }
  });
});