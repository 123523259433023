define('minerva-frontend/components/generic-forms/motor/filter-params-by-lob/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    filteredVehicleCategories: undefined,
    filteredBodyTypes: undefined,
    filteredCoverTypes: undefined,
    //We should pass these down to the sublob forms. Until these are out of the db,
    //we need to get the values prior initializing a lob-specific form
    init: function init() {
      this._super.apply(this, arguments);

      this.set('filteredVehicleCategories', A([]));
      this.set('filteredBodyTypes', A([]));
      this.set('filteredPackages', A([]));
      this.set('filteredCoverTypes', A([]));

      var lobId = get(this, 'selectedLob.id'); //value;
      //FILTER VEHICLE CATEGORIES
      var lobVehicleCategories = get(this, 'vehicleCategories');
      if (lobVehicleCategories) {
        set(this, 'filteredVehicleCategories', lobVehicleCategories.filterBy('lob', lobId));
      }
      //FILTER BODY TYPES
      var lobBodyTypes = get(this, 'bodyTypes');
      if (lobBodyTypes) {
        set(this, 'filteredBodyTypes', lobBodyTypes.filterBy('lob', lobId)); //Before that these were models, we needed lob.content.id. now that are plain json, we check them like this
      }

      var coverTypes = this.get('coverTypes');
      if (coverTypes) {
        if (lobId == 'PRIVATE' || lobId == 'COMMERCIAL') {
          this.set('filteredCoverTypes', coverTypes); //LOB11 supports all of them
        } else {
          this.set('filteredCoverTypes', coverTypes.filterBy('id', 'THIRD_PARTY')); //LOB11 supports all of them
        }
      }
    }
  });
});