define('minerva-frontend/controllers/proposal/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    currentUser: Ember.inject.service(),
    allowedLobs: undefined,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('allowedLobs', ['PRIVATE', 'COMMERCIAL', 'MOTORCYCLE', 'SPECIAL_TYPE', 'PRIVATE_BUS']);
    }
  });
});