define('minerva-frontend/services/param-store', ['exports', 'minerva-frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    params: undefined,
    getParams: function getParams() {
      if (this.get('params')) {
        return this.get('params');
      }
      var url = _environment.default.APP.apiUrl + "/params/all/";
      var promise;

      var headers = {};
      this.get('session').authorize('authorizer:jwt', function (headerName, headerValue) {
        headers[headerName] = headerValue;
      });
      promise = this.get('ajax').request(url, {
        method: 'GET',
        headers: headers
      }).then(function (response) {
        //console.log(json);
        return response;
      }).catch(function (reject) {
        return reject;
      });
      this.set('params', promise);
      return promise;
    },
    getConfig: function getConfig() {
      if (this.get('config')) {
        return this.get('config');
      }
      var url = _environment.default.APP.apiUrl + "/params/globals/";
      var promise;
      var headers = {};
      this.get('session').authorize('authorizer:jwt', function (headerName, headerValue) {
        headers[headerName] = headerValue;
      });
      promise = this.get('ajax').request(url, {
        method: 'GET',
        headers: headers
      }).then(function (response) {
        if (response.paramValue) {
          //console.log(json.paramValue);
          return response.paramValue;
        }
        return response;
      }).catch(function (reject) {
        return reject;
      });
      this.set('config', promise);
      return promise;
    }
  });
});