define('minerva-frontend/validators/generic/date-before', ['exports', 'ember-changeset-validations/utils/validation-errors', 'ember-validators', 'moment'], function (exports, _validationErrors, _emberValidators, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = validateDate;
  function validateDate(options) {
    return function (key, value, oldValue, changes, content) {
      var beforeDate = undefined;

      if (!options.field) {
        beforeDate = (0, _moment.default)();
      } else {
        var relatedFieldValue = Ember.get(changes, options.field) || Ember.get(content, options.field);
        if (relatedFieldValue) {
          beforeDate = (0, _moment.default)(relatedFieldValue);
        } else {
          return true;
        }
      }

      if (options.years) {
        beforeDate = beforeDate.subtract(options.years, 'years');
      }
      if (options.months) {
        beforeDate = beforeDate.subtract(options.months, 'months');
      }
      if (options.days) {
        beforeDate = beforeDate.subtract(options.days, 'days');
      }
      if (options.hours) {
        beforeDate = beforeDate.subtract(options.hours, 'hours');
      }
      if (options.minutes) {
        beforeDate = beforeDate.subtract(options.minutes, 'minutes');
      }
      beforeDate.set({ 'second': 59 });
      beforeDate = beforeDate.toISOString();

      if (beforeDate) {
        options.onOrBefore = beforeDate;
        options.format = 'YYYY-MM-DDTHH:mm:ssZ';
        options.errorFormat = 'YYYY-MM-DD HH:mm';
      }
      var result = (0, _emberValidators.validate)('date', value, options, null, key);
      return result === true ? true : (0, _validationErrors.default)(key, result);
    };
  }
});