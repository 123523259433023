define('minerva-frontend/helpers/check-visibility', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.checkVisibility = checkVisibility;
  function checkVisibility(params /*, hash*/) {
    return params;
  }

  exports.default = Ember.Helper.helper(checkVisibility);
});