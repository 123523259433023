define('minerva-frontend/components/generic-forms/proposal-form-wrapper/component', ['exports', 'minerva-frontend/models/covernote', 'minerva-frontend/models/minerva-document', 'minerva-frontend/models/proposal'], function (exports, _covernote, _minervaDocument, _proposal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    router: Ember.inject.service(),
    paramStore: Ember.inject.service(),

    classNames: "large-form-container",

    //Parameters for making this work both for covernotes and proposals

    modelClass: '', //e.g covernote, or proposal
    detailRoute: '', //e.g covernotes.covernote, proposals.proposal

    selectedLobID: undefined,
    selectedLob: undefined,
    initialClient: undefined,

    model: undefined,
    _comtecErrors: undefined,
    covernoteMode: Ember.computed.equal('modelClass', 'covernote'),
    proposalMode: Ember.computed.equal('modelClass', 'proposal'),
    endorsementMode: false, //Needs to be passed from the top.
    stateInitializers: undefined,
    _lineOfBusinessLock: false,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      var paramStore = this.get('paramStore');

      var stateInitializers = this.get('stateInitializers'); //Should be passed when initializing the object
      /*
      This object, is used as a way to pass top-down anything that is needed to initialize the state
      of the components in the component tree, in an agnostic way. The available states that we need to keep in mind are:
       - COVERNOTE_TO_PROPOSAL | New/Draft Proposal initiating from a related Covernote
      - CONTRACT_TO_ENDORSEMENT | New Endorsement initiating from a related contract (all endorsements fall under this category)
      - ...
      Depending on the state, each component might need to initiate data loading, model configuration or data locks
      Each component in the document-creation-flow should pass this object from top to down, while each component should be responsible
      for initializing its' state as it deems fit.
        Example initializers:
       {
        state: 'COVERNOTE_TO_PROPOSAL' //Required!
        values: {
          //any needed values that accompany the state. for a COVERNOTE_TO_PROPOSAL, we could have the relatedCovernote
          relatedCovernote: obj,
        }
       }
       NOTE: covernoteMode || endorsementMode || proposalMode, are use purely to control
      field visibility, and not for initializing state of the components, as it is very hard to locate the logic (aside of which data you should use)
      if it's spread around the various templates
       */
      var initState = stateInitializers ? stateInitializers.state : false;
      if (initState) {
        if (initState === 'COVERNOTE_TO_PROPOSAL') {
          this.set('_lineOfBusinessLock', true);
        } else if (initState === 'CONTRACT_TO_ENDORSEMENT') {
          this.set('_lineOfBusinessLock', true);
        } else if (initState === 'COVERNOTE_FINALIZATION') {
          this.set('_lineOfBusinessLock', true);
        }
      }

      paramStore.getParams().then(function (params) {
        Ember.set(_this, 'params', params);

        //First set the lob to the preselected lob (Eventually you will choose the lob yourself)
        var selectedLobID = Ember.get(_this, 'selectedLobID');
        if (selectedLobID) {
          var selectedLob = Ember.get(params, 'business-lines').findBy('id', selectedLobID);
          Ember.set(_this, 'selectedLob', selectedLob);
        }
      });
    },


    lobSpecificFormWrapper: Ember.computed('selectedLob.id', function () {
      var selectedLobID = Ember.get(this, 'selectedLob.id');
      switch (selectedLobID) {
        case 'PRIVATE':
        case 'COMMERCIAL':
        case 'MOTORCYCLE':
        case 'SPECIAL_TYPE':
        case 'PRIVATE_BUS':
          return 'motor-form';
        case 'EMPLOYER_LIABILITY_HELPER':
          return 'el-helper-form';
        case 'MEDICAL_HELPER':
          return 'm-helper-form';
        case 'EMPLOYER_LIABILITY_AND_MEDICAL_HELPER':
          return 'elnm-helper-form';
        case 'MI_HEALTH':
          return 'mi-health-form';
        case 'MI_ACCIDENT':
          return 'mi-accident-form';
        case 'MEDICAL_VISITOR':
          return 'm-visitor-form';
        case 'MI_PLACE':
          return 'mi-place-form';
        default:
          return null;
      }
    }),
    actions: {
      lobSelected: function lobSelected(lob) {
        //Lob has changed. So we re-initialize our whole changeset, along with any initialization logic that needs to happen
        //with the new lob

        /*
        if (!lob) {
          set(this, '_clientDataLocked', false);
          set(this, '_genericDataInfoLocked', false);
        }
        */
        Ember.set(this, 'selectedLob', lob);
      },
      goback: function goback() {
        if (confirm("Are you sure you want to cancel?")) {
          this.get('router').transitionTo('index');
          //history.back();
        }
      }
    }
    /*
      proposalMode: not('covernoteMode'),
    endorsementMode: false, //Endorsement mode happens when we have a covernoteMode but creating an endorsement
     //Dropdown params aliases and cp for use in template
      //Computed properties to show/hide fields depending on lob
    selectedLobId: oneWay('changeset.lineOfBusiness.id'),
    _lobSelected: false,
      isSubmitting: false,
     //--------------------------------------------
     preInitialized: false,
    clientDataLocked: undefined,
    clientChangeLocked: undefined,
     coverInfoLocked: undefined,
    _coverInfoLocked: computed('preInitialized', 'coverInfoLocked', function () {
      if (this.get('coverInfoLocked') === undefined) {
        return this.get('preInitialized');
      } else {
        return this.get('coverInfoLocked');
      }
    }),
    
     _initParams() {
      if (get(this, 'endorsementMode')) {
        this.set('documentTypes', A([
          {id: 'STANDARD_ENDORSEMENT', label: "Standard endorsement"},
          {id: 'DEFINITE_ENDORSEMENT', label: "Definite endorsement"},
        ]));
      }
      const paramStore = this.get('paramStore');
      return paramStore.getParams().then((params) => {
        set(this, 'params', params);
      });
    },
    _initModel(model) {
      var changeset;
      if (get(this, 'endorsementMode')) {
        changeset = new Changeset(model, lookupValidator(EndorsementValidations), EndorsementValidations);
      } else if (get(this, 'covernoteMode')) {
        changeset = new Changeset(model, lookupValidator(CoverNoteValidations), CoverNoteValidations);
      } else {
        changeset = new Changeset(model, lookupValidator(ProposalValidations), ProposalValidations);
      }
      return changeset;
    },
     _initChangeset(model, changeset) {
       const isModelPreinitialized = !model.get('isNew') || get(this, 'preInitialized');
        //set default values from database
      var backendConfig = this.get('backendConfig');
      if (isModelPreinitialized) {
        //all enums are currently in the form of objects inside the model (they come embedded)
        //we need to extract them, and set the changeset values to their IDs instead!
        //Minerva Document Enums
         if (model.get('lineOfBusiness')) {
          const lineOfBusiness = model.get('lineOfBusiness');
          //changeset.set('lineOfBusiness', lineOfBusiness);
          this.get('actions').lobChanged.call(this, lineOfBusiness, changeset);
        }
      }
      return changeset;
     },
    _init() {
      let model = this.get('model');
      this.get('paramStore').getConfig().then(backendConfig => {
        set(this, 'backendConfig', backendConfig);
        var changeset = this._initModel(model);
        this._initChangeset(model, changeset);
        set(this, 'changeset', changeset);
      });
      },
    comtecErrors: undefined,
    init() {
      this._super(...arguments);
      this._initParams().then(() => {
        this._init();
      });
    },
     isFormInvalid: alias('changeset.isInvalid'),
    
     actions: {
      checkEnter(followUpEvent, e) {
        if (e.key === 'Enter') {
          this.get(`actions.${followUpEvent}`).call(this);
        }
      },
      goback() {
        if (confirm("Are you sure you want to cancel?")) {
          this.get('router').transitionTo('index');
          //history.back();
        }
      },
      lobChanged(value, changeset) {
         changeset.set('lineOfBusiness', value);
        if (!value) {
          this.set('_lobSelected', false);
          return;
          //means an empty lob was selected. So clear the coverType
        }
        var lobId = get(value, 'id'); //value;
        this.set('_lobSelected', true);
      },
      emptySubmit() {
        console.log("doing nothing...");
        return false;
      },
      interimSubmit(changeset, mode) {
        changeset.validate();
        if (changeset.get('isValid')) {
          if (confirm("Are you sure you want to submit the form?")) {
            this.get('actions').submit.call(this, changeset, mode);
          }
        }
      },
      submit(changeset, mode) {
         if (!mode) {
          mode = 'FINAL';
        }
        var isFinal = false;
        if (mode == 'FINAL') {
          isFinal = true;
        }
        if (isFinal) {
          changeset.validate();
          if (changeset.get('isInvalid')) {
            return;
          }
        }
         //We check if drivers are also valid. If they are, we proceed to save the covernote
        //Exception is when we are saving as draft. Screw the validation then
         set(this, 'isSubmitting', true);
          if (isFinal) {
          changeset.set('submissionStatus', 'FINAL');
        } else {
          changeset.set('submissionStatus', 'DRAFT');
        }
         var hasCustomStartEndDate = false;
        if (!changeset.get('customStartEndDate')) {
          //means we should apply the default
          hasCustomStartEndDate = true;
          var times = this._getDefaultStartEndDateTimes();
          changeset.set('startDateTime', times.startDateTime);
          changeset.set('endDateTime', times.endDateTime);
        }
        var allKeysSchema;
        if (this.get('covernoteMode')) {
          allKeysSchema = Object.assign({}, documentSchema, covernoteSchema);
        } else {
          allKeysSchema = Object.assign({}, documentSchema, proposalSchema);
        }
          if (isFinal) {
           return changeset.cast(keys(allKeysSchema)).prepare((changes) => {
            return changes;
          }).save().then((obj) => {
            //console.log("changeset was saved successfully");
            //console.log("Now transitioning to route:", this.get('detailRoute'), obj.get('id'));
            this.get('notifications').success("Document was saved successfully");
            this.get('router').replaceWith(this.get('detailRoute'), obj.get('id'));
          }).catch((response) => {
            this.set('comtecErrors', response.errors);
            this.get('notifications').error(`There was an error while trying to save the document. ${response.errors}`);
            if (hasCustomStartEndDate) {
              changeset.set('customStartEndDate', true);
            }
            set(this, 'isSubmitting', false);
          });
        } else {
          let model = this.get('model');
          keys(allKeysSchema).forEach((key) => {
            model.set(key, changeset.get(key)); //Copies unvalidated values over to the model. Ouch!
          });
            return model.save().then((obj) => {
            this.get('notifications').success("Document was saved successfully as a draft");
            this.get('router').replaceWith(this.get('detailRoute'), obj.get('id'));
          }).catch((response) => {
            this.get('notifications').error(`There was an error while trying to save the document. ${response.errors}`);
            set(this, 'isSubmitting', false);
            if (hasCustomStartEndDate) {
              changeset.set('customStartEndDate', true);
            }
           });
        }
      }
     }
    */
  });
});