define('minerva-frontend/helpers/get-belongsto-id', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getBelongstoId = getBelongstoId;
  function getBelongstoId(params /*, hash*/) {

    var obj = params[0];
    var param = params[1];
    var belongsToVal = obj.belongsTo(param).id();
    return belongsToVal;
  }

  exports.default = Ember.Helper.helper(getBelongstoId);
});