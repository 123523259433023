define('minerva-frontend/routes/index', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    afterModel: function afterModel() {
      var _this = this;

      var emptyCovernote1 = this.get('store').createRecord('covernote');
      var emptyCovernote2 = this.get('store').createRecord('covernote');
      //this.controller.set('loadingLateProposalCovernotes', true);
      //this.controller.set('loadingMissingDocumentsCovernotes', true);
      emptyCovernote1.fetchCovernotesWithLateProposals({ sort: 'documentDateTime,desc' }).then(function (response) {
        var covernotes;
        covernotes = _this.get('store').pushPayload('covernote', response);
        _this.controller.set('lateProposalCovernotes', covernotes);
        _this.controller.set('loadingLateProposalCovernotes', false);
      }).finally(function () {
        emptyCovernote1.deleteRecord();
      });
      emptyCovernote2.fetchCovernotesWithoutDocuments({ sort: 'documentDateTime,desc' }).then(function (response) {
        _this.controller.set('missingDocumentsCovernotes', _this.get('store').pushPayload('covernote', response));
        _this.controller.set('loadingMissingDocumentsCovernotes', false);
      }).finally(function () {
        emptyCovernote2.deleteRecord();
      });
    }
  });
});