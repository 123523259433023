define('minerva-frontend/components/lob-forms/motorcycle-proposal/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    filteredCoverTypes: Ember.computed.filterBy('params.cover-types', 'id', 'THIRD_PARTY'),
    filteredVehicleCategories: Ember.computed.filterBy('params.vehicle-categories', 'lob', 'MOTORCYCLE'),
    filteredBodyTypes: Ember.computed.filterBy('params.body-types', 'lob', 'MOTORCYCLE'),
    filteredPackages: Ember.computed.filterBy('params.packages', 'lob', 'MOTORCYCLE'),
    coverFilteredPackages: undefined,
    filteredDriverTypes: Ember.computed.filter('params.driver-types', function (driverType, index, array) {
      var user = this.get('currentUser.user');
      var isUnderwriter = user.get("isHeadUnderwriter") || user.get("isUnderwriter");
      if (!isUnderwriter && driverType.id === 'ANY_DRIVER') {
        return false; //Block ANY DRIVER for non admins
      }
      var found = false;
      Ember.get(driverType, 'applicableCoverTypes').forEach(function (covertype) {
        if (covertype.id === 'THIRD_PARTY') {
          found = true;
        }
      });
      return found;
    }),
    isClientCompany: Ember.computed.equal('clientIdType', 'COMPANY_REGISTRATION'),
    init: function init() {
      this._super.apply(this, arguments);
      var thirdParty = this.get('params.cover-types').findBy('id', 'THIRD_PARTY');
      Ember.set(this, '_changeset.coverType', thirdParty);

      var stateInitializers = this.get('stateInitializers'); //Should be passed when initializing the object
      var initState = stateInitializers ? stateInitializers.state : false;
      if (initState) {
        //Means car is already preloaded!
        if (initState === 'COVERNOTE_TO_PROPOSAL') {
          Ember.set(this, '_coverInfoLocked', true);
          Ember.set(this, '_specialTermsLocked', true);
          Ember.set(this, '_driversLocked', true);
        } else if (initState === 'CONTRACT_TO_ENDORSEMENT') {
          Ember.set(this, '_coverInfoLocked', true);
          Ember.set(this, '_driverTypeLocked', true);
          Ember.set(this, '_additionalCoversLocked', true);
        }
      }
    },

    actions: {
      emptySubmit: function emptySubmit() {},
      submit: function submit(model, submissionStatus) {
        this.onSubmit(model, submissionStatus); //Let it propagate to motor lob-form-wrapper to handle instead. no custom logic here
      }
    }
  });
});