define('minerva-frontend/components/helpers/date-time-input/component', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    inputClassNames: '',
    errorClassNames: '',

    labelTime: "Time",
    hasTime: false,
    timeDisabled: false,
    value: undefined,
    defaultTime: "",
    calcedTime: Ember.computed('value', function () {
      var value = this.get('value');
      if (!value) {
        return "";
      }
      var datetimeFormatted = (0, _moment.default)(value).format("HH:mm");
      return datetimeFormatted;
    }),
    minValue: undefined,
    maxValue: undefined,

    onupdate: undefined,
    _maskType: Ember.computed('timeDisabled', 'hasTime', function () {
      if (this.get('hasTime') && this.get('timeDisabled')) {
        return 'date';
      } else if (this.get('hasTime')) {
        return 'datetime';
      } else {
        return 'date';
      }
    }),
    _valueFormatted: Ember.computed('value', function () {

      //value is assigned from outer element, and is a JS Date. From that,
      //we should output the formatted output to use in the element
      var value = this.get('value');
      if (!value) {
        return "";
      }
      var datetimeFormatted = (0, _moment.default)(value).format(this.get('momentFormat'));
      return datetimeFormatted;
    }),
    _valueUnformatted: "",
    momentFormat: Ember.computed('hasTime', 'timeDisabled', function () {
      if (this.get('hasTime')) {
        return "DD/MM/YYYY HH:mm";
      } else {
        return "DD/MM/YYYY";
      }
    }),
    valueParseFormat: Ember.computed('hasTime', 'timeDisabled', function () {
      if (this.get('hasTime') && !this.get('timeDisabled')) {
        return "DD/MM/YYYY HH:mm";
      } else {
        return "DD/MM/YYYY";
      }
    }),

    actions: {
      dateOrTimeChanged: function dateOrTimeChanged(valueUnformatted, valueFormatted) {
        var value = valueFormatted;
        if (!value) {
          return;
        }
        var format = this.get('momentFormat');
        if (this.get('hasTime') && this.get('timeDisabled') && this.get('defaultTime')) {
          value = value + " " + this.get('defaultTime');
        }

        if ((0, _moment.default)(value, format, true).isValid()) {
          var datetimeMoment = (0, _moment.default)(value, format);
          var datetime = datetimeMoment.toDate();
          if (this.get('onupdate')) {
            this.get('onupdate')(datetime);
          }
        } else {
          if (this.get('onupdate')) {
            this.get('onupdate')(undefined);
          }
        }
      },
      focusOut: function focusOut() {
        if (this.get('onFocusOut')) {
          this.get('onFocusOut')();
        }
      }
    }

  });
});