define("minerva-frontend/validations/driver", ["exports", "ember-changeset-validations/validators", "minerva-frontend/validators/special/driver-birthday", "minerva-frontend/validators/special/driver-license-date"], function (exports, _validators, _driverBirthday, _driverLicenseDate) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    driverName: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ min: 4, max: 30 })],
    driverId: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ integer: true }), (0, _validators.validateLength)({ min: 4, max: 8 })],
    dateOfBirth: [(0, _validators.validatePresence)(true), (0, _driverBirthday.default)({
      message: '{description} must be greater than {age} years old'
    })],
    licenseDate: [(0, _validators.validatePresence)(true), (0, _driverLicenseDate.default)({
      birthdayField: 'dateOfBirth',
      learnerField: 'learner'
    })],
    excessAmount: (0, _validators.validateNumber)({ positive: true, integer: true, allowBlank: true })
  };
});