define('minerva-frontend/components/profile/user-form/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      submit: function submit(model) {
        return model.save({ adapterOptions: { me: true } });
      }
    }
  });
});