define('minerva-frontend/validations/standard-endorsements/document-generic-info', ['exports', 'ember-changeset-validations/validators', 'minerva-frontend/validators/generic/date-before', 'minerva-frontend/validators/generic/date-after', 'ember-changeset-conditional-validations/validators/sometimes'], function (exports, _validators, _dateBefore, _dateAfter, _sometimes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    startDateTime: (0, _sometimes.default)([(0, _validators.validatePresence)(true), (0, _dateAfter.default)({
      minutes: -30,
      message: '{description} cannot start earlier than now'
    }), (0, _dateBefore.default)({
      field: 'endDateTime',
      message: '{description} cannot be after end date'
    })], function (changes, content) {
      return Ember.get(changes, 'customStartEndDate') == true;
    })
    //endDateTime : Cannot be changed, and should be exactly the same as the endTime of the base contract. Only start date can changed
  };
});