define('minerva-frontend/validations/covernote-cancellation-request', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    cancellationReason: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ min: 3 })],
    cancellationRequestConfirmation: [(0, _validators.validateInclusion)({
      list: [true],
      message: 'Please confirm the submission of this cancellation request!'
    })]

  };
});