define("minerva-frontend/validations/owner-driver", ["exports", "ember-changeset-validations/validators", "minerva-frontend/validators/special/driver-birthday", "minerva-frontend/validators/special/driver-license-date"], function (exports, _validators, _driverBirthday, _driverLicenseDate) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    /*
    dateOfBirth: [
      validatePresence(true),
      validateDriverBirthday({
        message: '{description} must be greater than {age} years old'
      })
    ],
    licenseDate: [
      validatePresence(true),
      validateDriverLicenseDate({
        birthdayField: 'dateOfBirth',
        learnerField: 'learner'
      })
    ],
    */
    excessAmount: (0, _validators.validateNumber)({ positive: true, integer: true, allowBlank: true })
  };
});