define('minerva-frontend/serializers/user', ['exports', 'minerva-frontend/serializers/application', 'ember-data-change-tracker/mixins/keep-only-changed'], function (exports, _application, _keepOnlyChanged) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_keepOnlyChanged.default, {
    primaryKey: 'username',
    modelClassName: 'user'
  });
});