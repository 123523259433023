define('minerva-frontend/validations/client', ['exports', 'ember-changeset-validations/validators', 'minerva-frontend/validators/generic/date-before', 'ember-changeset-conditional-validations/validators/sometimes'], function (exports, _validators, _dateBefore, _sometimes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = {
    clientIdType: (0, _validators.validatePresence)(true),
    //clientId: [validatePresence(true),validateNumber({integer:true})],

    clientId: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ min: 4, max: 20 })].concat(_toConsumableArray((0, _sometimes.default)([(0, _validators.validateNumber)({ integer: true })], function (changes, content) {
      return Ember.get(changes, 'clientIdType.id') === 'ID_CARD' || Ember.get(content, 'clientIdType.id') === 'ID_CARD';
    }))),

    firstName: (0, _sometimes.default)([(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ min: 2, max: 20 })
    //Add validate format for chassis number
    ], function (changes, content) {
      return !(Ember.get(changes, 'clientIdType.id') === 'COMPANY_REGISTRATION' || Ember.get(content, 'clientIdType.id') === 'COMPANY_REGISTRATION');
    }),
    lastName: (0, _sometimes.default)([(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ min: 2, max: 20 })
    //Add validate format for chassis number
    ], function (changes, content) {
      return !(Ember.get(changes, 'clientIdType.id') === 'COMPANY_REGISTRATION' || Ember.get(content, 'clientIdType.id') === 'COMPANY_REGISTRATION');
    }),
    companyName: (0, _sometimes.default)([(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ min: 1, max: 40 })], function (changes, content) {
      return Ember.get(changes, 'clientIdType.id') === 'COMPANY_REGISTRATION' || Ember.get(content, 'clientIdType.id') === 'COMPANY_REGISTRATION';
    }),
    email: (0, _validators.validateFormat)({ type: 'email', allowBlank: true }),
    dateOfBirth: (0, _sometimes.default)([(0, _validators.validatePresence)(true), (0, _dateBefore.default)({
      years: 18,
      message: '{description} must be greater than 18 years old'
    })
    //Add validate format for chassis number
    ], function (changes, content) {
      return !(Ember.get(changes, 'clientIdType.id') === 'COMPANY_REGISTRATION' || Ember.get(content, 'clientIdType.id') === 'COMPANY_REGISTRATION');
    }),
    nationality: (0, _sometimes.default)([(0, _validators.validatePresence)(true)
    //Add validate format for chassis number
    ], function (changes, content) {
      return !(Ember.get(changes, 'clientIdType.id') === 'COMPANY_REGISTRATION' || Ember.get(content, 'clientIdType.id') === 'COMPANY_REGISTRATION');
    }),
    occupation: (0, _validators.validatePresence)(true),

    postCode: (0, _validators.validatePresence)(true),
    homeStreetNumber: (0, _validators.validatePresence)(true),

    landlineTelNumber: (0, _sometimes.default)([(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ integer: true })], function (changes, content) {
      return !(Ember.get(changes, 'mobileTelNumber') || Ember.get(content, 'mobileTelNumber'));
    }),
    mobileTelNumber: (0, _sometimes.default)([(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ integer: true })], function (changes, content) {
      return !(Ember.get(changes, 'landlineTelNumber') || Ember.get(content, 'landlineTelNumber'));
    })
  };
});