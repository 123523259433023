define('minerva-frontend/routes/proposal/new', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'minerva-frontend/models/minerva-document'], function (exports, _authenticatedRouteMixin, _minervaDocument) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var keys = Object.keys;
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    currentUser: Ember.inject.service(),
    beforeModel: function beforeModel() {
      if (!this.get('currentUser.user.canIssueProposal')) {
        return this.transitionTo('index');
      }
    },

    queryParams: {
      covernote: {
        refreshModel: true
      },
      lob: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var _this = this;

      if (params.covernote) {
        if (this.get('currentUser.user.migratedToNewVersion')) {
          return this.get('store').findRecord('covernote', params.covernote).then(function (covernote) {
            //let covernoteProperties = covernote.toJSON({includeId: false});
            //ADD HERE CHECK TO NOT ALLOW TO DO THIS IF COVERNOTE IS DRAFT?

            var stateInitializers = {
              state: 'COVERNOTE_TO_PROPOSAL',
              values: {
                covernote: covernote
              }
            };

            var proposalInitProperties = {};
            keys(_minervaDocument.documentSchema).forEach(function (key) {
              proposalInitProperties[key] = Ember.get(covernote, key);
            });
            //Remove covernote properties that are server generated
            delete proposalInitProperties.state;
            //delete proposalInitProperties.owner;
            delete proposalInitProperties.documentNumber;
            delete proposalInitProperties.documentType;
            delete proposalInitProperties.documentDateTime;
            delete proposalInitProperties.pdfLinks;

            //Create proposal
            var newProposal = _this.get('store').createRecord('proposal', proposalInitProperties);

            //Set BelongsTo rels
            newProposal.set('nationality', covernote.get('nationality'));
            newProposal.set('occupation', covernote.get('occupation'));
            newProposal.set('street', covernote.get('street'));
            newProposal.set('vehicleMake', covernote.get('vehicleMake'));

            //Set related covernote
            newProposal.set('relatedCovernote', covernote);
            //newProposal.set('covernoteNumber', covernote.get('documentNumber'));
            newProposal.set('documentType', 'PROPOSAL');
            var lineOfBusinessId = covernote.get('lineOfBusiness.id');
            return Ember.RSVP.hash({ proposal: newProposal, lineOfBusinessId: lineOfBusinessId, stateInitializers: stateInitializers });
          });
        } else {

          return this.get('store').findRecord('covernote', params.covernote).then(function (covernote) {
            //let covernoteProperties = covernote.toJSON({includeId: false});
            //ADD HERE CHECK TO NOT ALLOW TO DO THIS IF COVERNOTE IS DRAFT?
            var proposalInitProperties = {};
            keys(_minervaDocument.documentSchema).forEach(function (key) {
              proposalInitProperties[key] = Ember.get(covernote, key);
            });

            //Remove covernote properties that are server generated
            delete proposalInitProperties.state;
            //delete proposalInitProperties.owner;
            delete proposalInitProperties.documentNumber;
            delete proposalInitProperties.documentType;
            delete proposalInitProperties.documentDateTime;
            delete proposalInitProperties.pdfLinks;

            //Create proposal
            var newProposal = _this.get('store').createRecord('proposal', proposalInitProperties);

            //Set BelongsTo rels
            newProposal.set('nationality', covernote.get('nationality'));
            newProposal.set('occupation', covernote.get('occupation'));
            newProposal.set('street', covernote.get('street'));
            newProposal.set('vehicleMake', covernote.get('vehicleMake'));

            //Set related covernote
            newProposal.set('relatedCovernote', covernote);
            //newProposal.set('covernoteNumber', covernote.get('documentNumber'));
            newProposal.set('documentType', 'PROPOSAL');

            return Ember.RSVP.hash({ proposal: newProposal, isPreinitialized: true });
          });
        }
      } else {
        //We disabled new proposals without covernote! So redirect to home
        return this.transitionTo('index');
        //return hash({proposal:this.get('store').createRecord('proposal', {}), isPreinitialized:false});
      }
    },

    actions: {
      willTransition: function willTransition() {
        var model = this.controller.get('model.proposal');

        if (model.get('isNew')) {
          model.destroyRecord();
        }
      }
    }

  });
});