define('minerva-frontend/components/forms/proposal-form/component', ['exports', 'moment', 'minerva-frontend/validations/covernote', 'minerva-frontend/validations/proposal', 'minerva-frontend/validations/endorsement', 'minerva-frontend/validations/driver', 'ember-changeset-validations', 'ember-changeset', 'minerva-frontend/config/environment', 'minerva-frontend/models/covernote', 'minerva-frontend/models/minerva-document', 'minerva-frontend/models/proposal', 'npm:greek-utils'], function (exports, _moment, _covernote, _proposal, _endorsement, _driver, _emberChangesetValidations, _emberChangeset, _environment, _covernote2, _minervaDocument, _proposal2, _npmGreekUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var keys = Object.keys;


  var Driver = Ember.Object.extend({
    driverId: '',
    driverName: '',
    learner: false,
    dateOfBirth: '',
    excessAmount: null,
    licenseDate: '',
    foreignLicense: false,
    ownerDriver: false
  });
  exports.default = Ember.Component.extend({
    DriverValidations: _driver.default,
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    isSpecialTermsVisible: Ember.computed.alias('currentUser.user.allowSpecialTerms'),
    router: Ember.inject.service(),
    paramStore: Ember.inject.service(),
    isSubmitting: false,
    classNames: "large-form-container",

    //Parameters for making this work both for covernotes and proposals

    modelClass: '', //e.g covernote, or proposal
    detailRoute: '', //e.g covernotes.covernote, proposals.proposal
    covernoteMode: Ember.computed.equal('modelClass', 'covernote'),
    proposalMode: Ember.computed.equal('modelClass', 'proposal'),
    endorsementMode: false, //Endorsement mode happens when we have a covernoteMode but creating an endorsement

    //Dropdown params aliases and cp for use in template

    idTypes: Ember.computed.alias('params.id-types'),

    coverTypes: Ember.computed.alias('params.cover-types'), //check _availableCoverTypes below for the ones used in the template

    bodyTypes: Ember.computed.alias('params.body-types'),
    driverTypes: Ember.computed.alias('params.driver-types'),
    vehicleUseTypes: Ember.computed.alias('params.types-of-use'),

    genders: Ember.computed.alias('params.genders'),
    packages: Ember.computed.alias('params.packages'), //USED FOR PROPOSALS
    businessLines: Ember.computed('params.business-lines', function () {
      var allowedLobs = ['PRIVATE', 'COMMERCIAL', 'MOTORCYCLE', 'SPECIAL_TYPE', 'PRIVATE_BUS'];
      return this.get('params.business-lines').filter(function (lob) {
        return allowedLobs.contains(lob.id);
      });
    }),
    vehicleCategories: Ember.computed.alias('params.vehicle-categories'),
    vehicleTypes: Ember.computed.alias('params.vehicle-types'),
    vehicleSpecialTypes: Ember.computed.alias('params.vehicle-special-types'),
    policyInstallments: Ember.computed.alias('params.policy-installments'),
    noClaimsDiscounts: Ember.computed.alias('params.no-claim-discounts'),
    additionalExcesses: Ember.computed.alias('params.additional-excesses'),
    //Extra fields for making the form interactively adapt based on the input values
    isForeignStreet: false,
    additionalCoversLocked: false,
    //Drivers subsection
    drivers: null,
    driversCount: Ember.computed.alias('changeset.drivers.length'),

    /*
    driversCount: computed('changeset.ownerDriving', 'extraDriversCount', function () {
      var extra = 0;
      if (this.get('changeset.ownerDriving')) {
        extra = 1;
      }
      if (!this.get('extraDriversCount')) {
        return extra;
      } else {
        return this.get('extraDriversCount') + extra;
      }
    }),
    */
    ownerDrivingLicenseDate: "",
    ownerDrivingPolicyExcess: "",
    ownerLicenseIsForeign: false,
    canAddDrivers: Ember.computed.lt('driversCount', 6),

    cities: null, //Get loaded from combo-box directly
    documentTypes: null,
    _cityCodeNameFirstHalf: "",
    _cityCodeNameSecondHalf: "",

    //@Put this in the submit button logic!. If isCustomAddress then do this and apply the changes to the changeset that was passed to submit
    _cityCodeNameUpdated: Ember.observer('_cityCodeNameFirstHalf', '_cityCodeNameSecondHalf', function () {
      if (this.get('_cityCodeNameFirstHalf')) {
        this.set('changeset.cityCodeName', this.get('_cityCodeNameFirstHalf') + ', ' + this.get('_cityCodeNameSecondHalf'));
      } else {
        this.set('changeset.cityCodeName', '' + this.get('_cityCodeNameSecondHalf'));
      }
    }),
    possibleVehicleMake: '',

    //@Keep
    _startDateTimeIsFuture: Ember.computed('changeset.startDateTime', function () {
      var now = (0, _moment.default)();
      now.set({ 'hour': 23, 'minute': 59 });
      return this.get('changeset.startDateTime') > now.toDate();
    }),

    //Computed properties to show/hide fields depending on lob
    selectedLobId: Ember.computed.oneWay('changeset.lineOfBusiness.id'),
    _lobSelected: false,
    coverTypeSelected: false,
    selectedClientIdType: Ember.computed.oneWay('changeset.clientIdType.id'),

    isComprehensive: Ember.computed.equal('changeset.coverType.id', 'COMPREHENSIVE'),
    //Should clear out sum insured if they get hidden due to this.. .maybe before validating shit?

    //LOB Checks for showing hiding fields
    isCommercialLob: Ember.computed.equal('selectedLobId', 'COMMERCIAL'), //LOB12
    ////LOB Type related visibility checks


    isCoverPackageVisible: false, //alias('isPrivateLob'),
    isVehicleTypeVisible: false, //alias('isPrivateLob'),
    isVehicleSpecialTypeVisible: false, //alias('isSpecialTypeLob'),

    isNtalikaVisible: false, //and('isCommercialLob', 'isThirdParty'), //PROPOSAL
    isDrivingSchoolVisible: false, //and('isCommercialLob', 'isThirdParty'), //PROPOSAL
    isVehicleAntiqueVisible: false, //and('isCommercialLob', 'isThirdParty'), //PROPOSAL
    isOwnPackageVisible: false, //alias('isCommercialLob'), //PROPOSAL

    isNtalikaDisabled: false,
    isDrivingSchoolDisabled: false,
    isVehicleAntiqueDisabled: false,
    isOwnPackageDisabled: false,
    isConvertibleVisible: false, //alias('isPrivateOrCommercialLob'), //PROPOSAL
    isHighPerformanceVisible: false, //alias('isPrivateOrCommercialLob'), //PROPOSAL
    isLeftHandDriveVisible: false, //alias('isPrivateOrCommercialLob'), //PROPOSAL

    ////Drivers extra fields visibilities
    isYoungOrOldInOtherPolicyVisible: false, //or('isPrivateOrCommercialLob', 'isSpecialTypeLob'), //PROPOSAL
    isAdditionalExcessVisible: false, //and('isPrivateOrCommercialLob', 'isComprehensive'),
    isNoClaimsDiscountVisible: false, //and('isPrivateOrCommercialLob', 'isComprehensive'),
    ////Addditional covers visibilities
    isTrailerVisible: false, // or('isPrivateLob', 'isCommercialLob', 'isSpecialTypeLob'),
    isMalicousDamageCoverVisible: false, // and('isPrivateOrCommercialLob', 'isComprehensive'),
    isStormCoverVisible: false, // and('isPrivateOrCommercialLob', 'isComprehensive'),
    isNcdProtectionVisible: false, // and('isPrivateOrCommercialLob', 'isComprehensive'),
    isPrivateOrCommercialOrMotorcycleLob: false, // or('isPrivateOrCommercialLob', 'isMotorcycleLob'),
    isNclProtectionCoverVisible: false, // and('isPrivateOrCommercialOrMotorcycleLob', 'isThirdParty'),
    isOwnerDrivingOtherVehiclesVisible: false, // alias('isPrivateOrCommercialLob'),
    isLossOfUseVisible: false, // and('isPrivateOrCommercialLob', 'isComprehensive'),
    isPersonalAccidentVisible: false, // alias('isPrivateOrCommercialLob'),
    isWindscreenVisible: false, // alias('isPrivateOrCommercialLob'),
    isHailstormCoverVisible: false, // and('isPrivateOrCommercialLob', 'isComprehensive'),
    isGoodsInTransitCoverVisible: false, // or('isCommercialLob', 'isSpecialTypeLob'),
    isGoodsInTransitCoverAmountVisible: false, // or('isCommercialLob', 'isSpecialTypeLob'),
    isToolOfTradeCoverAmountVisible: false, // or('isCommercialLob', 'isSpecialTypeLob'),

    isPolicyExcessVisible: false, //and('covernoteMode', 'isComprehensive'),
    isPolicyExcessAutocalculated: Ember.computed.and('covernoteMode', 'isComprehensive'),
    areDriversExcessVisible: Ember.computed.alias('covernoteMode'),
    isSumInsuredVisible: false, //and('isPrivateOrCommercialLob', 'isNotThirdParty'),
    //isVehicleNumberOfSeats: not('isMotorcycleLob'), //OR we could just populate with 1?

    //Client Type Checks
    isClientCompany: Ember.computed.equal('selectedClientIdType', 'COMPANY_REGISTRATION'),
    ////Client Type related visibility checks
    isGenderVisible: Ember.computed.not('isClientCompany'),
    isNationalityVisible: Ember.computed.not('isClientCompany'),

    //--------------------------------------------

    preInitialized: false,
    clientDataLocked: undefined,
    clientChangeLocked: undefined,
    carDataLocked: undefined,
    carChangeLocked: undefined,
    _carDataLocked: Ember.computed('preInitialized', 'carDataLocked', function () {
      if (this.get('carDataLocked') === undefined) {
        return this.get('preInitialized');
      } else {
        return this.get('carDataLocked');
      }
    }),
    _carChangeLocked: Ember.computed('preInitialized', 'carDataLocked', 'carChangeLocked', function () {
      if (this.get('carChangeLocked') === undefined) {
        if (this.get('carDataLocked') === undefined) {
          return this.get('preInitialized');
        } else {
          return this.get('carDataLocked');
        }
      } else {
        return this.get('carChangeLocked');
      }
    }),
    _clientDataLocked: Ember.computed('preInitialized', 'clientDataLocked', function () {
      if (this.get('clientDataLocked') === undefined) {
        return this.get('preInitialized');
      } else {
        return this.get('clientDataLocked');
      }
    }),
    _clientChangeLocked: Ember.computed('preInitialized', 'clientChangeLocked', function () {
      if (this.get('clientChangeLocked') === undefined) {
        if (this.get('clientDataLocked') === undefined) {
          return this.get('preInitialized');
        } else {
          return this.get('clientDataLocked');
        }
      } else {
        return this.get('clientChangeLocked');
      }
    }),
    coverInfoLocked: undefined,
    _coverInfoLocked: Ember.computed('preInitialized', 'coverInfoLocked', function () {
      if (this.get('coverInfoLocked') === undefined) {
        return this.get('preInitialized');
      } else {
        return this.get('coverInfoLocked');
      }
    }),
    driversLocked: undefined,
    _driversLocked: Ember.computed('preInitialized', 'driversLocked', function () {
      if (this.get('driversLocked') === undefined) {
        return this.get('preInitialized');
      } else {
        return this.get('driversLocked');
      }
    }),

    filteredVehicleCategories: undefined,
    filteredBodyTypes: undefined,
    filteredDriverTypes: undefined,
    filteredCoverTypes: undefined,
    filteredPackages: undefined,
    _getDefaultStartEndDateTimes: function _getDefaultStartEndDateTimes(initialStartDate) {
      var today;
      if (initialStartDate) {
        today = (0, _moment.default)(initialStartDate);
      } else {
        today = (0, _moment.default)();
      }

      var inFourMonths;
      if (this.get('covernoteMode')) {
        inFourMonths = (0, _moment.default)(today).add(4, 'month').subtract(1, 'day');
      } else {
        inFourMonths = (0, _moment.default)(today).add(1, 'year').subtract(1, 'day');
      }

      inFourMonths.set({ 'hour': 23, 'minute': 59 });

      return {
        startDateTime: today.toDate(),
        endDateTime: inFourMonths.toDate()
      };
    },
    _initCustomStartEndDateTime: function _initCustomStartEndDateTime(changeset) {
      var today = (0, _moment.default)();
      //var tomorrow = today.add(1, 'day');
      //tomorrow.set({'hour': 0, 'minute': 1});
      today.add(5, 'minute');
      var inFourMonths;

      if (this.get('covernoteMode')) {
        inFourMonths = (0, _moment.default)(today).add(4, 'month').subtract(1, 'day');
      } else {
        inFourMonths = (0, _moment.default)(today).add(1, 'year').subtract(1, 'day');
      }
      inFourMonths.set({ 'hour': 23, 'minute': 59 });
      changeset.set('startDateTime', today.toDate());
      changeset.set('endDateTime', inFourMonths.toDate());
    },
    _initParams: function _initParams() {
      var _this = this;

      this.set('cities', Ember.A([{ id: 1, value: "Lefkosia" }, { id: 5, value: "Lemesos" }, { id: 3, value: "Larnaka" }, { id: 4, value: "Paphos" }, { id: 6, value: "Ammochostos" }]));

      if (Ember.get(this, 'endorsementMode')) {
        this.set('documentTypes', Ember.A([{ id: 'STANDARD_ENDORSEMENT', label: "Standard endorsement" }, { id: 'DEFINITE_ENDORSEMENT', label: "Definite endorsement" }]));
      }

      this.set('filteredVehicleCategories', Ember.A([]));
      this.set('filteredBodyTypes', Ember.A([]));
      this.set('filteredDriverTypes', Ember.A([]));
      this.set('filteredCoverTypes', Ember.A([]));
      this.set('filteredPackages', Ember.A([]));
      var paramStore = this.get('paramStore');
      return paramStore.getParams().then(function (params) {
        Ember.set(_this, 'params', params);
      });
    },
    _initModel: function _initModel(model) {
      var changeset;
      if (Ember.get(this, 'endorsementMode')) {
        changeset = new _emberChangeset.default(model, (0, _emberChangesetValidations.default)(_endorsement.default), _endorsement.default);
      } else if (Ember.get(this, 'covernoteMode')) {
        changeset = new _emberChangeset.default(model, (0, _emberChangesetValidations.default)(_covernote.default), _covernote.default);
      } else {
        changeset = new _emberChangeset.default(model, (0, _emberChangesetValidations.default)(_proposal.default), _proposal.default);
      }
      return changeset;
    },
    _initExistingDriversAndChangeSets: function _initExistingDriversAndChangeSets(model, changeset, isModelPreinitialized) {
      var existingDrivers = model.get('drivers');
      var drivers = Ember.A([]);
      var ownerDriverDetails = void 0;
      var changesetDrivers = Ember.A([]);

      if (isModelPreinitialized) {
        var _existingDrivers = model.get('drivers');

        if (_existingDrivers) {
          //If ownerIsDriving, then the first driver IS the owner
          //Extract it
          if (model.get('ownerDriving') && _existingDrivers.length > 0) {
            ownerDriverDetails = _existingDrivers[0];
            _existingDrivers.removeAt(0);
            //We now need to call ownerDrivingChanged after changeset is initialized with drivers
          }
          _existingDrivers.forEach(function (driverPOJO) {
            console.log("Driver", driverPOJO);
            var driver = Driver.create(driverPOJO);
            if (driverPOJO.dateOfBirth) {
              driver.set('dateOfBirth', new _moment.default(driverPOJO.dateOfBirth, 'YYYY/MM/DD').toDate());
            }
            if (driverPOJO.licenseDate) {
              driver.set('licenseDate', new _moment.default(driverPOJO.licenseDate, 'YYYY/MM/DD').toDate());
            }
            drivers.pushObject(driver);
            var driverChangeset = new _emberChangeset.default(driver, (0, _emberChangesetValidations.default)(_driver.default), _driver.default);
            changesetDrivers.pushObject(driverChangeset);
          });
        }
        Ember.set(changeset, 'drivers', changesetDrivers); //will hold driver changesets
        Ember.set(this, 'drivers', drivers); //Will hold driver models
      } else {
        Ember.set(changeset, 'drivers', changesetDrivers); //will hold driver changesets
        Ember.set(this, 'drivers', drivers); //Will hold driver models
      }

      if (model.get('ownerDriving')) {
        //means owner is driving
        this.get('actions').ownerDrivingChanged.call(this, true, changeset); //We don't wanna call it with false because the shell driver IS NOT THERE anw
        if (ownerDriverDetails) {
          Ember.set(changeset, 'ownerDrivingPolicyExcess', ownerDriverDetails.excessAmount);
          if (ownerDriverDetails.licenseDate) {
            Ember.set(changeset, 'ownerDrivingLicenseDate', (0, _moment.default)(ownerDriverDetails.licenseDate, 'YYYY/MM/DD').toDate());
          }
          Ember.set(changeset, 'ownerIsLearner', ownerDriverDetails.learner);
          Ember.set(changeset, 'ownerLicenseIsForeign', ownerDriverDetails.foreignLicense);
        }
      }
      return { changesetDrivers: changesetDrivers, drivers: drivers };
    },
    _initChangeset: function _initChangeset(model, changeset) {
      var inFourMonthsLabel;
      if (Ember.get(this, 'covernoteMode')) {
        inFourMonthsLabel = "(in four months)";
      } else {
        inFourMonthsLabel = "(in 1 year)";
      }
      Ember.set(this, 'inFourMonthsLabel', inFourMonthsLabel);

      /*
      let drivers = A([]);
      let changesetDrivers = A([]);
      let ownerDriverDetails;
      */
      var isModelPreinitialized = !model.get('isNew') || Ember.get(this, 'preInitialized');
      if (isModelPreinitialized && model.get('startDateTime')) {
        //First, lets set start date to where it should be;
        Ember.set(changeset, 'customStartEndDate', true);

        //WE WERE DOING THE BELOW, SO WE PREPARE THE PROPOSAL (AFTER INITIALIZING FROM COVERNOTE) WITH THE ONE YEAR SPAN. BUT THIS CAUSES A BUNCH OF WRONG LOGIC
        //ESPECIALLY IF THE DOCUMENT USED TO INITIALIZE THIS HAD A SPECIFIC DURATION (EG 1 MONTH) THAT WE NEED TO KEEP, for example on EDIT AND APPROVE
        var times = this._getDefaultStartEndDateTimes(model.get('startDateTime'));
        if (Ember.get(this, 'proposalMode') && model.get('isNew')) {
          //This Means it's a new proposal, and we are not editing one prior approving it.
          //In this case, set the time defaults
          Ember.set(changeset, 'startDateTime', times.startDateTime);
          Ember.set(changeset, 'endDateTime', times.endDateTime);
        }

        //Now let's set some min Values for the validation to pickup and check


        if (Ember.get(this, 'endorsementMode')) {
          //pass
          Ember.set(this, 'maxEndDateTime', model.get('endDateTime'));
          Ember.set(changeset, 'maxEndDateTime', Ember.get(this, 'maxEndDateTime'));
        } else {
          Ember.set(this, 'minEndDateTime', model.get('endDateTime'));
          Ember.set(changeset, 'minEndDateTime', Ember.get(this, 'minEndDateTime'));
        }
      }

      if (isModelPreinitialized && model.get('cityCodeName')) {
        //Means it has custom address!
        Ember.set(this, 'isForeignStreet', true);
        //TODO !!!
      }

      this._initExistingDriversAndChangeSets(model, changeset, isModelPreinitialized);
      /*
      if (isModelPreinitialized) {
        let existingDrivers = model.get('drivers');
         if (existingDrivers) {
          //If ownerIsDriving, then the first driver IS the owner
          //Extract it
          if (model.get('ownerDriving') && existingDrivers.length > 0) {
            ownerDriverDetails = existingDrivers[0];
            existingDrivers.removeAt(0);
            //We now need to call ownerDrivingChanged after changeset is initialized with drivers
          }
          existingDrivers.forEach((driverPOJO) => {
            console.log("Driver", driverPOJO);
            var driver = Driver.create(driverPOJO);
            if (driverPOJO.dateOfBirth) {
              driver.set('dateOfBirth', new Date(driverPOJO.dateOfBirth));
            }
            if (driverPOJO.licenseDate) {
              driver.set('licenseDate', new Date(driverPOJO.licenseDate));
            }
            drivers.pushObject(driver);
            var driverChangeset = new Changeset(driver, lookupValidator(DriverValidations), DriverValidations);
            changesetDrivers.pushObject(driverChangeset);
          });
        }
        set(changeset, 'drivers', changesetDrivers); //will hold driver changesets
        set(this, 'drivers', drivers); //Will hold driver models
      } else {
        set(changeset, 'drivers', changesetDrivers); //will hold driver changesets
        set(this, 'drivers', drivers); //Will hold driver models
      }
        //Need to do the following AFTER we set the drivers on to the changeset, because the callable acts upon those...
       if (model.get('ownerDriving')) {
        //means owner is driving
        this.get('actions').ownerDrivingChanged.call(this, true, changeset); //We don't wanna call it with false because the shell driver IS NOT THERE anw
        if (ownerDriverDetails) {
          set(changeset, 'ownerDrivingPolicyExcess', ownerDriverDetails.excessAmount);
          if (ownerDriverDetails.licenseDate) {
            set(changeset, 'ownerDrivingLicenseDate', moment(ownerDriverDetails.licenseDate, 'YYYY-MM-DD').toDate());
          }
          set(changeset, 'ownerIsLearner', ownerDriverDetails.learner);
          set(changeset, 'ownerLicenseIsForeign', ownerDriverDetails.foreignLicense);
        }
      }
      */

      //set default values from database
      var backendConfig = this.get('backendConfig');
      if (!isModelPreinitialized) {
        if (Ember.get(backendConfig, 'documentDefaults.nationality')) {
          this.get('store').findRecord('country', Ember.get(backendConfig, 'documentDefaults.nationality')).then(function (country) {
            Ember.set(changeset, 'nationality', country);
          });
        }
        //if (get(backendConfig, 'documentDefaults.clientIdType')) {
        var defaultClientIdType = 'ID_CARD'; //get(backendConfig, 'documentDefaults.clientIdType');
        Ember.set(changeset, 'clientIdType', Ember.get(this, 'idTypes').findBy('id', defaultClientIdType));
        //}
      } else {
        //all enums are currently in the form of objects inside the model (they come embedded)
        //we need to extract them, and set the changeset values to their IDs instead!
        //Minerva Document Enums

        if (model.get('lineOfBusiness')) {
          var lineOfBusiness = model.get('lineOfBusiness');
          //changeset.set('lineOfBusiness', lineOfBusiness);
          this.get('actions').lobChanged.call(this, lineOfBusiness, changeset);
        }
        if (model.get('coverType')) {
          var coverType = model.get('coverType');
          //changeset.set('coverType', coverType);
          this.get('actions').coverTypeChanged.call(this, coverType, changeset);
        }
        if (model.get('coverPackage')) {
          this.get('actions').coverPackageChanged.call(this, model.get('coverPackage'), changeset);
        }

        Ember.set(this, '_clientDataLoaded', true);
        Ember.set(this, '_clientDataFound', true);
        Ember.set(this, '_carDataLoaded', true);
        Ember.set(this, '_carDataFound', true);
      }
      return changeset;
    },
    _init: function _init() {
      var _this2 = this;

      var model = this.get('model');
      this.get('paramStore').getConfig().then(function (backendConfig) {
        Ember.set(_this2, 'backendConfig', backendConfig);
        var changeset = _this2._initModel(model);
        _this2._initChangeset(model, changeset);
        Ember.set(_this2, 'changeset', changeset);
        console.log("Changeset Initialized:", changeset);
      });
    },

    comtecErrors: undefined,
    init: function init() {
      var _this3 = this;

      this._super.apply(this, arguments);
      this._initParams().then(function () {
        _this3._init();
      });
    },


    isFormInvalid: Ember.computed.alias('changeset.isInvalid'), /*computed('changeset.isInvalid', 'changeset.drivers.[]', 'changeset.drivers.@each.isInvalid', function () {
                                                                if (get(this, 'changeset.isInvalid')) {
                                                                return true;
                                                                }
                                                                const drivers = get(this, 'changeset.drivers');
                                                                if (drivers) {
                                                                var hasInvalidDrivers = false;
                                                                drivers.forEach((driver) => {
                                                                if (driver.isInvalid) {
                                                                hasInvalidDrivers = true;
                                                                }
                                                                });
                                                                return hasInvalidDrivers;
                                                                }
                                                                return false;
                                                                }),
                                                                */
    _comtectClientInfoFieldType: Ember.computed('_comtectClientInfoLock', function () {
      if (Ember.get(this, '_comtectClientInfoLock')) {
        return 'text';
      } else {
        return 'text';
      }
    }),
    _isTomLoading: false,

    _carDataFound: false,
    _carDataLoaded: false,
    _carDataLoading: false,
    _carDataNotMatchingLob: false,
    _carDataNotMatchingLobMessage: [],
    _fetchVehicleInfoFromTom: function _fetchVehicleInfoFromTom(licensePlate /*, resolve, reject*/) {
      var _this4 = this;

      var changeset = this.changeset;

      if (!licensePlate) {
        return;
      }

      this.set('_carDataLoading', true);
      var url = _environment.default.APP.apiUrl + "/iac/plate/" + licensePlate;

      var headers = {};
      this.get('session').authorize('authorizer:jwt', function (headerName, headerValue) {
        headers[headerName] = headerValue;
      });
      var notifications = Ember.get(this, 'notifications');
      this.get('ajax').request(url, {
        method: 'GET',
        headers: headers,
        contentType: "application/x-www-form-urlencoded; charset=UTF-8"
      }).then(function (response) {
        var json = response;
        notifications.success('Car with registration number ' + licensePlate + ' found in TOM');
        var manufactureDate = (0, _moment.default)(json.manufactureDate, "DD-MM-YYYY");
        var leftHandDrive;
        if (json.steeringPosition == "Δεξιά") {
          leftHandDrive = false;
        } else {
          leftHandDrive = true;
        }
        changeset.setProperties({
          tomInfo: response,
          vehicleChassisNumber: json.vin,
          vehicleEngineCapacity: json.engineCapacity,
          vehicleEnginePower: json.enginePower,
          vehicleModel: json.modelDescription,
          vehicleManufactureYear: manufactureDate.year(),
          vehicleNumberOfSeats: json.numberOfSeatedPassengers,
          leftHandDrive: leftHandDrive
        });
        if (json.modelDescription) {
          _this4.set('_tomHasResultsForVehicleModel', true);
        } else {
          _this4.set('_tomHasResultsForVehicleModel', false);
        }

        if (json.minervaMake) {
          _this4.get('store').findRecord('minerva-make', json.minervaMake).then(function (make) {
            changeset.set('vehicleMake', make);
          });
          _this4.set('_tomHasResultsForVehicleMake', true);
          changeset.set('vehicleMake', undefined);
        } else {
          _this4.set('_tomHasResultsForVehicleMake', false);
          _this4.set('possibleVehicleMake', json.make);
        }
        _this4.set('_carDataFound', true);
        _this4.set('_tomResults', json);
        //CHECK IF car returned vehicle category is allowed for this lob. if yes, allow to proceed, else not


        var currentLobId = changeset.get('lineOfBusiness.id');
        var allowedLobIds = json.allowedLobs;

        if (allowedLobIds.includes(currentLobId)) {
          _this4.set('_carDataNotMatchingLob', false);
        } else {
          _this4.set('_carDataNotMatchingLob', true);
          _this4.set('_carDataNotMatchingLobMessage', allowedLobIds);
        }
        return json;
      }).catch(function (response) {
        notifications.warning('Car with registration number ' + licensePlate + ' could not be found in TOM');
        console.log('_fetchVehicleInfoFromTom', response);
        changeset.setProperties({
          tomInfo: undefined,
          vehicleChassisNumber: undefined,
          vehicleEngineCapacity: undefined,
          vehicleEnginePower: undefined,
          vehicleModel: undefined,
          vehicleManufactureYear: undefined,
          vehicleNumberOfSeats: undefined,
          leftHandDrive: undefined,
          vehicleMake: undefined
        });
        _this4.set('_tomHasResultsForVehicleMake', false);
        _this4.set('_tomHasResultsForVehicleModel', false);
        _this4.set('_carDataFound', false);
        _this4.set('_tomResults', undefined);
        _this4.set('_carDataNotMatchingLob', false);
        _this4.set('_carDataNotMatchingLobMessage', []);
      }).finally(function () {
        _this4.set('_carDataLoaded', true);
        _this4.set('_carDataLoading', false);
      });
      //fetch(url, {method: 'GET', headers: headers})
    },
    _calculateExcess: function _calculateExcess() {
      //console.log("1.*******");
      var excess = 240;
      var sumInsured = this.get('changeset.sumInsured');
      //console.log("2.*******");
      if (!sumInsured) {
        sumInsured = 0;
      }
      if (sumInsured / 100 < 240) {
        excess = 240;
      } else {
        excess = sumInsured / 100;
      }
      var years70 = (0, _moment.default)().subtract(70, 'years');
      var years75 = (0, _moment.default)().subtract(75, 'years');
      var haveLearner = false;
      var haveOld = false;
      if (this.get('drivers')) {
        this.get('drivers').forEach(function (driver) {
          if (Ember.get(driver, 'learner')) {
            haveLearner = true;
          }
          var driverYear = (0, _moment.default)(Ember.get(driver, 'dateOfBirth'));
          if (driverYear.isBetween(years75, years70)) {
            haveOld = true;
          }
        });
      }
      //console.log("3.*******");
      if (haveLearner) {
        excess = excess + 40;
      }
      if (haveOld) {
        excess = excess + 40;
      }
      return Math.round(excess);
    },

    excessCalculated: Ember.computed('isPolicyExcessAutocalculated', 'changeset.sumInsured', 'drivers.[]', 'drivers.@each.dateOfBirth', 'drivers.@each.learner', function () {
      if (!this.get('isPolicyExcessAutocalculated')) {
        return undefined;
      }
      return this._calculateExcess();
    }),

    /*
    _clientInfoStepShown: computed('_lobSelected', 'endorsementMode', '_contractFound', function () {
      if (!this.get('endorsementMode')) {
        return this.get('_lobSelected');
      } else {
        return this.get('_contractFound');
      }
    }),
    */
    _clientInfoStepShown: Ember.computed.alias('_lobSelected'),
    _clientDataFound: false,
    _clientDataLoaded: false,
    _clientDataLoading: false,
    _fetchClientInformation: function _fetchClientInformation(clientId, clientIdType /*, resolve, reject*/) {
      var _this5 = this;

      if (!clientId) {
        return;
      }
      Ember.set(this, '_clientDataLoading', true);
      var data = {
        idType: clientIdType,
        id: clientId
      };
      var url = _environment.default.APP.apiUrl + "/comtec/lookupUser";
      this.set('_isComtecClientLoading', true);
      this.get('session').authorize('authorizer:jwt', function (headerName, headerValue) {
        var headers = {};
        headers[headerName] = headerValue;
        _this5.get('ajax').request(url, {
          method: 'GET',
          data: data,
          headers: headers,
          contentType: 'application/json; charset=utf-8'
        }).then(function (response) {
          if (response.comtecClientNumber) {
            //means the request succeeded
            _this5.get('notifications').success("Client found in backend system. Autopopulating...");

            var momentDayOfBirth = undefined;
            if (response.dateOfBirth) {
              momentDayOfBirth = new _moment.default(response.dateOfBirth, 'YYYY/MM/DD').toDate();
            }
            _this5.changeset.setProperties({
              comtecClientId: response.comtecClientNumber,
              firstName: response.firstName,
              lastName: response.lastName,
              companyName: response.companyName,
              apartmentNumber: response.apartmentNumber,
              cityCodeName: response.cityCodeName,
              dateOfBirth: momentDayOfBirth,
              emailAddress: response.emailAddress,
              faxNumber: response.faxNumber,
              homeStreetNumber: response.homeStreetNumber,
              landlineTelNumber: response.landlineTelNumber,
              mobileTelNumber: response.mobileTelNumber,
              postCode: response.postCode,
              streetName: response.streetName,
              gender: _this5.get('genders').findBy('id', response.gender)
            });
            _this5.changeset.validate();

            /*
            if (response.gender) {
              set(changeset, 'gender', this.get('genders').findBy('id', response.gender));
            }
            */
            if (response.occupation) {
              _this5.get('store').findRecord('occupation', response.occupation).then(function (occupation) {
                Ember.set(_this5, 'changeset.occupation', occupation);
              });
            }
            if (response.street) {
              _this5.get('store').findRecord('street', response.street).then(function (street) {
                Ember.set(_this5, 'changeset.street', street);
              });
            }
            if (response.nationality) {
              _this5.get('store').findRecord('country', response.nationality).then(function (country) {
                Ember.set(_this5, 'changeset.nationality', country);
              });
            }
            _this5.set('_comtectClientInfoLock', true); //TO BE DEPRICATED
            Ember.set(_this5, '_clientDataFound', true);
          } else {
            Ember.set(_this5, 'changeset.comtecClientId', undefined);
            _this5.get('notifications').info("Client couldn't be located...");
            _this5.changeset.setProperties({
              comtecClientId: undefined,
              firstName: undefined,
              lastName: undefined,
              apartmentNumber: undefined,
              cityCodeName: undefined,
              dateOfBirth: undefined,
              emailAddress: undefined,
              faxNumber: undefined,
              homeStreetNumber: undefined,
              landlineTelNumber: undefined,
              mobileTelNumber: undefined,
              postCode: undefined,
              streetName: undefined,
              gender: undefined,
              occupation: undefined,
              street: undefined,
              nationality: undefined
            });

            _this5.set('_comtectClientInfoLock', false); //TO BE DEPRICATED
            Ember.set(_this5, '_clientDataFound', false);
          }
        }).catch(function (response) {
          Ember.set(_this5, 'changeset.comtecClientId', undefined);
          _this5.get('notifications').error('There was an error while looking up the client. ' + response.errors);
          _this5.changeset.setProperties({
            comtecClientId: undefined,
            firstName: undefined,
            lastName: undefined,
            apartmentNumber: undefined,
            cityCodeName: undefined,
            dateOfBirth: undefined,
            emailAddress: undefined,
            faxNumber: undefined,
            homeStreetNumber: undefined,
            landlineTelNumber: undefined,
            mobileTelNumber: undefined,
            postCode: undefined,
            streetName: undefined,
            gender: undefined,
            occupation: undefined,
            street: undefined,
            nationality: undefined
          });
          Ember.set(_this5, '_clientDataFound', false);
          _this5.set('_comtectClientInfoLock', false); //TO BE DEPRICATED
        }).finally(function () {
          _this5.set('_isComtecClientLoading', false); //TO BE DEPRICATED
          Ember.set(_this5, '_clientDataLoading', false);
          Ember.set(_this5, '_clientDataLoaded', true);
        });
      });
    },

    actions: {
      checkEnter: function checkEnter(followUpEvent, e) {
        if (e.key === 'Enter') {
          this.get('actions.' + followUpEvent).call(this);
        }
      },
      goback: function goback() {
        if (confirm("Are you sure you want to cancel?")) {
          this.get('router').transitionTo('index');
          //history.back();
        }
      },
      addDriver: function addDriver(changeset) {
        var driver = Driver.create();
        var driverChangeset = new _emberChangeset.default(driver, (0, _emberChangesetValidations.default)(_driver.default), _driver.default);
        //this.get('drivers').pushObject(driver);

        var drivers = Ember.get(changeset, 'drivers');
        Ember.get(changeset, 'drivers').pushObject(driverChangeset);
        Ember.get(this, 'drivers').pushObject(driver);
        changeset.validate();
      },
      removeDriver: function removeDriver(driverIndex) {
        Ember.get(this, 'drivers').removeAt(driverIndex);
        Ember.get(this, 'changeset.drivers').removeAt(driverIndex);
        Ember.get(this, 'changeset').validate();
        //this.get('changeset.drivers').removeObject(driver);
      },
      firstNameChanged: function firstNameChanged(value, changeset) {
        changeset.set('firstName', value);
        changeset.set('companyName', "");
        changeset.validate();
      },
      lastNameChanged: function lastNameChanged(value, changeset) {
        changeset.set('lastName', value);
        changeset.set('companyName', "");
        changeset.validate();
      },
      companyNameChanged: function companyNameChanged(value, changeset) {
        changeset.set('companyName', value);
        changeset.set('lastName', "");
        changeset.set('firstName', "");
        changeset.validate();
      },
      loadClient: function loadClient() {
        var clientId = Ember.get(this, 'changeset.clientId');
        if (!clientId) {
          return;
        }
        var clientIdType = Ember.get(this, 'changeset.clientIdType.id');
        this._fetchClientInformation(clientId.toUpperCase(), clientIdType);
      },
      clearClient: function clearClient() {
        this.changeset.setProperties({
          clientId: undefined,
          comtecClientId: undefined,
          firstName: undefined,
          lastName: undefined,
          apartmentNumber: undefined,
          cityCodeName: undefined,
          dateOfBirth: undefined,
          emailAddress: undefined,
          faxNumber: undefined,
          homeStreetNumber: undefined,
          landlineTelNumber: undefined,
          mobileTelNumber: undefined,
          postCode: undefined,
          streetName: undefined,
          gender: undefined
        });

        /*
        if (get(this,'endorsementMode')==true){
          this.changeset.set('lineOfBusiness', null) //Also clearing this, to force user to rechoose -> hence re initialize fields properly
        }
        */
        Ember.set(this, '_clientDataLoaded', false);
        Ember.set(this, '_clientDataFound', false);
      },
      landlineTelNumberChanged: function landlineTelNumberChanged(value, changeset) {
        changeset.set('landlineTelNumber', value);
        changeset.validate();
      },
      mobileTelNumberChanged: function mobileTelNumberChanged(value, changeset) {
        changeset.set('mobileTelNumber', value);
        changeset.validate();
      },

      /*
      existingContractReferenceChanged(value, changeset) {
        changeset.set('existingContractReference', value);
        if (!value && !get(this,'endorsementMode')) {
          if (get(this, 'covernoteMode')) {
            changeset.set('documentType', 'COVERNOTE');
          } else {
            changeset.set('documentType', 'PROPOSAL');
          }
        }
        changeset.validate();
      },
      */
      ownerDrivingChanged: function ownerDrivingChanged(value, changeset) {
        //IF owner is driving, we add a shell driver into the drivers list,
        //so the validatePresence validation for drivers can pass. We do not
        //validate this though, and will reconstruct it at a later point on submission

        changeset.set('ownerDriving', value);
        if (value) {
          var driver = Driver.create({ isOwnerShell: true });
          Ember.get(changeset, 'drivers').insertAt(0, driver);
          Ember.get(this, 'drivers').insertAt(0, driver);
        } else {
          Ember.get(this, 'drivers').removeAt(0);
          Ember.get(changeset, 'drivers').removeAt(0);
        }
        changeset.validate();
      },
      emptySubmit: function emptySubmit() {
        console.log("doing nothing...");
        return false;
      },
      interimSubmit: function interimSubmit(changeset, mode) {
        changeset.validate();
        if (changeset.get('isValid')) {
          if (confirm("Are you sure you want to submit the form?")) {
            this.get('actions').submit.call(this, changeset, mode);
          }
        }
      },
      submit: function submit(changeset, mode) {
        var _this6 = this;

        if (!mode) {
          mode = 'FINAL';
        }
        var isFinal = false;
        if (mode == 'FINAL') {
          isFinal = true;
        }
        if (isFinal) {
          changeset.validate();
          if (changeset.get('isInvalid')) {
            return;
          }
        }

        //We check if drivers are also valid. If they are, we proceed to save the covernote
        //Exception is when we are saving as draft. Screw the validation then

        var hasOwnerDriving = false;
        var hasInvalidDrivers = false;

        Ember.get(changeset, 'drivers').forEach(function (driverChangeset) {
          //console.log('validating driver');
          if (Ember.get(driverChangeset, 'isOwnerShell')) {
            hasOwnerDriving = true;
          } else {
            if (!driverChangeset.get('isValid')) {
              //console.log("Invalid driver:", driverChangeset);
              driverChangeset.validate();
              hasInvalidDrivers = true;
            }
          }
        });
        if (hasInvalidDrivers && isFinal) {
          //console.log("Invalid drivers:", get(this, 'drivers'));
          return;
        }

        Ember.set(this, 'isSubmitting', true);
        if (hasOwnerDriving) {
          Ember.get(this, 'drivers').objectAt(0).setProperties({
            driverId: Ember.get(changeset, 'clientId'),
            driverName: Ember.get(changeset, 'firstName') + ' ' + Ember.get(changeset, 'lastName'),
            learner: Ember.get(changeset, 'ownerIsLearner'),
            dateOfBirth: Ember.get(changeset, 'dateOfBirth'),
            excessAmount: Ember.get(changeset, 'ownerDrivingPolicyExcess'),
            licenseDate: Ember.get(changeset, 'ownerDrivingLicenseDate'),
            foreignLicense: Ember.get(changeset, 'ownerLicenseIsForeign'),
            ownerDriver: true
          });
        }

        Ember.get(changeset, 'drivers').forEach(function (driverChangeset) {
          if (Ember.get(driverChangeset, 'isOwnerShell')) {
            return; // we created this object on the previous if. changeset is just a shell anw, and we initialized it manually just a moment ago .
          }
          driverChangeset.execute();
        });

        if (isFinal) {
          changeset.set('submissionStatus', 'FINAL');
        } else {
          changeset.set('submissionStatus', 'DRAFT');
        }

        if (this.get('isPolicyExcessAutocalculated')) {
          changeset.set('policyExcess', this._calculateExcess());
        }
        var hasCustomStartEndDate = false;
        if (!changeset.get('customStartEndDate')) {
          //means we should apply the default
          hasCustomStartEndDate = true;
          var times = this._getDefaultStartEndDateTimes();
          changeset.set('startDateTime', times.startDateTime);
          changeset.set('endDateTime', times.endDateTime);
        }
        var allKeysSchema;
        if (this.get('covernoteMode')) {
          allKeysSchema = Object.assign({}, _minervaDocument.documentSchema, _covernote2.covernoteSchema);
        } else {
          allKeysSchema = Object.assign({}, _minervaDocument.documentSchema, _proposal2.proposalSchema);
        }

        if (isFinal) {

          return changeset.cast(keys(allKeysSchema)).prepare(function (changes) {
            var driverArray = Ember.get(_this6, 'drivers').toArray();
            driverArray.forEach(function (driver) {
              Ember.set(driver, 'dateOfBirth', (0, _moment.default)(Ember.get(driver, 'dateOfBirth')).format('YYYY-MM-DD'));
              Ember.set(driver, 'licenseDate', (0, _moment.default)(Ember.get(driver, 'licenseDate')).format('YYYY-MM-DD'));
            });
            changes['drivers'] = driverArray; //Give out the real drivers array. not the changeset.
            //Fix for working with version 2
            return changes;
          }).save().then(function (obj) {
            //console.log("changeset was saved successfully");
            //console.log("Now transitioning to route:", this.get('detailRoute'), obj.get('id'));
            _this6.get('notifications').success("Document was saved successfully");
            _this6.get('router').replaceWith(_this6.get('detailRoute'), obj.get('id'));
          }).catch(function (response) {
            _this6.set('comtecErrors', response.errors);
            _this6.get('notifications').error('There was an error while trying to save the document. ' + response.errors);
            _this6._initExistingDriversAndChangeSets(_this6.get('model'), changeset, true);
            if (hasCustomStartEndDate) {
              changeset.set('customStartEndDate', true);
            }
            if (Ember.get(_this6, 'minEndDateTime')) {
              changeset.set('minEndDateTime', Ember.get(_this6, 'minEndDateTime'));
            }
            if (Ember.get(_this6, 'maxEndDateTime')) {
              changeset.set('maxEndDateTime', Ember.get(_this6, 'maxEndDateTime'));
            }
            Ember.set(_this6, 'isSubmitting', false);
          });
        } else {
          var model = this.get('model');
          keys(allKeysSchema).forEach(function (key) {
            model.set(key, changeset.get(key)); //Copies unvalidated values over to the model. Ouch!
          });

          var driverArray = Ember.get(this, 'drivers').toArray();
          driverArray.forEach(function (driver) {
            Ember.set(driver, 'dateOfBirth', (0, _moment.default)(Ember.get(driver, 'dateOfBirth')).format('YYYY-MM-DD'));
            Ember.set(driver, 'licenseDate', (0, _moment.default)(Ember.get(driver, 'licenseDate')).format('YYYY-MM-DD'));
          });
          model.set('drivers', driverArray);

          return model.save().then(function (obj) {
            _this6.get('notifications').success("Document was saved successfully as a draft");
            _this6.get('router').replaceWith(_this6.get('detailRoute'), obj.get('id'));
          }).catch(function (response) {
            _this6.get('notifications').error('There was an error while trying to save the document. ' + response.errors);
            _this6._initExistingDriversAndChangeSets(_this6.get('model'), changeset, true);
            Ember.set(_this6, 'isSubmitting', false);
            if (hasCustomStartEndDate) {
              changeset.set('customStartEndDate', true);
            }
            if (Ember.get(_this6, 'minEndDateTime')) {
              changeset.set('minEndDateTime', Ember.get(_this6, 'minEndDateTime'));
            }
            if (Ember.get(_this6, 'maxEndDateTime')) {
              changeset.set('maxEndDateTime', Ember.get(_this6, 'maxEndDateTime'));
            }
            Ember.set(changeset, 'maxEndDateTime', Ember.get(_this6, 'maxEndDateTime'));
            Ember.set(_this6, 'isSubmitting', false);
          });
        }
      },
      customStartEndDateChanged: function customStartEndDateChanged(value, changeset) {
        changeset.set('customStartEndDate', value);
        if (value) {
          //Means custom start date is set
          this._initCustomStartEndDateTime(changeset);
        } else {
          changeset.set('startDateTime', undefined);
          changeset.set('endDateTime', undefined);
        }
        changeset.validate();
      },
      lobChanged: function lobChanged(value, changeset) {

        changeset.set('lineOfBusiness', value);
        if (!value) {
          this.set('_lobSelected', false);
          this.set('filteredVehicleCategories', undefined);
          this.set('filteredBodyTypes', undefined);
          this.get('actions').coverTypeChanged.call(this, null, changeset);
          return;
          //means an empty lob was selected. So clear the coverType
        }
        var lobId = Ember.get(value, 'id'); //value;
        this.set('_lobSelected', true);
        //NOW UPDATE COVERTYPE FILTERS and SELECT DEFAULT
        var coverTypes = this.get('coverTypes');
        if (coverTypes) {
          if (lobId == 'PRIVATE' || lobId == 'COMMERCIAL') {
            this.set('filteredCoverTypes', coverTypes); //LOB11 supports all of them
          } else {
            this.set('filteredCoverTypes', coverTypes.filterBy('id', 'THIRD_PARTY')); //LOB11 supports all of them
          }
          //var coverType = this.get('store').peekRecord('params/cover-type', 'THIRD_PARTY');


          if (!Ember.get(changeset, 'coverType')) {
            //If not coming from a prepopulated document, that has a covertype,
            //and covertype is empty, populate it with a default
            var thirdParty = coverTypes.findBy('id', 'THIRD_PARTY');
            this.get('actions').coverTypeChanged.call(this, thirdParty, changeset);
          }
          /*
          else {
            //We need to rerun this for the proper fields to get shown/hidden in relation to the lob!
            this.get('actions').coverTypeChanged.call(this, get(changeset, 'coverType'), changeset);
          }*/
        }

        //FILTER VEHICLE CATEGORIES ALSO
        var lobVehicleCategories = Ember.get(this, 'vehicleCategories');
        if (lobVehicleCategories) {
          Ember.set(this, 'filteredVehicleCategories', lobVehicleCategories.filterBy('lob', lobId));
        }

        //FILTER BODY TYPES
        var lobBodyTypes = Ember.get(this, 'bodyTypes');
        if (lobBodyTypes) {
          Ember.set(this, 'filteredBodyTypes', lobBodyTypes.filterBy('lob', lobId)); //Before that these were models, we needed lob.content.id. now that are plain json, we check them like this
        }

        /*
        var defaultBodyType = '';
         if (lobId == 'PRIVATE') {
          //Set type of body
          defaultBodyType = 'SALOON';
         } else if (lobId == 'COMMERCIAL') {
         } else if (lobId == 'MOTORCYCLE') {
          //Set type of body
          defaultBodyType = 'MOTORCYCLE';
        }
         if (defaultBodyType) {
          //var bodyType = this.get('store').peekRecord('params/body-type', defaultBodyType);
          changeset.set('typeOfBody', defaultBodyType);
        }else{
          changeset.set('typeOfBody', undefined);
        }
          */
      },
      coverTypeChanged: function coverTypeChanged(value, changeset) {
        var _this7 = this;

        changeset.set('coverType', value);
        var lobId = changeset.get('lineOfBusiness.id');
        var backendConfig = this.get('backendConfig');

        var visibilityFlagSet = backendConfig.proposalParamsVisibility;
        if (!value) {
          Ember.set(this, 'coverTypeSelected', false);
          visibilityFlagSet.ALL_FIELDS.forEach(function (field) {
            var visibilityFlag = "is" + field[0].toUpperCase() + field.substring(1) + "Visible";
            _this7.set(visibilityFlag, false);
            changeset.set(field, undefined);
          });
          return;
          //clear up everything
        } else {
          var coverTypeId = Ember.get(value, 'id'); //value;
          var coverTypeFields = visibilityFlagSet[lobId][coverTypeId];
          var lobFields = visibilityFlagSet[lobId]["ALL"];
          var allFields = lobFields.concat(coverTypeFields);
          allFields.forEach(function (field) {
            var visibilityFlag = "is" + field[0].toUpperCase() + field.substring(1) + "Visible";
            if (!_this7.get('preInitialized')) {
              if (!(field == 'sumInsured' || field == 'policyExcess')) {
                changeset.set(field, undefined);
              }
            }
            _this7.set(visibilityFlag, true);
          });

          var differenceFields = visibilityFlagSet.ALL_FIELDS.filter(function (x) {
            return !allFields.includes(x);
          });
          differenceFields.forEach(function (field) {
            var visibilityFlag = "is" + field[0].toUpperCase() + field.substring(1) + "Visible";
            changeset.set(field, undefined);
            _this7.set(visibilityFlag, false);
          });
          var driverTypes = this.get('driverTypes');
          if (driverTypes) {
            var availableDriverTypesInRegardsToMode = driverTypes.filter(function (obj) {
              //return get(obj, 'applicableCoverTypes').indexOf(coverTypeId) > -1;

              var found = false;
              Ember.get(obj, 'applicableCoverTypes').forEach(function (covertype) {
                if (covertype.id == coverTypeId) {
                  found = true;
                }
              });
              return found;
            });
            this.set('filteredDriverTypes', availableDriverTypesInRegardsToMode);
          }

          var packages = Ember.get(this, 'packages').filterBy('lob', lobId);
          if (packages) {
            Ember.set(this, 'filteredPackages', packages.filterBy('coverType', coverTypeId)); //Before that these were models, we needed lob.content.id. now that are plain json, we check them like this
          }

          if (coverTypeId != 'THIRD_PARTY') {
            this.set('isNtalikaDisabled', false);
            this.set('isDrivingSchoolDisabled', false);
            this.set('isVehicleAntiqueDisabled', false);
            this.set('isOwnPackageDisabled', false);
            changeset.set('ntalika', false);
            changeset.set('vehicleAntique', false);
            changeset.set('ownPackage', false);
            changeset.set('drivingSchool', false);
          }

          Ember.set(this, 'coverTypeSelected', true);
        }
      },
      vehicleRegistrationNumberChanged: function vehicleRegistrationNumberChanged(value, changeset) {
        var greeklishVersion = _npmGreekUtils.default.toGreeklish(value);
        changeset.set('vehicleRegistrationNumber', greeklishVersion);
        /*
        if (value) {
          return new RSVP.Promise((resolve, reject) => {
            debounce(this, this._fetchVehicleInfoFromTom, greeklishVersion.toUpperCase(), changeset, resolve, reject, 700);
          });
        }
        */
      },
      lookupCar: function lookupCar() {
        var vehicleRegistrationNumber = Ember.get(this, 'changeset.vehicleRegistrationNumber');
        this._fetchVehicleInfoFromTom(vehicleRegistrationNumber.toUpperCase());
      },
      skipCarLookup: function skipCarLookup() {
        var vehicleRegistrationNumber = '000000';
        Ember.set(this.get('changeset'), 'vehicleRegistrationNumber', vehicleRegistrationNumber);
        this._fetchVehicleInfoFromTom(vehicleRegistrationNumber.toUpperCase());
      },
      clearCar: function clearCar() {
        this.set('_carDataLoaded', false);
        this.set('_carDataLoading', false);
        this.set('_carDataFound', false);
        this.set('_carDataNotMatchingLob', false);
        this.set('_carDataNotMatchingLobMessage', []);

        this.changeset.setProperties({
          vehicleRegistrationNumberChanged: undefined,
          tomInfo: undefined,
          vehicleChassisNumber: undefined,
          vehicleEngineCapacity: undefined,
          vehicleEnginePower: undefined,
          vehicleModel: undefined,
          vehicleManufactureYear: undefined,
          vehicleNumberOfSeats: undefined,
          leftHandDrive: undefined,
          vehicleMake: undefined
        });
      },
      ntalikaChanged: function ntalikaChanged(value, changeset) {
        changeset.set('ntalika', value);
        changeset.set('vehicleAntique', false);
        changeset.set('ownPackage', false);
        //this.set('isNtalikaDisabled', true);
        //this.set('isDrivingSchoolDisabled', true);
        this.set('isVehicleAntiqueDisabled', true);
        this.set('isOwnPackageDisabled', true);
      },
      drivingSchoolChanged: function drivingSchoolChanged(value, changeset) {
        changeset.set('drivingSchool', value);
        changeset.set('vehicleAntique', false);
        changeset.set('ownPackage', false);
        //this.set('isNtalikaDisabled', true);
        //this.set('isDrivingSchoolDisabled', true);
        this.set('isVehicleAntiqueDisabled', true);
        this.set('isOwnPackageDisabled', true);
      },
      vehicleAntiqueChanged: function vehicleAntiqueChanged(value, changeset) {
        changeset.set('vehicleAntique', value);
        changeset.set('ntalika', false);
        changeset.set('drivingSchool', false);
        changeset.set('ownPackage', false);

        this.set('isNtalikaDisabled', true);
        this.set('isDrivingSchoolDisabled', true);
        this.set('isOwnPackageDisabled', true);
      },
      ownPackageChanged: function ownPackageChanged(value, changeset) {

        changeset.set('ownPackage', value);
        changeset.set('ntalika', false);
        changeset.set('drivingSchool', false);
        changeset.set('vehicleAntique', false);

        this.set('isNtalikaDisabled', true);
        this.set('isDrivingSchoolDisabled', true);
        this.set('isVehicleAntiqueDisabled', true);
      },
      startDateTimeChanged: function startDateTimeChanged(value, changeset) {
        changeset.set('startDateTime', value);

        if (value) {
          var inFourMonths;
          if (this.get('covernoteMode')) {
            inFourMonths = (0, _moment.default)(value).add(4, 'month').subtract(1, 'day');
          } else {
            inFourMonths = (0, _moment.default)(value).add(1, 'year').subtract(1, 'day');
          }

          inFourMonths.set({ 'hour': 23, 'minute': 59 });

          changeset.set('endDateTime', inFourMonths.toDate());
        }
      },
      coverPackageChanged: function coverPackageChanged(value, changeset) {
        changeset.set('coverPackage', value);
        var values;
        if (value) {
          values = Ember.get(value, 'values');
        } else {
          values = {};
        }
        if (Ember.get(values, 'personalAccident')) {
          changeset.set('personalAccident', Ember.get(values, 'personalAccident'));
        } else {
          changeset.set('personalAccident', undefined);
        }
        if (Ember.get(values, 'windscreen')) {
          changeset.set('windscreen', Ember.get(values, 'windscreen'));
        } else {
          changeset.set('windscreen', undefined);
        }
        if (Ember.get(values, 'driversType')) {
          changeset.set('driversType', Ember.get(this, 'driverTypes').findBy('id', Ember.get(values, 'driversType')));
        }

        var booleans = ['ownerDrivingOtherVehicles', 'ncdProtection', 'trailer', 'maliciousDamageCover', 'stormCover', 'roadAssistance', 'hailstormCover'];
        booleans.forEach(function (cover) {
          if (Ember.get(values, cover)) {
            changeset.set(cover, true);
          } else {
            changeset.set(cover, false);
          }
        });
        if (value) {
          Ember.set(this, 'additionalCoversLocked', true);
        } else {
          Ember.set(this, 'additionalCoversLocked', false);
        }
      }
    }
  });
});