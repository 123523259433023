define('minerva-frontend/validations/proposals/mi-accident', ['exports', 'ember-changeset-validations/validators', 'ember-changeset-conditional-validations/validators/sometimes'], function (exports, _validators, _sometimes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    miAccidentPlan: [(0, _validators.validatePresence)(true)],
    weight: (0, _sometimes.default)([(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ integer: true })], function (changes, content) {
      return !(Ember.get(changes, 'isClientCompany') === true || Ember.get(content, 'isClientCompany') === true);
    }),
    height: (0, _sometimes.default)([(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ integer: true })], function (changes, content) {

      return !(Ember.get(changes, 'isClientCompany') === true || Ember.get(content, 'isClientCompany') === true);
    }),
    otherInsuredPerson: (0, _sometimes.default)([(0, _validators.validatePresence)(true)], function (changes, content) {
      return Ember.get(changes, 'isClientCompany') === true || Ember.get(content, 'isClientCompany') === true;
    })
  };
});