define('minerva-frontend/routes/covernote/edit', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    currentUser: Ember.inject.service(),
    model: function model(params) {
      if (this.get('currentUser.user.migratedToNewVersion')) {
        console.log("User migrated to new versoin. Loading new covernote forms...");
        return this.get('store').findRecord('covernote', params.covernoteId).then(function (covernote) {
          var stateInitializers = {
            state: 'COVERNOTE_FINALIZATION',
            values: {
              covernote: covernote
            }
          };
          return Ember.RSVP.hash({
            endorsementMode: covernote.get('isEndorsement'),
            covernote: covernote,
            stateInitializers: stateInitializers
          });
        });
      } else {
        return this.get('store').findRecord('covernote', params.covernoteId);
      }
    }
  });
});