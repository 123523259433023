define("minerva-frontend/validations/proposals/elnm-helper", ["exports", "minerva-frontend/validations/proposals/el-helper", "minerva-frontend/validations/proposals/m-helper"], function (exports, _elHelper, _mHelper) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var assign = Ember.assign;


  var ElnMHelperProposalValidations = {};

  exports.default = assign({}, _elHelper.default, _mHelper.default, ElnMHelperProposalValidations);
});