define('minerva-frontend/components/covernotes/admin/approve-dissaprove-form/component', ['exports', 'minerva-frontend/validations/covernote-approval-dissaproval'], function (exports, _covernoteApprovalDissaproval) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    CovernoteApprovalDissaprovalValidations: _covernoteApprovalDissaproval.default,
    store: Ember.inject.service()
  });
});