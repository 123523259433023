define('minerva-frontend/components/covernotes/list-table/component', ['exports', 'ember-models-table/components/models-table-server-paginated', 'minerva-frontend/mixins/list-table'], function (exports, _modelsTableServerPaginated, _listTable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _modelsTableServerPaginated.default.extend(_listTable.default, {
    classNames: "covernote-table-wrapper",
    currentUser: Ember.inject.service(),
    router: Ember.inject.service(),

    columnsDefault: [{
      "propertyName": "documentNumber",
      "routeName": "covernote.covernote",
      "className": "document-number-col"
    }, {
      "propertyName": "vehicleRegistrationNumber",
      "title": "Vehicle Reg No.",
      "disableSorting": true,
      "className": "vehicle-reg-number-col"
    }, {
      "propertyName": "documentDateTimeFormatted",
      "title": "Created on",
      "disableSorting": true
    }, {
      "propertyName": "startDateTimeFormatted",
      "title": "Starts On",
      "disableFiltering": true,
      "disableSorting": true
    }, {
      "propertyName": "endDateTimeFormatted",
      "title": "Until",
      "disableFiltering": true,
      "disableSorting": true
    }, {
      "propertyName": "sumInsured",
      "disableFiltering": true,
      "disableSorting": true
    }, {
      "propertyName": "pendingCancellation",
      "disableFiltering": true,
      "disableSorting": true,
      "component": "covernotes/list-table-notes"
    }, {
      "propertyName": "documentTypeLabel",
      "title": "Type",
      "disableFiltering": true,
      "disableSorting": true
    }, {
      "propertyName": "coverType.display",
      "title": "Cover",
      "disableFiltering": true,
      "disableSorting": true
    }, {
      "propertyName": "lineOfBusiness.display",
      "title": "LOB",
      "disableFiltering": true,
      "disableSorting": true
    }],

    showState: false,
    showOwner: Ember.computed.or('currentUser.user.isAdmin', 'currentUser.user.isUnderwriter', 'currentUser.user.isOfficeHead'),
    columns: Ember.computed('showState', 'showOwner', function () {
      var columns = Ember.get(this, 'columnsDefault').slice(0);
      if (Ember.get(this, 'showState')) {
        columns.insertAt(2, {
          "propertyName": "state",
          "disableFiltering": true,
          "disableSorting": true
        });
      }
      if (Ember.get(this, 'showOwner')) {
        columns.insertAt(2, {
          "propertyName": "ownerID",
          "title": "Owner",
          "disableFiltering": true,
          "disableSorting": true
        });
      }
      return columns;
    }),
    actions: {
      clickOnRow: function clickOnRow(index, obj) {
        this.get('router').transitionTo('covernote.covernote', obj.get('id'));
      }
    }

  });
});