define('minerva-frontend/helpers/get-lob-suffix', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getLobSuffix = getLobSuffix;
  function getLobSuffix(params /*, hash*/) {
    var selectedLobID = params[0];
    switch (selectedLobID) {
      case 'PRIVATE':
        return 'private';
      case 'COMMERCIAL':
        return 'commercial';
      case 'MOTORCYCLE':
        return 'motorcycle';
      case 'SPECIAL_TYPE':
        return 'special-type';
      case 'PRIVATE_BUS':
        return 'private-bus';
      //return 'motor';
      case 'EMPLOYER_LIABILITY_HELPER':
        return 'el-helper';
      case 'MEDICAL_HELPER':
        return 'm-helper';
      case 'EMPLOYER_LIABILITY_AND_MEDICAL_HELPER':
        return 'elnm-helper';
      case 'MI_HEALTH':
        return 'mi-health';
      case 'MI_ACCIDENT':
        return 'mi-accident';
      case 'MEDICAL_VISITOR':
        return 'm-visitor';
      case 'MI_PLACE':
        return 'mi-place';
      default:
        return '';
    }
  }

  exports.default = Ember.Helper.helper(getLobSuffix);
});