define('minerva-frontend/routes/settings/parameters/vehicle-categories', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return Ember.A([]);
    }
  });
});