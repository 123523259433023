define('minerva-frontend/components/forms/endorsement-wrapper/component', ['exports', 'minerva-frontend/models/minerva-document', 'moment', 'minerva-frontend/config/environment'], function (exports, _minervaDocument, _moment, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var keys = Object.keys;
  exports.default = Ember.Component.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    params: undefined,
    documentTypes: undefined,

    businessLines: Ember.computed('params.business-lines', function () {
      var allowedLobs = ['PRIVATE', 'COMMERCIAL', 'MOTORCYCLE', 'SPECIAL_TYPE', 'PRIVATE_BUS'];
      return Ember.get(this, 'params.business-lines').filter(function (lob) {
        return allowedLobs.contains(lob.id);
      });
    }),
    _contractLoading: false,
    _contractLoaded: false,
    _contractFound: false,
    _carChangeLocked: false,
    _clientDataLocked: false,
    existingContractReference: '',
    lineOfBusiness: undefined,
    documentType: undefined,
    model: undefined,

    init: function init() {
      this._super.apply(this, arguments);
    },
    willDestroyElement: function willDestroyElement() {
      var model = this.get('model');
      if (model) {
        // && model.get('isNew')) {
        model.rollbackAttributes();
        //this.controller.get('model').destroyRecord();
        //this.controller.set('model', undefined);
      }
      this._super.apply(this, arguments);
    },

    actions: {
      checkEnter: function checkEnter(followUpEvent, e) {
        if (e.key === 'Enter') {
          this.get('actions.' + followUpEvent).call(this);
        }
      },
      emptySubmit: function emptySubmit() {
        return false;
      },
      loadEndorsementContract: function loadEndorsementContract() {
        var _this = this;

        var existingContractReference = Ember.get(this, 'existingContractReference');
        var documentType = Ember.get(this, 'documentType');
        var lineOfBusiness = Ember.get(this, 'lineOfBusiness');

        if (!existingContractReference) {
          return;
        }
        Ember.set(this, '_contractFound', false);
        Ember.set(this, '_contractLoaded', false);
        Ember.set(this, '_contractLoading', true);
        var url = _environment.default.APP.apiUrl + "/covernotes/policydetails";

        var headers = {};
        this.get('session').authorize('authorizer:jwt', function (headerName, headerValue) {
          headers[headerName] = headerValue;
        });
        var notifications = Ember.get(this, 'notifications');
        this.get('ajax').request(url, {
          method: 'GET',
          headers: headers,
          contentType: "application/x-www-form-urlencoded; charset=UTF-8",
          data: {
            lob: Ember.get(lineOfBusiness, 'comtecCode'),
            policyId: existingContractReference
          }
        }).then(function (response) {
          //let json = response;
          console.log("Contract found:", response);
          var initProperties = {};
          keys(_minervaDocument.documentSchema).forEach(function (key) {
            initProperties[key] = Ember.get(response, key);
          });
          if (initProperties.dateOfBirth) {
            initProperties.dateOfBirth = new _moment.default(initProperties.dateOfBirth, 'YYYY-MM-DD').toDate();
          }

          delete initProperties.documentType; //This will be an endorsement anw
          delete initProperties.state; //Server generated

          delete initProperties.documentNumber; //Server generated
          delete initProperties.documentDateTime; //Server generated

          var ownerId = initProperties.owner;
          delete initProperties.owner; //Server generated
          var nationalityId = initProperties.nationality;
          delete initProperties.nationality;
          var occupationId = initProperties.occupation;
          delete initProperties.occupation;
          var streetId = initProperties.street;
          delete initProperties.street;
          var vehicleMakeId = initProperties.vehicleMake;
          delete initProperties.vehicleMake;

          var model = _this.get('store').createRecord('covernote', initProperties); //this.get('model');
          model.set('drivers', initProperties.drivers);

          //Clear document type
          model.set('documentType', documentType);

          if (documentType === 'DEFINITE_ENDORSEMENT') {
            _this.setProperties({
              _carChangeLocked: true,
              _clientDataLocked: true
            });
          } else {
            _this.setProperties({
              _carChangeLocked: false,
              _clientDataLocked: false
            });
          }
          //

          model.set('existingContractReference', existingContractReference);

          var promises = [];
          if (ownerId) {
            promises.push(_this.get('store').findRecord('user', ownerId).then(function (obj) {
              model.set('owner', obj);
            }));
          }
          if (nationalityId) {
            promises.push(_this.get('store').findRecord('country', nationalityId).then(function (obj) {
              model.set('nationality', obj);
            }));
          }
          if (occupationId) {
            promises.push(_this.get('store').findRecord('occupation', occupationId).then(function (obj) {
              model.set('occupation', obj);
            }));
          }
          if (streetId) {
            promises.push(_this.get('store').findRecord('street', streetId).then(function (obj) {
              model.set('street', obj);
            }));
          }
          if (vehicleMakeId) {
            promises.push(_this.get('store').findRecord('minerva-make', vehicleMakeId).then(function (obj) {
              model.set('vehicleMake', obj);
            }));
          }
          Ember.RSVP.Promise.all(promises).catch(function () /*e*/{
            //console.log("A promise returned an error", e);
          }).finally(function () {

            _this.set('model', model);
            Ember.set(_this, '_contractLoading', false);
            Ember.set(_this, '_contractLoaded', true);
            Ember.set(_this, '_contractFound', true);
            notifications.success('Policy with number ' + existingContractReference + ' found. Loading...');
          });
        }).catch(function (response) {
          console.log("higher error:", response);
          Ember.set(_this, '_contractLoading', false);
          Ember.set(_this, '_contractLoaded', true);
          Ember.set(_this, '_contractFound', false);
          notifications.warning('Policy with number ' + existingContractReference + ' could not be found.');
        });
      },
      clearEndorsementContract: function clearEndorsementContract() {
        Ember.set(this, '_contractLoading', false);
        Ember.set(this, '_contractLoaded', false);
        Ember.set(this, '_contractFound', false);
        //Clear model
        if (this.get('model')) {
          var model = this.get('model');
          model.destroyRecord();
        }
        Ember.set(this, 'model', undefined);
      }
    }
  });
});