define('minerva-frontend/routes/covernote/new-endorsement', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    currentUser: Ember.inject.service(),
    beforeModel: function beforeModel() {
      if (!this.get('currentUser.user.canIssueEndorsement')) {
        return this.transitionTo('index');
      }
    }
  });
});