define('minerva-frontend/components/helpers/filter-by-val-in-array/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    unfilteredArray: null,
    filterArrayParam: '',
    filterValueToLookup: null,
    hasValue: Ember.computed.bool('filterValueToLookup'),
    onEmptyValueReturnAll: false,
    filteredArray: Ember.computed('unfilteredArray.[]', 'filterArrayParam', 'filterValueToLookup', function () {
      var param_arr = this.get('filterArrayParam');
      var val = this.get('filterValueToLookup');
      if (!this.get('hasValue') && this.get('onEmptyValueReturnAll')) {
        return this.get('unfilteredArray');
      }
      if (!this.get('unfilteredArray')) {
        return undefined;
      }
      return this.get('unfilteredArray').filter(function (obj) {
        return obj.get(param_arr).indexOf(val) > -1;
      });
    })
  });
});