define('minerva-frontend/components/lob-forms/private-proposal/component', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    filteredCoverTypes: Ember.computed.alias('params.cover-types'),
    filteredVehicleCategories: Ember.computed.filterBy('params.vehicle-categories', 'lob', 'PRIVATE'),
    filteredBodyTypes: Ember.computed.filterBy('params.body-types', 'lob', 'PRIVATE'),
    filteredPackages: Ember.computed.filterBy('params.packages', 'lob', 'PRIVATE'),
    initialDriversType: null, //When transforming a proposal, this should be set on form loading.
    coverFilteredPackages: Ember.computed('filteredPackages.[]', '_changeset.coverType.id', 'initialDriversType', function () {
      var _this = this;

      var coverTypeId = Ember.get(this, '_changeset.coverType.id');
      var packages = Ember.get(this, 'filteredPackages');
      if (packages) {
        var filteredPackages = packages.filterBy('coverType', coverTypeId);
        if (this.get('initialDriversType')) {
          //Means this was a covernote before, and we cannot allow a package that does not match the drivers type
          // I need to exclude PRIVATE_PLUS
          filteredPackages = filteredPackages.filter(function (pack) {
            if (pack.id === 'PRIVATE_PLUS' && _this.get('initialDriversType') !== 'ANY_DRIVER_WITH_EXCLUSION') {
              return false;
            } else {
              return true;
            }
          });
        }
        return filteredPackages;
      } else {
        return [];
      }
    }), //THIS IS SET WHEN A COVER TYPE IS SELECTED! SHOULD BE USED IN THE TEMPLATE INSTEAD OF THE FILTERED PACKAGES
    filteredDriverTypes: null, //Gets calculated below, when a cover type gets selected!

    isClientCompany: Ember.computed.equal('clientIdType', 'COMPANY_REGISTRATION'),
    isComprehensive: Ember.computed.equal('_changeset.coverType.id', 'COMPREHENSIVE'),
    isFireAndTheft: Ember.computed.equal('_changeset.coverType.id', 'FIRE_AND_THEFT'),
    isThirdParty: Ember.computed.equal('_changeset.coverType.id', 'THIRD_PARTY'),
    vehicleTypes: Ember.computed.alias('params.vehicle-types'),
    isFourWheelsMotorcycle: Ember.computed.equal('_changeset.vehicleCategory.id', 'FOUR_WHEEL_MOTORCYCLE'),

    vehicleCategoryIsSelected: Ember.computed.bool('_changeset.vehicleCategory.id'),
    coverPackageIsSelected: Ember.computed.bool('_changeset.coverPackage.id'),
    isPolicyExcessVisible: Ember.computed.alias('isComprehensive'),

    _calculateExcess: function _calculateExcess() {
      var excess = 240;
      var sumInsured = this.get('_changeset.sumInsured');
      if (!sumInsured) {
        sumInsured = 0;
      }
      if (sumInsured / 100 < 240) {
        excess = 240;
      } else {
        excess = sumInsured / 100;
      }
      return Math.round(excess);
    },
    //TODO - Shis should go into validation!!!!

    isSumInsuredVisible: Ember.computed.or('isFireAndTheft', 'isComprehensive'),
    isNoClaimsDiscountVisible: Ember.computed.alias('isComprehensive'),
    isAdditionalExcessVisible: Ember.computed.alias('isComprehensive'),
    noClaimsDiscounts: Ember.computed.alias('params.no-claim-discounts'),
    additionalExcesses: Ember.computed.alias('params.additional-excesses'),

    isRoadAssistanceVisible: Ember.computed.not('isFourWheelsMotorcycle'),
    isWindScreenVisible: Ember.computed.not('isFourWheelsMotorcycle'),
    isMalicousDamageCoverVisible: Ember.computed.alias('isComprehensive'),
    isMalicousFireVisible: Ember.computed.alias('isFireAndTheft'), //maliciousFire is always locked as it's set using a package only;
    isStormCoverVisible: Ember.computed.alias('isComprehensive'),
    isNcdProtectionVisible: Ember.computed.alias('isComprehensive'),
    isNclProtectionCoverVisible: Ember.computed.alias('isThirdParty'),
    isLossOfUseVisible: Ember.computed.alias('isComprehensive'),
    isHailstormCoverVisible: Ember.computed.alias('isComprehensive'),
    init: function init() {
      this._super.apply(this, arguments);
      this.set('filteredDriverTypes', this._getAvailableDriversBestOnCoverTypeId('THIRD_PARTY'));

      var stateInitializers = this.get('stateInitializers'); //Should be passed when initializing the object
      var initState = stateInitializers ? stateInitializers.state : false;
      if (initState) {
        //Means car is already preloaded!
        if (initState === 'COVERNOTE_TO_PROPOSAL') {
          Ember.set(this, '_coverInfoLocked', true);
          Ember.set(this, '_specialTermsLocked', true);
          Ember.set(this, '_driversLocked', true);
          Ember.set(this, 'initialDriversType', this.get('_changeset.driversType.id'));
        } else if (initState === 'CONTRACT_TO_ENDORSEMENT') {
          Ember.set(this, '_coverInfoLocked', true);
          Ember.set(this, '_driverTypeLocked', true);
          Ember.set(this, '_discountsLoadingsLocked', true);
          Ember.set(this, '_additionalCoversLocked', true);

          //In endorsement, they can change car if they want to
        }
      }
    },
    _getAvailableDriversBestOnCoverTypeId: function _getAvailableDriversBestOnCoverTypeId(coverTypeId) {

      var driverTypes = this.get('params.driver-types');
      //We should also block 'ANY_DRIVER if user is an agent
      var user = this.get('currentUser.user');
      var isUnderwriter = user.get("isHeadUnderwriter") || user.get("isUnderwriter");
      if (driverTypes) {
        var availableDriverTypesInRegardsToMode = driverTypes.filter(function (obj) {
          //return get(obj, 'applicableCoverTypes').indexOf(coverTypeId) > -1;
          if (!isUnderwriter && obj.id === 'ANY_DRIVER') {
            return false; //Block ANY DRIVER for non admins
          }
          var found = false;
          Ember.get(obj, 'applicableCoverTypes').forEach(function (covertype) {
            if (covertype.id === coverTypeId) {
              found = true;
            }
          });
          return found;
        });
        return availableDriverTypesInRegardsToMode;
      } else {
        return [];
      }
    },

    actions: {
      coverTypeChanged: function coverTypeChanged(value, changeset) {
        changeset.set('coverType', value);
        if (!value) {
          //clear up everything
          this.set('_changeset.policyExcess', null);
          this.set('_changeset.sumInsured', null);
          return;
        }
        var coverTypeId = Ember.get(value, 'id'); //value;
        var availableDriverTypesInRegardsToMode = this._getAvailableDriversBestOnCoverTypeId(coverTypeId);
        this.set('filteredDriverTypes', availableDriverTypesInRegardsToMode);
        //Also clear any selected driver type up to now. THEY SHOuOLD re-choose
        this.set('_changeset.driversType', null);

        if (!this.get('isSumInsuredVisible')) {
          this.set('_changeset.sumInsured', null);
        }
        if (!this.get('isPolicyExcessVisible')) {
          this.set('_changeset.policyExcess', null);
        }
      },
      vehicleCategoryChanged: function vehicleCategoryChanged(value, changeset) {
        changeset.set('vehicleCategory', value);
        var vehicleCategoryId = null;
        if (value) {
          vehicleCategoryId = Ember.get(value, 'id');
          if (vehicleCategoryId !== 'FOUR_WHEEL_MOTORCYCLE') {
            changeset.set('windscreen', 300);
            changeset.set('roadAssistance', true);
          } else {
            changeset.set('windscreen', '');
            changeset.set('roadAssistance', false);
          }
        } else {
          changeset.set('windscreen', '');
          changeset.set('roadAssistance', false);
        }
      },
      coverPackageChanged: function coverPackageChanged(value, changeset) {
        var packageId = null;
        if (value) {
          packageId = Ember.get(value, 'id');
        }
        /*
        ADDITIONAL COVERS
        changeset.set('trailer',false);
        changeset.set('roadAssistance',false);
        changeset.set('maliciousDamageCover',false);
        changeset.set('stormCover',false);
        changeset.set('ncdProtection',false);
        changeset.set('nclProtectionCover',false);
        changeset.set('ownerDrivingOtherVehicles',false);
        changeset.set('lossOfUse',false);
        changeset.set('hailstormCover',false);
        changeset.set('personalAccident','');
        changeset.set('windscreen','');
           */
        /*
        Note: If four wheel motorcycle, we do not put windscreen
        */
        var is_four_wheel_motorcycle = false;
        var vehicleCategory = changeset.get('vehicleCategory');
        if (vehicleCategory) {
          if (vehicleCategory.id === 'FOUR_WHEEL_MOTORCYCLE') {
            is_four_wheel_motorcycle = true;
          }
        }

        var fieldsToLock = {
          trailer: true,
          roadAssistance: true,
          maliciousDamageCover: true,
          maliciousFire: true,
          stormCover: true,
          ncdProtection: true,
          nclProtectionCover: true,
          ownerDrivingOtherVehicles: true,
          lossOfUse: true,
          hailstormCover: true,
          personalAccident: true,
          windscreen: true,
          driversType: false
        };
        switch (packageId) {

          case 'PRIVATE_PLUS':
            //Private (LOB11) Third Party – Plus
            /*
            Personal Accident €10,000
            Windscreen €400
            Any driver with restrictions
            Owner driving other vehicles
            Trailer
            Road Assistance
            Πρέπει επίσης να μπορούν να επιλέξουν την κάλυψη NCL Protection
             */
            changeset.set('trailer', true); //!
            changeset.set('roadAssistance', true); //!
            changeset.set('maliciousDamageCover', false);
            changeset.set('maliciousFire', false);
            changeset.set('stormCover', false);
            changeset.set('ncdProtection', false);
            changeset.set('nclProtectionCover', false);
            changeset.set('ownerDrivingOtherVehicles', true); //!
            changeset.set('lossOfUse', false);
            changeset.set('hailstormCover', false);
            changeset.set('personalAccident', 10000); //!
            if (!is_four_wheel_motorcycle) {
              changeset.set('windscreen', 400); //!
            }
            //We also change drivers type - We should lock this
            changeset.set('driversType', Ember.get(this, 'filteredDriverTypes').findBy('id', 'ANY_DRIVER_WITH_EXCLUSION'));
            fieldsToLock.nclProtectionCover = false; //Do not lock this one
            fieldsToLock.driversType = true; //Lock drivers yea..
            break;
          case 'TP_S':
            //Private (LOB11) Third Party – TP-S
            /*
            Personal Accident €5,000
            Windscreen €300
            Road Assistance
             Όλες οι υπόλοιπες καλύψεις θα πρέπει να είναι κλειδωμένες
             */
            changeset.set('trailer', false);
            changeset.set('roadAssistance', true); //!
            changeset.set('maliciousDamageCover', false);
            changeset.set('maliciousFire', false);
            changeset.set('stormCover', false);
            changeset.set('ncdProtection', false);
            changeset.set('nclProtectionCover', false);
            changeset.set('ownerDrivingOtherVehicles', false);
            changeset.set('lossOfUse', false);
            changeset.set('hailstormCover', false);
            changeset.set('personalAccident', 500); //!
            if (!is_four_wheel_motorcycle) {
              changeset.set('windscreen', 300); //!
            }
            break;
          case 'SILVER':
            //Private (LOB11) Fire & Theft – Silver
            /*
            Personal Accident €10,000
            Windscreen €400
            Owner driving other vehicles
            Trailer
            Malicious Fire
            Road Assistance
            Δεν υπάρχουν επιπλέον καλύψεις για επιλογή
              */
            changeset.set('trailer', true); //!
            changeset.set('roadAssistance', true); //!
            changeset.set('maliciousDamageCover', false);
            changeset.set('maliciousFire', true); //!
            changeset.set('stormCover', false);
            changeset.set('ncdProtection', false);
            changeset.set('nclProtectionCover', false);
            changeset.set('ownerDrivingOtherVehicles', true); //!
            changeset.set('lossOfUse', false);
            changeset.set('hailstormCover', false);
            changeset.set('personalAccident', 10000); //!
            if (!is_four_wheel_motorcycle) {
              changeset.set('windscreen', 400); //!
            }

            break;
          case 'COMPREHENSIVE_S':
            //Private (LOB11) Comprehensive –S
            /*
            Personal Accident €5,000
            Windscreen €300
            Road Assistance
            Όλες οι υπόλοιπες καλύψεις θα πρέπει να είναι κλειδωμένες
             */
            changeset.set('trailer', false);
            changeset.set('roadAssistance', true); //!
            changeset.set('maliciousDamageCover', false);
            changeset.set('maliciousFire', false);
            changeset.set('stormCover', false);
            changeset.set('ncdProtection', false);
            changeset.set('nclProtectionCover', false);
            changeset.set('ownerDrivingOtherVehicles', false);
            changeset.set('lossOfUse', false);
            changeset.set('hailstormCover', false);
            changeset.set('personalAccident', 5000); //!
            if (!is_four_wheel_motorcycle) {
              changeset.set('windscreen', 300); //!
            }
            break;
          case 'GOLD':
            //Private (LOB11) Comprehensive – Gold
            /*
            Personal Accident €10,000
            Windscreen €600
            Owner driving other vehicles
            Trailer
            Malicious Damage
            Storm
            Hailstorm
            NCD Protection
            Road Assistance
            Δεν υπάρχουν επιπλέον καλύψεις για επιλογή
             */
            changeset.set('trailer', false);
            changeset.set('roadAssistance', true); //!
            changeset.set('maliciousDamageCover', true); //!
            changeset.set('maliciousFire', false);
            changeset.set('stormCover', true); //!
            changeset.set('ncdProtection', true); //!
            changeset.set('nclProtectionCover', false);
            changeset.set('ownerDrivingOtherVehicles', true); //!
            changeset.set('lossOfUse', false);
            changeset.set('hailstormCover', true); //!
            changeset.set('personalAccident', 10000); //!
            if (!is_four_wheel_motorcycle) {
              changeset.set('windscreen', 600); //!
            }
            break;
          default:
            changeset.set('trailer', false);
            changeset.set('roadAssistance', false);
            changeset.set('maliciousDamageCover', false);
            changeset.set('maliciousFire', false);
            changeset.set('stormCover', false);
            changeset.set('ncdProtection', false);
            changeset.set('nclProtectionCover', false);
            changeset.set('ownerDrivingOtherVehicles', false);
            changeset.set('lossOfUse', false);
            changeset.set('hailstormCover', false);
            changeset.set('personalAccident', '');
            changeset.set('windscreen', '');
        }
        if (packageId) {
          //this.set('__additionalCoversLocked', true);
          this.set('_fieldsToLock', fieldsToLock);
        } else {
          //this.set('_additionalCoversLocked', false);
          this.set('_fieldsToLock', {});
        }
        changeset.set('coverPackage', value);
      },
      emptySubmit: function emptySubmit() {},
      submit: function submit(model, submissionStatus) {
        this.onSubmit(model, submissionStatus); //Let it propagate to motor lob-form-wrapper to handle instead. no custom logic here
      }
    }

  });
});