define('minerva-frontend/components/lob-forms/mi-health-proposal/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    mihealthExcesses: Ember.computed.alias('params.mihealth-excess'),
    coinsurancePercentages: Ember.computed.alias('params.mihealth-coinsurance'),
    init: function init() {
      this._super.apply(this, arguments);
      this.set('_changeset.coinsurancePercentage', this.get('coinsurancePercentages').findBy('id', 'COINSURANCE_1'));
      this.set('_changeset.annualExcess', this.get('mihealthExcesses').findBy('id', 'EXCESS_1'));
    },

    actions: {
      emptySubmit: function emptySubmit() {},
      submit: function submit(model, submissionStatus) {
        //Here we should handle Lob specific logic. When done, pass the stuff on to the form-wrapper to submit the info!
        this.onSubmit(model, submissionStatus);
      }
    }
  });
});