define('minerva-frontend/models/occupation', ['exports', 'ember-data', 'ember-custom-actions'], function (exports, _emberData, _emberCustomActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    findByDisplayName: (0, _emberCustomActions.resourceAction)('search/findByDisplayContainingIgnoreCaseAndDisabledIsFalse', { type: 'GET' }),
    display: _emberData.default.attr('string')
  });
});