define('minerva-frontend/validators/special/driver-excess', ['exports', 'ember-changeset-validations/utils/validation-errors', 'ember-validators', 'ember-changeset-validations/validators'], function (exports, _validationErrors, _emberValidators, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = validateDriverExcess;
  function validateDriverExcess() {
    var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    return function (key, newValue, oldValue, changes, content) {
      return true;
      /*
      console.log("Validating Driver's excess..", newValue);
      var globalExcess;
      if (get(changes,'policyExcess')) {
        globalExcess = get(changes,'policyExcess');
      } else {
        globalExcess = get(content,'policyExcess');
      }
      console.log("Driver excess globalExcess is:", globalExcess);
      let result = undefined;
      if (globalExcess && newValue) {
         if (newValue < globalExcess) {
          return "Driver excess must be greater than global policy excess";
        }
      } else {
        result = validate('number', newValue, options, null, key);
      }
       return (result === true) ? true : buildMessage(key, result, newValue, options);
      */
    };
  }
});