define('minerva-frontend/components/lob-forms/commercial-proposal/component', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    currentUser: Ember.inject.service(),
    filteredCoverTypes: Ember.computed.alias('params.cover-types'),
    filteredVehicleCategories: Ember.computed.filterBy('params.vehicle-categories', 'lob', 'COMMERCIAL'),
    filteredBodyTypes: Ember.computed.filterBy('params.body-types', 'lob', 'COMMERCIAL'),
    filteredPackages: Ember.computed.filterBy('params.packages', 'lob', 'COMMERCIAL'),
    coverFilteredPackages: Ember.computed('filteredPackages.[]', '_changeset.coverType.id', function () {
      var coverTypeId = Ember.get(this, '_changeset.coverType.id');
      var packages = Ember.get(this, 'filteredPackages');
      if (packages) {
        return packages.filterBy('coverType', coverTypeId);
      }
    }), //THIS IS SET WHEN A COVER TYPE IS SELECTED! SHOULD BE USED IN THE TEMPLATE INSTEAD OF THE FILTERED PACKAGES
    filteredDriverTypes: null, //Gets calculated below, when a cover type gets selected!
    isClientCompany: Ember.computed.equal('clientIdType', 'COMPANY_REGISTRATION'),
    isComprehensive: Ember.computed.equal('_changeset.coverType.id', 'COMPREHENSIVE'),
    isThirdParty: Ember.computed.equal('_changeset.coverType.id', 'THIRD_PARTY'),
    isFireAndTheft: Ember.computed.equal('_changeset.coverType.id', 'FIRE_AND_THEFT'),
    isPolicyExcessVisible: Ember.computed.alias('isComprehensive'),
    _calculateExcess: function _calculateExcess() {
      var excess = 240;
      var sumInsured = this.get('_changeset.sumInsured');
      if (!sumInsured) {
        sumInsured = 0;
      }
      if (sumInsured / 100 < 240) {
        excess = 240;
      } else {
        excess = sumInsured / 100;
      }
      return Math.round(excess);
    },
    //TODO - Shis should go into validation!!!!

    isNoClaimsDiscountVisible: Ember.computed.alias('isComprehensive'),
    isAdditionalExcessVisible: Ember.computed.alias('isComprehensive'),
    noClaimsDiscounts: Ember.computed.alias('params.no-claim-discounts'),
    additionalExcesses: Ember.computed.alias('params.additional-excesses'),

    //isSumInsuredVisible: alias('isComprehensive'),
    isSumInsuredVisible: Ember.computed.or('isFireAndTheft', 'isComprehensive'),

    isMalicousDamageCoverVisible: Ember.computed.alias('isComprehensive'),
    isMalicousFireVisible: Ember.computed.alias('isFireAndTheft'), //maliciousFire is always locked as it's set using a package only;
    isStormCoverVisible: Ember.computed.alias('isComprehensive'),
    isNcdProtectionVisible: Ember.computed.alias('isComprehensive'),
    isNclProtectionCoverVisible: Ember.computed.alias('isThirdParty'),
    isLossOfUseVisible: Ember.computed.alias('isComprehensive'),
    isHailstormCoverVisible: Ember.computed.alias('isComprehensive'),

    isNtalikaVisible: Ember.computed.equal('_changeset.typeOfBody.id', 'LORRY'),
    isRoadAssistanceLockedCustomLogic: Ember.computed('_changeset.typeOfBody.id', '_changeset.vehicleEngineCapacity', '_changeset.ntalika', function () {
      /*
               Εάν είναι διπλοκάμπινο θα πρέπει πάντα η επιλογή να είναι επιλεγμένη και κλειδωμένη
      ·         εάν είναι νταλίκα ΔΕΝ μπορεί να γίνει επιλογή και θα πρέπει να είναι κλειδωμένο
      ·         Εάν είναι κάτι άλλο από τα πιο πάνω τότε θα πρέπει να ελέγχεις τα cc του αυτοκινήτου
      Εάν είναι <= 2800 τότε θα είναι πάντα επιλεγμένη και κλειδωμένη
      Εάν είναι > 2800 δεν θα είναι επιλεγμένη, αλλά θα ξεκλειδώνει για να μπορούν να το επιλέξουν εάν το θέλουν
           */
      var bodyType = this.get('_changeset.typeOfBody');
      var isNtalika = this.get('_changeset.ntalika');
      if (bodyType) {
        if (bodyType.id === 'DOUBLE_CABIN' || isNtalika) {
          return true; //Also need to set roadAssistance to True if diplokampino || false if isNtalika, from somewhere else
        } else {
          //We need to check car's cc's
          var cc = this.get('_changeset.vehicleEngineCapacity');
          if (cc <= 2800) {
            return true; // Should also SET TO TRUE from somewhere else
          } else {
            return false;
          }
        }
      } else {
        return false;
      }
      //TODO - An nen diplokampino to body type (pou erkete aftomata pou covernote an nen transform to proposal so we need to check pas to init
      // Prp na tickaro. An nen ntalika, na xetikaro (which means elegxo pote allasei i ntalika tzie kamno to tziame
      //Episis to logic gia to diplokampino prp na thorei tzie ta cc. Tounto logic isos na prp na mpei se topo pou to na to
      // kalo tzie sta endorsemsnts. san method triggerRoadAssistanceBasedOnBodyType()

      //There is custom logic to lock or unlock this field. At the same time, the value of the field is determined
      //based on the bodyType or the ntalika
      //an to bodyType enne LORRY, i ntalika prp nan xosmeni!
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.set('filteredDriverTypes', this._getAvailableDriversBestOnCoverTypeId('THIRD_PARTY'));

      var stateInitializers = this.get('stateInitializers'); //Should be passed when initializing the object
      var initState = stateInitializers ? stateInitializers.state : false;
      if (initState) {
        //Means car is already preloaded!
        if (initState === 'COVERNOTE_TO_PROPOSAL') {
          Ember.set(this, '_coverInfoLocked', true);
          Ember.set(this, '_specialTermsLocked', true);
          Ember.set(this, '_driversLocked', true);
          this._setRoadAssistanceValue(); //Run this to pre-populate the roadAssistance as the car details are already loaded for this
        } else if (initState === 'CONTRACT_TO_ENDORSEMENT') {
          Ember.set(this, '_coverInfoLocked', true);
          Ember.set(this, '_driverTypeLocked', true);
          Ember.set(this, '_discountsLoadingsLocked', true);
          Ember.set(this, '_additionalCoversLocked', true);
        }
      }
    },
    _setRoadAssistanceValue: function _setRoadAssistanceValue() {
      var bodyType = this.get('_changeset.typeOfBody');
      var isNtalika = this.get('_changeset.ntalika');
      if (bodyType) {
        if (bodyType.id === 'DOUBLE_CABIN') {
          this.set('_changeset.roadAssistance', true); //Also need to set roadAssistance to True if diplokampino || false if isNtalika, from somewhere else
        } else if (isNtalika) {
          this.set('_changeset.roadAssistance', false);
        } else {
          //We need to check car's cc's
          var cc = this.get('_changeset.vehicleEngineCapacity');
          if (cc <= 2800) {
            this.set('_changeset.roadAssistance', true);
          } else {
            this.set('_changeset.roadAssistance', false);
          }
        }
      } else {
        //this.set('_changeset.roadAssistance', false);
        //DO NOTHING. It means its up to the user to set it up or not. Or to a package that selected it.
      }
    },
    _getAvailableDriversBestOnCoverTypeId: function _getAvailableDriversBestOnCoverTypeId(coverTypeId) {

      var driverTypes = this.get('params.driver-types');
      //We should also block 'ANY_DRIVER if user is an agent
      var user = this.get('currentUser.user');
      var isUnderwriter = user.get("isHeadUnderwriter") || user.get("isUnderwriter");
      if (driverTypes) {
        var availableDriverTypesInRegardsToMode = driverTypes.filter(function (obj) {
          //return get(obj, 'applicableCoverTypes').indexOf(coverTypeId) > -1;
          if (!isUnderwriter && obj.id === 'ANY_DRIVER') {
            return false; //Block ANY DRIVER for non admins
          }
          var found = false;
          Ember.get(obj, 'applicableCoverTypes').forEach(function (covertype) {
            if (covertype.id === coverTypeId) {
              found = true;
            }
          });
          return found;
        });
        return availableDriverTypesInRegardsToMode;
      } else {
        return [];
      }
    },

    actions: {
      ntalikaChanged: function ntalikaChanged(value, changeset) {
        changeset.set('ntalika', value);
        changeset.set('vehicleAntique', false);
        changeset.set('ownPackage', false);
        //this.set('isNtalikaDisabled', true);
        //this.set('isDrivingSchoolDisabled', true);
        this.set('isVehicleAntiqueDisabled', true);
        this.set('isOwnPackageDisabled', true);
      },
      drivingSchoolChanged: function drivingSchoolChanged(value, changeset) {
        changeset.set('drivingSchool', value);
        changeset.set('vehicleAntique', false);
        changeset.set('ownPackage', false);
        //this.set('isNtalikaDisabled', true);
        //this.set('isDrivingSchoolDisabled', true);
        this.set('isVehicleAntiqueDisabled', true);
        this.set('isOwnPackageDisabled', true);
      },
      vehicleAntiqueChanged: function vehicleAntiqueChanged(value, changeset) {
        changeset.set('vehicleAntique', value);
        changeset.set('ntalika', false);
        changeset.set('drivingSchool', false);
        changeset.set('ownPackage', false);

        this.set('isNtalikaDisabled', true);
        this.set('isDrivingSchoolDisabled', true);
        this.set('isOwnPackageDisabled', true);
      },
      ownPackageChanged: function ownPackageChanged(value, changeset) {

        changeset.set('ownPackage', value);
        changeset.set('ntalika', false);
        changeset.set('drivingSchool', false);
        changeset.set('vehicleAntique', false);

        this.set('isNtalikaDisabled', true);
        this.set('isDrivingSchoolDisabled', true);
        this.set('isVehicleAntiqueDisabled', true);
      },
      typeOfBodyChanged: function typeOfBodyChanged(value) {
        this._setRoadAssistanceValue();
      },
      coverTypeChanged: function coverTypeChanged(value, changeset) {
        changeset.set('coverType', value);
        if (!value) {
          //clear up everything
          this.set('_changeset.policyExcess', null);
          this.set('_changeset.sumInsured', null);
          return;
        }
        var coverTypeId = Ember.get(value, 'id'); //value;
        var availableDriverTypesInRegardsToMode = this._getAvailableDriversBestOnCoverTypeId(coverTypeId);
        this.set('filteredDriverTypes', availableDriverTypesInRegardsToMode);
        //Also clear any selected driver type up to now. THEY SHOuOLD re-choose
        this.set('_changeset.driversType', null);

        if (!this.get('isSumInsuredVisible')) {
          this.set('_changeset.sumInsured', null);
        }
        if (!this.get('isPolicyExcessVisible')) {
          this.set('_changeset.policyExcess', null);
        }
      },
      coverPackageChanged: function coverPackageChanged(value, changeset) {
        var packageId = null;
        if (value) {
          packageId = Ember.get(value, 'id');
        }
        var fieldsToLock = {
          trailer: true,
          roadAssistance: true,
          maliciousDamageCover: true,
          maliciousFire: true,
          stormCover: true,
          ncdProtection: true,
          nclProtectionCover: true,
          ownerDrivingOtherVehicles: true,
          lossOfUse: true,
          hailstormCover: true,
          personalAccident: true,
          windscreen: true,
          goodsInTransitCover: true,
          goodsInTransitCoverAmount: true,
          toolOfTradeCoverAmount: true,
          driversType: false
        };

        switch (packageId) {
          case 'COMMERCIAL_THIRD_PARTY':
            /*
            Windscreen €300
            Trailer
            NCL Protection
            */
            changeset.set('trailer', true); //!
            changeset.set('roadAssistance', false); //!
            this._setRoadAssistanceValue(); //Re-Apply the custom logic. If it doesn't get it, it's gonna stay open for the user to change it
            changeset.set('maliciousDamageCover', false);
            changeset.set('maliciousFire', false);
            changeset.set('stormCover', false);
            changeset.set('ncdProtection', false);
            changeset.set('nclProtectionCover', true);
            changeset.set('ownerDrivingOtherVehicles', false); //!
            changeset.set('lossOfUse', false);
            changeset.set('hailstormCover', false);
            changeset.set('personalAccident', ''); //!
            changeset.set('windscreen', 300); //!
            changeset.set('goodsInTransitCover', false);
            changeset.set('goodsInTransitCoverAmount', '');
            changeset.set('toolOfTradeCoverAmount', '');

            //We also change drivers type - We should lock this

            fieldsToLock.personalAccident = false; //Do not lock this one
            fieldsToLock.windscreen = false; //Do not lock this one
            fieldsToLock.ownerDrivingOtherVehicles = false;
            fieldsToLock.roadAssistance = false; //Lock is not enforced on a package level. But depending on the typeOfBody, another flag my lock this field in the template
            fieldsToLock.maliciousFire = false; //Although en mporeis na thkialexeis paketo an nen Fire & Theft
            break;
          default:
            changeset.set('trailer', false); //!
            changeset.set('roadAssistance', false); //!
            this._setRoadAssistanceValue(); //Re-Apply the custom logic. If it doesn't get it, it's gonna stay open for the user to change it
            changeset.set('maliciousDamageCover', false);
            changeset.set('maliciousFire', false);
            changeset.set('stormCover', false);
            changeset.set('ncdProtection', false);
            changeset.set('nclProtectionCover', false);
            changeset.set('ownerDrivingOtherVehicles', false); //!
            changeset.set('lossOfUse', false);
            changeset.set('hailstormCover', false);
            changeset.set('personalAccident', ''); //!
            changeset.set('windscreen', ''); //!
            changeset.set('goodsInTransitCover', false);
            changeset.set('goodsInTransitCoverAmount', '');
            changeset.set('toolOfTradeCoverAmount', '');
        }

        if (packageId) {
          //this.set('__additionalCoversLocked', true);
          this.set('_fieldsToLock', fieldsToLock);
        } else {
          //this.set('_additionalCoversLocked', false);
          this.set('_fieldsToLock', {});
        }
        changeset.set('coverPackage', value);
      },
      emptySubmit: function emptySubmit() {},
      submit: function submit(model, submissionStatus) {
        this.onSubmit(model, submissionStatus); //Let it propagate to motor lob-form-wrapper to handle instead. no custom logic here
      }
    }
  });
});