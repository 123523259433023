define('minerva-frontend/models/params/vehicle-category', ['exports', 'ember-data', 'minerva-frontend/models/params/base-param'], function (exports, _emberData, _baseParam) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseParam.default.extend({
    lob: _emberData.default.belongsTo('params/business-line'),
    applicableCoverTypes: _emberData.default.attr() //hasMany('params/cover-type'),
  });
});