define('minerva-frontend/components/users/users-table/component', ['exports', 'minerva-frontend/mixins/list-table', 'ember-models-table/components/models-table-server-paginated'], function (exports, _listTable, _modelsTableServerPaginated) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _modelsTableServerPaginated.default.extend(_listTable.default, {
    classNames: ['default-table-wrapper'],
    router: Ember.inject.service(),

    columns: [{
      "propertyName": "username",
      "disableFiltering": true
      //"disableSorting": false,
    }, {
      "propertyName": "firstName",
      "disableFiltering": true
      //"disableSorting": false,
    }, {
      "propertyName": "lastName",
      "disableFiltering": true
      //"disableSorting": false
    }, {
      "propertyName": "email",
      "disableFiltering": true,
      "disableSorting": true
    }, {
      "propertyName": "mobile",
      "disableFiltering": true,
      "disableSorting": true
    }, {
      "propertyName": "landline",
      "disableFiltering": true,
      "disableSorting": true
    }, {
      "propertyName": "companyName",
      "disableFiltering": true,
      "disableSorting": true
    }, {
      "propertyName": "frontendAppMajorVersion",
      "disableFiltering": true,
      "disableSorting": true
    }, {
      "propertyName": "lastLoginTimestamp",
      "disableFiltering": true,
      "disableSorting": true
    }, {
      "propertyName": "isAgent",
      "disableFiltering": true,
      "disableSorting": true,
      "component": "helpers/table-boolean-display"
    }, {
      "propertyName": "isAdmin",
      "disableFiltering": true,
      "disableSorting": true,
      "component": "helpers/table-boolean-display"
    }, {
      "propertyName": "isUnderwriter",
      "disableFiltering": true,
      "disableSorting": true,
      "component": "helpers/table-boolean-display"
    }],
    actions: {
      clickOnRow: function clickOnRow(index, obj) {
        this.get('router').transitionTo('user', obj.get('id'));
      }
    }

  });
});