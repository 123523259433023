define('minerva-frontend/controllers/proposal/proposal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    currentUser: Ember.inject.service(),
    actions: {
      saveFilename: function saveFilename(response, fileDescription) {
        var _this = this;

        //We should append the filename to the list of files of the model
        var model = this.get('model'); //currentModel;
        if (!model.get('files')) {
          model.set('files', []);
        }
        var newFile = {
          fileId: response.fileId,
          fileName: response.fileName,
          ext: response.ext,
          description: fileDescription,
          uploadTime: response.uploadTime
        };
        model.get('files').pushObject(newFile);
        model.save().then(function () {
          _this.get('notifications').success("File was upload successfully");
        }).catch(function (response) {
          model.rollbackAttributes();
          _this.get('notifications').error('There was an error while trying to upload the file. ' + response.errors);
        });
      },
      removeFile: function removeFile(fileObj) {
        var _this2 = this;

        if (confirm('Are you sure you want to remove the file ' + fileObj.description + '?')) {
          var model = this.get('model');
          this.get('model.files').removeObject(fileObj);
          model.save().then(function () {
            _this2.get('notifications').info("File was deleted successfully");
          }).catch(function (response) {
            model.rollbackAttributes();
            _this2.get('notifications').error('There was an error while trying to delete the file. ' + response.errors);
          });
        }
      }
    }
  });
});