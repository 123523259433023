define('minerva-frontend/models/covernote-cancellation-request', ['exports', 'ember-data', 'ember-moment/computeds/moment', 'ember-moment/computeds/format', 'ember-moment/computeds/locale'], function (exports, _emberData, _moment, _format, _locale) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    covernote: _emberData.default.belongsTo('covernote'),
    documentDateTime: _emberData.default.attr('date'),
    lastModificationDateTime: _emberData.default.attr('date'),

    owner: _emberData.default.attr('string'),
    handledBy: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    cancellationReason: _emberData.default.attr('string'),
    handleComments: _emberData.default.attr('string'),

    documentDateTimeFormatted: (0, _format.default)((0, _locale.default)((0, _moment.default)('documentDateTime'), 'moment.locale'), 'DD/MM/YYYY HH:mm'),
    lastModificationDateTimeFormatted: (0, _format.default)((0, _locale.default)((0, _moment.default)('lastModificationDateTime'), 'moment.locale'), 'DD/MM/YYYY HH:mm')
  });
});