define('minerva-frontend/components/combos/street-select/component', ['exports', 'minerva-frontend/components/combos/generic-model-select/component', 'minerva-frontend/components/combos/generic-model-select/template'], function (exports, _component, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    layout: _template.default,
    searchEnabled: true,
    disableBackendSearch: true,
    searchField: "streetName",
    inputPlaceholder: "Search for street name",
    modelClass: 'street',
    displayAttr: 'streetName',
    findByDisplayNameSearchTermKey: 'postCode',
    postCode: null,

    _postCodeChanged: Ember.observer('postCode', function () {
      var _this = this;

      var postCode = this.get('postCode');
      if (!postCode) {
        return;
      }

      return new Ember.RSVP.Promise(function (resolve, reject) {
        Ember.run.debounce(_this, _this._performSearch, postCode, resolve, reject, 600);
      });
    }),
    init: function init() {
      var _this2 = this;

      this._super.apply(this, arguments);
      if (this.get('postCode')) {
        //If initialized with post code, then fetch address!
        return new Ember.RSVP.Promise(function (resolve, reject) {
          Ember.run.debounce(_this2, _this2._performSearch, _this2.get('postCode'), resolve, reject, 600);
        });
      }
    }
  });
});