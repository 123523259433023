define('minerva-frontend/routes/users/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return this.get('store').createRecord('user', {});
    },

    actions: {
      willTransition: function willTransition() {
        var model = this.controller.get('model');
        if (model.get('isNew')) {
          model.destroyRecord();
        } else {
          model.rollBackAttributes();
        }
      }
    }
  });
});